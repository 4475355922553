import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    timeZone: {
        id: 'account.account pref.notification delivery option.Time Zone',
        defaultMessage: 'Time Zone'
    },
    textMessages: {
        id: 'account.account pref.notification delivery option.Text Messages',
        defaultMessage: 'Text Messages'
    },
    email: {
        id: 'account.account pref.notification delivery option.Email',
        defaultMessage: 'Email'
    },
    text: {
        id: 'account.account pref.notification delivery option.Text',
        defaultMessage: 'Text'
    },
    both: {
        id: 'account.account pref.notification delivery option.Both',
        defaultMessage: 'Text, Email'
    },
    none: {
        id: 'account.account pref.notification delivery option.None',
        defaultMessage: 'Method not selected'
    }
});
