import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CustomPolicyCommonContacts.metadata.json5';
import styles from './CustomPolicyCommonContacts.module.scss';
import './CustomPolicyCommonContacts.messages';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomFormatterUtil, CustomIconUtil } from 'gw-portals-util-js';

const getPhoneNumber = (contact) => {
    let pNumber;
    if (contact.primaryPhoneType !== undefined) {
        switch (contact.primaryPhoneType) {
            case "work":
                pNumber = contact.workNumber;
                break;
            case "mobile":
                pNumber = contact.cellNumber;
                break;
            case "home":
                pNumber = contact.homeNumber;
                break;
            default:
                pNumber = contact.homeNumber;
        }
        return CustomFormatterUtil.formatPhoneNumber(pNumber);
    }
    return "-";   
};

const formatContactRole = (roles) => {
    return roles.length ? roles.toString() : '-';
};

function CustomPolicyCommonContacts(props) {
    const { value: policyContacts } = props;
    
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');

    const getContactsData = useCallback(() => {
        const contactData = policyContacts.contacts.reduce((prev, curr) => {

            const existContact = prev.find(
                (contact) => contact.contact.publicID === curr.contact.publicID
            );
            if (existContact) {
                existContact.contactRole.push(curr.contactRole);
            } else {
                prev.push({
                    contact: curr.contact,
                    contactRole: [curr.contactRole]
                });
            }
            return prev;
        }, []);
        return contactData
            .map((contact) => {
                return {
                    contactName: contact.contact.displayName,
                    contactRole: formatContactRole(contact.contactRole),
                    contactDOB: CustomFormatterUtil.maskDOB(contact.contact.dateOfBirth),
                    contactEmail: contact.contact.emailAddress1
                        ? contact.contact.emailAddress1
                        : '-',
                    contactPhone: getPhoneNumber(contact.contact),
                    contactAddress: contact.contact.primaryAddress.displayName
                };
            });
    }, [policyContacts]);

    const contactData = getContactsData();



    const generateContactsOverrides = useCallback(() => {
        const overrides = contactData.map((contact, index) => {
            return {
                [`contactDOB${index}`]: {
                    value: contact.contactDOB },
                [`contactsPhoneNumber${index}`]: {
                    value: contact.contactPhone },
                [`gridSeparator${index}`]: {
                    visible: ((contactData.length - 1) !== index) },
                [`gridSeparatorLeftColumnforContacts${index}`]: {
                    visible: ((index) % 2 === 0) && index > 1 },
                [`gridSeparatorRightColumnforContacts${index}`]: {
                    visible: ((index) % 2 === 0) && index > 1 },
                [`contactEmail${index}`]: {
                    value: contact.contactEmail, },
                [`contactsAddress${index}`]: {
                    value: contact.contactAddress, },
                [`contactRoles${index}`]: {
                    value: contact.contactRole },
                [`contactName${index}`]: {
                    value: contact.contactName,
                }, 
            };  });
        return Object.assign({}, ...overrides);
    }, [contactData]);

    const overrides = {
        policyCommonTableHeader: {
            visible: !isMobile
        },
        contacts: {
            value: contactData
        },
        policyContactAgentNumber: {
            content: CustomFormatterUtil.formatPhoneNumber(props.agentDetails.producer_details.producerCodeOfServiceProducerPhone_Ext) + "."
        },
        infoLogo: {
            src: CustomIconUtil.getLogoSrc('blue_info_icon.png')
        },
        ...generateContactsOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                policyContacts,
                id,
                path,
                overrides
            );
        },
        [policyContacts, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policyContacts}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

CustomPolicyCommonContacts.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array])
};

CustomPolicyCommonContacts.defaultProps = {
    value: []
};

export default CustomPolicyCommonContacts;
