import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Icon } from '@jutro/components';
import { IntlContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';

import lobIconUtil from 'gw-portals-util-js/lobIconUtil';
import { FieldLinkComponent, Currency as CurrencyField } from 'gw-components-platform-react';

import metadata from './PoliciesTable.metadata.json5';
import styles from './PoliciesTable.module.scss';
import messages from './PoliciesTable.messages';

const findLatestPolicyPeriod = (policyPeriods) => {
    return _.maxBy(policyPeriods, (period) => {
        return new Date(period.effective).getTime();
    });
};

const getPoliciesTableData = (policySummaries, policyNumbers) => {
    const policyList = _.map(policyNumbers, (policy) => {
        // Find policy summary associated with the current policy
        const summaryMatch = policySummaries.find((policySummary) => {
            return policySummary.periods[0].policyId === policy;
        });

        // If the summaryMatch is undefined, then this policy is not in force.
        if (summaryMatch !== undefined) {
            /*  Create summary object. At this point the policy number is known so that is the
             *  only field set.
             */
            let returnObject = {
                policyNumber: policy
            };

            // Find the latest policy period
            const latestPolicyPeriod = findLatestPolicyPeriod(summaryMatch.periods);

            if (latestPolicyPeriod) {
                // If a period was found, merge the summary date with the summary object
                returnObject = Object.assign(returnObject, {
                    productCodes: latestPolicyPeriod.lines,
                    premium: latestPolicyPeriod.premium,
                    effectiveDate: latestPolicyPeriod.effective,
                    expirationDate: latestPolicyPeriod.expiration
                });
            }
            return returnObject;
        }

        return undefined;
    });

    return policyList.filter((policy) => {
        return policy !== undefined;
    });
};

const getProductImage = (item, index, { id: property }) => {
    const productCode = item[property][0];
    const icon = lobIconUtil.getMaterialIcon(productCode);
    return <Icon icon={icon} title={productCode} key={`${productCode}_${index}`} />;
};

const getLink = (item, index, { id: property }) => {
    return (
        <FieldLinkComponent
            id={`policy${item[property]}`}
            to={`/account-policy-details/${item[property]}`}
            className={styles.policyLinkContainer}
            title={messages.policy}
            value={item[property]}
        />
    );
};

const renderFormattedCurrencyField = (item, index) => {
    return (
        <div className={styles.currencyContainer}>
            <CurrencyField
                id={`currency_${index}`}
                value={item}
                readOnly
                hideLabel
            />
        </div>
    );
};

function PoliciesTable(props) {
    const { policySummaries = [], policyNumbers = [] } = props;
    const intl = useContext(IntlContext);

    const getFormattedCurrency = useCallback((item, index, property) => {
        return renderFormattedCurrencyField(item[property.id], index);
    }, []);

    const overrideProps = {
        tablePolicySummary: {
            data: getPoliciesTableData(policySummaries, policyNumbers)
        }
    };

    const getFormattedDate = (item, index, property) => {
        return intl.formatDate(new Date(item[property.id]), { year: 'numeric', month: 'short', day: 'numeric' });
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            getProductImage,
            getLink,
            getFormattedDate,
            getFormattedCurrency
        }
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

PoliciesTable.propTypes = {
    policySummaries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    policyNumbers: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

export default PoliciesTable;
