import InvoicesTable from './InvoicesTable/InvoicesTable';
import PoliciesTable from './PoliciesTable/PoliciesTable';
import BillingSummary from './BillingSummary/BillingSummary';
import BillingSummaryPage from './BillingSummaryPage/BillingSummaryPage';
import MakePaymentPage from './MakePaymentPage/MakePaymentPage';
import CustomChoosePymtPage from './CustomChoosePymtPage/CustomChoosePymtPage';

const billingComponentMap = {
    InvoicesTable: { component: 'InvoicesTable' },
    PoliciesTable: { component: 'PoliciesTable' },
    BillingSummary: { component: 'BillingSummary' },
};

const billingAdditionalComponents = {
    InvoicesTable,
    PoliciesTable,
    BillingSummary,
};

export {
    InvoicesTable,
    PoliciesTable,
    BillingSummary,
    BillingSummaryPage,
    MakePaymentPage,
    billingComponentMap,
    billingAdditionalComponents,
    CustomChoosePymtPage
};
