import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    noBillingData: {
        id: 'billing.billing-summary.policy.card.No Billing Data Available at this time',
        defaultMessage: 'No Billing Data Available at this time'
    },
    agencyBill: {
        id: 'billing.billing-summary.policy.card.Agency Bill',
        defaultMessage: 'Agency Bill'
    },
    directBill: {
        id: 'billing.billing-summary.policy.card.Direct Bill',
        defaultMessage: 'Direct Bill'
    },
    statusActive: {
        id: 'billing.billing-summary.policy.card.policy-status.Active',
        defaultMessage: 'Active'
    },
    statusCanceled: {
        id: 'billing.billing-summary.policy.card.policy-status.Canceled',
        defaultMessage: 'Canceled'
    },
    paymentPastDue: {
        id: 'billing.billing-summary.policy.card.Payment past due',
        defaultMessage: 'Payment past due'
    },
    nextPaymentDue: {
        id: 'billing.billing-summary.policy.card.Next payment due',
        defaultMessage: 'Next payment due'
    },
    currentPaymentDue: {
        id: 'billing.billing-summary.policy.card.Current payment due',
        defaultMessage: 'Current payment due'
    },
    nextPaymentDueValue: {
        id: 'billing.billing-summary.policy.card.{nextPaymentAmt} due on {nextPaymentAmtDate}',
        defaultMessage: '{nextPaymentAmt} due on {nextPaymentAmtDate}'
    },
    lastPaymentReceivedValue: {
        id: 'billing.billing-summary.policy.card.{lastPaymentReceivedAmt} on {lastPaymentReceivedAmtDate}',
        defaultMessage: '{lastPaymentReceivedAmt} on {lastPaymentReceivedAmtDate}'
    },
    totalAmountReceived: {
        id: 'billing.billing-summary.policy.card.Total amount received',
        defaultMessage: 'Total amount received'
    }
});