/* eslint-disable max-len */
import React, {
    useEffect, useState, useContext, useCallback
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { getNormalizedLOBName } from 'gw-portals-config-js';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useAuthentication } from 'gw-digital-auth-react';
import { PolicyService } from 'gw-capability-policy';
import { DocumentService } from 'gw-capability-policydocument';
import {
    PolicyCommonDetails, PolicyCommonDocuments, CustomPolicyCommonContacts, CustomCoveredDriversComponent,  PolicyCoverages, CustomPolicyCommonDetails
} from 'gw-capability-policy-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CustomIconUtil, CustomPolicyTypeUtil } from 'gw-portals-util-js';
import { CustomAgentComponent, LoadingContext } from 'gw-capability-policy-react';
import appConfig from 'app-config';
import metadata from './PolicyDetails.metadata.json5';
import messages from './PolicyDetails.messages';
import styles from './PolicyDetails.module.scss';
import { CustomFormatterUtil } from 'gw-portals-util-js';

function PolicyDetails(props) {
    const { history, match } = props;
    const { authHeader } = useAuthentication();
    const [policyCommonDetails, updatePolicyCommonDetails] = useState({
        currentPeriodDetails: {},
        renewedPeriodDetails: {},
        hasRenewedPeriod: false,
    });
    const [writableDocuments, updateWritableDocuments] = useState([]);
    const loader = useContext(LoadingContext);
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet';
    const policyNumber = _.get(match, 'params.policyNumber');
    const [agentCardStyle, setStyle] = useState('agent-columns-hidden');
    const [agentContainerCardStyle, setContainerStyle] = useState('tableContainer policy-agent-card policy-agent-card-minified');
    const [policyContactsDetailCardStyle, setpolicyContactsStyle] = useState('policy-contacts-columns-hidden');
    const [policyContactsDetailContainerStyle, setpolicyContactsContainerStyle] = useState('tableContainer policy-contacts-card policy-contacts-card-minified');
    const [policyCoverageDetailCardStyle, setPolicyCoveragesStyle] = useState('policy-coverages-columns-hidden');
    const [policyCoverageDetailContainerStyle, setPolicyCoveragesContainerStyle] = useState('tableContainer policy-coverages-card policy-coverages-card-minified');
    const [covereddriversCardStyle, setcovereddriversStyle] = useState('covered-drivers-columns-hidden');
    const [covereddriversDetailContainerStyle, setcovereddriversContainerStyle] = useState('tableContainer covered-drivers-card covered-drivers-card-minified');
    const [policyDetailsCardStyle, setpolicydetailsStyle] = useState('policy-details-columns-hidden');
    const [policyDetailContainerStyle, setpolicydetailsContainerStyle] = useState('tableContainer policy-details-card policy-details-card-minified');
    const [vehicleCoverageDetailCardStyle, setVehicleCoveragesStyle] = useState('vehicle-coverages-columns-hidden');
    const [vehicleCoverageDetailContainerStyle, setVehicleCoveragesContainerStyle] = useState('tableContainer vehicle-coverages-card vehicle-coverages-card-minified');
    const [policyDocumentsCardStyle, setpolicydocumentsStyle] = useState('policy-documents-columns-hidden');
    const [policyDocumentsContainerStyle, setpolicydocumentsContainerStyle] = useState('tableContainer policy-documents-card policy-documents-card-minified');

    const generateIDCard = useCallback(() => {
        loader.setLoading(true);
        Promise.all([DocumentService.generateOnlineDocument('printIdCards', policyNumber, authHeader)])
            .finally(() => { loader.setLoading(false); });
    }, []);

    const generateDeclarationPage = useCallback(() => {
        loader.setLoading(true);
        Promise.all([DocumentService.generateOnlineDocument('printDeclarationPage', policyNumber, authHeader)])
            .finally(() => { loader.setLoading(false); });
    }, []);

    const getPolicyDetails = useCallback(async () => {
        loader.setLoading(true);
        const policyDetails = await PolicyService.getAccountPolicyDetails(policyNumber, authHeader);
        updatePolicyCommonDetails({
            currentPeriodDetails: policyDetails.currentPeriod,
            renewedPeriodDetails: policyDetails.renewedPeriod ? policyDetails.renewedPeriod : {},
            hasRenewedPeriod: !_.isEmpty(policyDetails.renewedPeriod),
        });
        window.scroll(0, 0);
        loader.setLoading(false);
    }, [policyNumber, authHeader]);

    const redirectToEndorsement = useCallback((policyDetails, requestType) => {
        const productCode = _.findKey(policyDetails.lobs, (lob) => !_.isEmpty(lob));
        history.push(`/endorsement-${getNormalizedLOBName(productCode)}`, { policyNumber, policyDetails, requestType });
    }, [history, policyNumber]);

    const canShowLinks = () => {
        if (!_.isEmpty(policyCommonDetails.currentPeriodDetails)) {
            const status = policyCommonDetails.currentPeriodDetails.status_Ext;
            return (status !== 'Expired' && status !== 'Canceled');
        }
        return true;
    };

    const contactRole = 'Named Insured';
    let policyHolderName = null;
    let dateOfBirth = null;
    if(policyCommonDetails.currentPeriodDetails.contacts!==undefined){
    for (const contact of policyCommonDetails.currentPeriodDetails.contacts){
        if(contact.contactRole === contactRole){
            policyHolderName = contact.contact.displayName;
            dateOfBirth = contact.contact.dateOfBirth;
            break;
        };
    };
};

    const getPolicyDownloadLink = useCallback(() => {
        return DocumentService.downloadPolicyDocument(
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'),
            _.get(policyCommonDetails.currentPeriodDetails, 'idCardSessionID')
        );
    }, [policyCommonDetails]);

    function customToggle(componentState, componentFunc, componentContainerFunc, componentClass, hiddenComponentClass, containerClass, hiddenContainerClass) {
        if (componentState === hiddenComponentClass) {
            componentContainerFunc(containerClass);
            componentFunc(componentClass);
        } else {
            componentContainerFunc(hiddenContainerClass);
            componentFunc(hiddenComponentClass);
        }
    }

    const toggleMobileCard = useCallback((isChevron, componentState, componentFunc, componentContainerFunc, componentClass, hiddenComponentClass, containerClass, hiddenContainerClass) => () => {
        const cards = [{
            componentState: policyDetailsCardStyle, componentFunc: setpolicydetailsStyle, componentContainerFunc: setpolicydetailsContainerStyle, componentClass: 'policy-details-columns', hiddenComponentClass: 'policy-details-columns-hidden', containerClass: 'tableContainer policy-details-card', hiddenContainerClass: 'tableContainer policy-details-card policy-details-card-minified'
        }, {
            componentState: policyDocumentsCardStyle, componentFunc: setpolicydocumentsStyle, componentContainerFunc: setpolicydocumentsContainerStyle, componentClass: 'policy-documents-columns', hiddenComponentClass: 'policy-documents-columns-hidden', containerClass: 'tableContainer policy-documents-card', hiddenContainerClass: 'tableContainer policy-documents-card policy-documents-card-minified'
        }, {
            componentState: agentCardStyle, componentFunc: setStyle, componentContainerFunc: setContainerStyle, componentClass: 'agent-columns', hiddenComponentClass: 'agent-columns-hidden', containerClass: 'tableContainer policy-agent-card', hiddenContainerClass: 'tableContainer policy-agent-card policy-agent-card-minified'
        }, {
            componentState: policyCoverageDetailCardStyle, componentFunc: setPolicyCoveragesStyle, componentContainerFunc: setPolicyCoveragesContainerStyle, componentClass: 'policy-coverages-columns', hiddenComponentClass: 'policy-coverages-columns-hidden', containerClass: 'tableContainer policy-coverages-card', hiddenContainerClass: 'tableContainer policy-coverages-card policy-coverages-card-minified'
        }, {
            componentState: policyContactsDetailCardStyle, componentFunc: setpolicyContactsStyle, componentContainerFunc: setpolicyContactsContainerStyle, componentClass: 'policy-contacts-columns', hiddenComponentClass: 'policy-contacts-columns-hidden', containerClass: 'tableContainer policy-contacts-card', hiddenContainerClass: 'tableContainer policy-contacts-card policy-contacts-card-minified'
        }, {
            componentState: vehicleCoverageDetailCardStyle, componentFunc: setVehicleCoveragesStyle, componentContainerFunc: setVehicleCoveragesContainerStyle, componentClass: 'vehicle-coverages-columns', hiddenComponentClass: 'vehicle-coverages-columns-hidden', containerClass: 'tableContainer vehicle-coverages-card', hiddenContainerClass: 'tableContainer vehicle-coverages-card vehicle-coverages-card-minified'
        }, {
            componentState: covereddriversCardStyle, componentFunc: setcovereddriversStyle, componentContainerFunc: setcovereddriversContainerStyle, componentClass: 'covered-drivers-columns', hiddenComponentClass: 'covered-drivers-columns-hidden', containerClass: 'tableContainer covered-drivers-card', hiddenContainerClass: 'tableContainer covered-drivers-card covered-drivers-card-minified'
        }];

        if (isMobile) {
            if (isChevron || (componentState === hiddenComponentClass && !isChevron)) {
                customToggle(componentState, componentFunc, componentContainerFunc, componentClass, hiddenComponentClass, containerClass, hiddenContainerClass);
            }
            for (let i = 0; i < 7; i += 1) {
                const card = cards[i];
                if (card.componentState !== card.hiddenComponentClass && card.componentState !== componentState) {
                    customToggle(card.componentState, card.componentFunc, card.componentContainerFunc, card.componentClass, card.hiddenComponentClass, card.containerClass, card.hiddenContainerClass);
                }
            }
        }
    }, [isMobile, policyDetailsCardStyle, policyDocumentsCardStyle, vehicleCoverageDetailCardStyle, covereddriversCardStyle, agentCardStyle, policyContactsDetailCardStyle, policyCoverageDetailCardStyle]);

    const policyAgentDetails = {
        producer_details: policyCommonDetails.currentPeriodDetails.producerContact_Ext,
        producer_policeis: []
    };

    useEffect(() => {
        try {
            getPolicyDetails();
        } catch (e) {
            throw new Error(e);
        }
    }, [writableDocuments.length]);

    const overrideProps = {
        viewIDCard: {
            onClick: generateIDCard,
            target: '_blank',
        },
        viewDec: {
            onClick: generateDeclarationPage,
            target: '_blank',
        },
        policyDetailTitle: {
            content: CustomPolicyTypeUtil.isHOFlex_Ext(policyCommonDetails.currentPeriodDetails)
                ? CustomPolicyTypeUtil.getHOFlexTitle(false)
                : translator(policyCommonDetails.currentPeriodDetails.policyType_Ext)
        },
        idCardContainer: {
            visible: !_.isEmpty(_.get(policyCommonDetails.currentPeriodDetails, 'idCardPublicID'))
        },
        idCard: {
            href: getPolicyDownloadLink(),
            target: '_blank',
        },
        policyDocumentsSection: {
            updateWritableDocuments: updateWritableDocuments,
            writableDocuments: writableDocuments,
            policyDetails: policyCommonDetails.currentPeriodDetails
        },
        policyTypeAvatar: {
            imageSource: CustomIconUtil.getCustomPolicyTypeIcon(policyCommonDetails.currentPeriodDetails.policyType_Ext)
        },
        policyNumber: {
            content: translator(messages.policyNumberValue, {
                id: policyCommonDetails.currentPeriodDetails.policyNumber,
            }),
        },
        contactAgentDiv: {
            className: isMobile ? agentCardStyle : 'agent-columns',
        },
        policyAgentCard: {
            className: isMobile ? agentContainerCardStyle : 'tableContainer policy-agent-card',
            onClick: toggleMobileCard(false, agentCardStyle, setStyle, setContainerStyle, 'agent-columns', 'agent-columns-hidden', 'tableContainer policy-agent-card', 'tableContainer policy-agent-card policy-agent-card-minified')
        },
        policyagentdetails: {
            agentDetails: policyAgentDetails,
            singlePolicy: true,
        },
        toggleicon: {
            src: agentCardStyle === 'agent-columns-hidden' ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, agentCardStyle, setStyle, setContainerStyle, 'agent-columns', 'agent-columns-hidden', 'tableContainer policy-agent-card', 'tableContainer policy-agent-card policy-agent-card-minified')
        },
        toggleCard: {
            visible: isMobile,
        },
        vehicleCoverageDetailsDiv: {
            className: isMobile ? vehicleCoverageDetailCardStyle : 'vehicle-coverages-columns',
        },
        vehiclecoveragedetailscard: {
            // eslint-disable-next-line max-len
            visible: (policyCommonDetails.currentPeriodDetails.lobs !== undefined) ? !_.isEmpty(policyCommonDetails.currentPeriodDetails.lobs.personalAuto) : false,
            className: isMobile ? vehicleCoverageDetailContainerStyle : 'tableContainer vehicle-coverages-card',
            onClick: toggleMobileCard(false, vehicleCoverageDetailCardStyle, setVehicleCoveragesStyle, setVehicleCoveragesContainerStyle, 'vehicle-coverages-columns', 'vehicle-coverages-columns-hidden', 'tableContainer vehicle-coverages-card', 'tableContainer vehicle-coverages-card vehicle-coverages-card-minified')
        },
        toggleVehicleCoverageDetailsIcon: {
            src: vehicleCoverageDetailCardStyle === 'vehicle-coverages-columns-hidden' ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, vehicleCoverageDetailCardStyle, setVehicleCoveragesStyle, setVehicleCoveragesContainerStyle, 'vehicle-coverages-columns', 'vehicle-coverages-columns-hidden', 'tableContainer vehicle-coverages-card', 'tableContainer vehicle-coverages-card vehicle-coverages-card-minified')
        },
        toggleVehicleCoverageDetailsCard: {
            visible: isMobile,
        },
        togglePolicyCoverageDetailsIcon: {
            src: (policyCoverageDetailCardStyle === 'policy-coverages-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, policyCoverageDetailCardStyle, setPolicyCoveragesStyle, setPolicyCoveragesContainerStyle, 'policy-coverages-columns', 'policy-coverages-columns-hidden', 'tableContainer policy-coverages-card', 'tableContainer policy-coverages-card policy-coverages-card-minified')
        },
        togglePolicyCoverageDetailsCard: {
            visible: isMobile
        },
        policycoveragedetailsdiv: {
            className: isMobile ? policyCoverageDetailCardStyle : 'policy-coverages-columns'
        },
        policycoveragedetailscard: {
            className: isMobile ? policyCoverageDetailContainerStyle : 'tableContainer policy-coverages-card',
            visible: (policyCommonDetails.currentPeriodDetails.lobs !== undefined) ? !_.isEmpty(policyCommonDetails.currentPeriodDetails.lobs.homeOwners) : false, // (policyCommonDetails.currentPeriodDetails !=null)//!_.isEmpty(policyCommonDetails.currentPeriodDetails.lobs.homeOwners)
            onClick: toggleMobileCard(false, policyCoverageDetailCardStyle, setPolicyCoveragesStyle, setPolicyCoveragesContainerStyle, 'policy-coverages-columns', 'policy-coverages-columns-hidden', 'tableContainer policy-coverages-card', 'tableContainer policy-coverages-card policy-coverages-card-minified')
        },
        policyContactsDiv: {
            className: isMobile ? policyContactsDetailCardStyle : 'policy-contacts-columns'
        },
        policyContactsDetailsCard: {
            className: isMobile ? policyContactsDetailContainerStyle : 'tableContainer policy-contacts-card',
            onClick: toggleMobileCard(false, policyContactsDetailCardStyle, setpolicyContactsStyle, setpolicyContactsContainerStyle, 'policy-contacts-columns', 'policy-contacts-columns-hidden', 'tableContainer policy-contacts-card', 'tableContainer policy-contacts-card policy-contacts-card-minified')
        },
        togglePolicyContactsIcon: {
            src: (policyContactsDetailCardStyle === 'policy-contacts-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, policyContactsDetailCardStyle, setpolicyContactsStyle, setpolicyContactsContainerStyle, 'policy-contacts-columns', 'policy-contacts-columns-hidden', 'tableContainer policy-contacts-card', 'tableContainer policy-contacts-card policy-contacts-card-minified')
        },
        togglePolicyContactDetailsCard: {
            visible: isMobile
        },
        covereddriversdiv: {
            className: isMobile ? covereddriversCardStyle : 'covered-drivers-columns'
        },
        togglecovereddriversIcon: {
            src: (covereddriversCardStyle === 'covered-drivers-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, covereddriversCardStyle, setcovereddriversStyle, setcovereddriversContainerStyle, 'covered-drivers-columns', 'covered-drivers-columns-hidden', ' tableContainer covered-drivers-card', 'tableContainer covered-drivers-card covered-drivers-card-minified')
        },
        toggleCoveredDriversCard: {
            visible: isMobile
        },
        covereddriversCard: {
            className: isMobile ? covereddriversDetailContainerStyle : 'tableContainer covered-drivers-card',
            visible: (policyCommonDetails.currentPeriodDetails.lobs !== undefined) ? !_.isEmpty(policyCommonDetails.currentPeriodDetails.lobs.personalAuto) : false,
            onClick: toggleMobileCard(false, covereddriversCardStyle, setcovereddriversStyle, setcovereddriversContainerStyle, 'covered-drivers-columns', 'covered-drivers-columns-hidden', ' tableContainer covered-drivers-card', 'tableContainer covered-drivers-card covered-drivers-card-minified')
        },
        policyDetailsComponent: {
            policyDetails: policyCommonDetails.currentPeriodDetails,
            agentDetails: policyAgentDetails
        },
        customPolicyDetailsDiv: {
            className: isMobile ? policyDetailsCardStyle : 'policy-details-columns'
        },
        customPolicyDetailsCard: {
            className: isMobile ? policyDetailContainerStyle : 'tableContainer policy-details-card',
            onClick: toggleMobileCard(false, policyDetailsCardStyle, setpolicydetailsStyle, setpolicydetailsContainerStyle, 'policy-details-columns', 'policy-details-columns-hidden', 'tableContainer policy-details-card', 'tableContainer policy-details-card policy-details-card-minified'),
        },
        togglepolicyDetailsIcon: {
            src: (policyDetailsCardStyle === 'policy-details-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, policyDetailsCardStyle, setpolicydetailsStyle, setpolicydetailsContainerStyle, 'policy-details-columns', 'policy-details-columns-hidden', 'tableContainer policy-details-card', 'tableContainer policy-details-card policy-details-card-minified')
        },
        togglepolicyDetailsCard: {
            visible: isMobile
        },
        idCardAvatar: {
            src: CustomIconUtil.getLogoSrc('download.svg'),
        },
        linkdivid: {
            visible: policyCommonDetails.currentPeriodDetails.productName !== 'Residential'
        },
        documentLinkDiv: {
            visible: canShowLinks()
        },
        customPolicyDocumentsDiv: {
            className: isMobile ? policyDocumentsCardStyle : 'policy-documents-columns'
        },
        customPolicyDocumentCard: {
            className: isMobile ? policyDocumentsContainerStyle : 'tableContainer policy-documents-card',
            onClick: toggleMobileCard(false, policyDocumentsCardStyle, setpolicydocumentsStyle, setpolicydocumentsContainerStyle, 'policy-documents-columns', 'policy-documents-columns-hidden', 'tableContainer policy-documents-card', 'tableContainer policy-documents-card policy-documents-card-minified')
        },
        togglepolicyDocumentsIcon: {
            src: (policyDocumentsCardStyle === 'policy-documents-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleMobileCard(true, policyDocumentsCardStyle, setpolicydocumentsStyle, setpolicydocumentsContainerStyle, 'policy-documents-columns', 'policy-documents-columns-hidden', 'tableContainer policy-documents-card', 'tableContainer policy-documents-card policy-documents-card-minified')
        },
        togglepolicyDocumentsCard: {
            visible: isMobile
        },
        changeMailingAddLink: {
            visible: isMobile ? policyDetailsCardStyle !== 'policy-details-columns-hidden' : true,
            target: '_blank',
            href: appConfig.env.CHANGE_MAILING_ADDRESS_URL
        },
        changePolicyHolderNameLink: {
            visible: isMobile ? policyContactsDetailCardStyle !== 'policy-contacts-columns-hidden' : true,
            target: '_blank',
            href: appConfig.env.CHANGE_POLICYHOLDER_NAME_URL
        },
        policyContactsSection: {
            agentDetails: policyAgentDetails
        }
    };
    const resolvers = {
        resolveComponentMap: {
            policycommondetailscomponent: PolicyCommonDetails,
            policycoveragescomponent: PolicyCoverages,
            policycommondocumentscomponent: PolicyCommonDocuments,
            policycommoncontactscomponent: CustomPolicyCommonContacts,
            policyagentcard: CustomAgentComponent,
            customcoveredDriversDetailsComponent: CustomCoveredDriversComponent,
            custompolicydetailscard: CustomPolicyCommonDetails
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangePolicy: () => redirectToEndorsement(policyCommonDetails.currentPeriodDetails, 'policyChange'),
            onChangePolicyRenewal: () => redirectToEndorsement(policyCommonDetails.renewedPeriodDetails, 'policyRenew')
        }
    };
    if (!loader.loading && (Object.keys(policyCommonDetails.currentPeriodDetails).length > 0)) {
        return (
            <>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={policyCommonDetails}
                    overrideProps={overrideProps}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </>
        );
    }
    return <> </>;
}
PolicyDetails.propTypes = {
    match: PropTypes.shape({}).isRequired,
    history: PropTypes.shape({ push: PropTypes.func }).isRequired,
    location: PropTypes.shape({ productCode: PropTypes.string }).isRequired,
};
export default withRouter(PolicyDetails);
