import { setComponentMapOverrides } from '@jutro/uiconfig';
import ConfirmationPage from './pages/Confirmation/ConfirmationPage';
import HOPAPaymentPage from './pages/Payments/HOPAPaymentPage/PaymentPage';
import CPBOPPaymentPage from './pages/Payments/CPBOPPaymentPage/PaymentPage';

setComponentMapOverrides(
    {
        HOPAPaymentPage: { component: 'HOPAPaymentPage' },
        CPBOPPaymentPage: { component: 'CPBOPPaymentPage' },
        ConfirmationPage: { component: 'ConfirmationPage' }
    },
    {
        HOPAPaymentPage,
        CPBOPPaymentPage,
        ConfirmationPage
    }
);
export { default as messages } from './PolicyChangeCommon.messages';
export { default as PolicyChange } from './models/PolicyChange';
export { default as ConfirmationQuoteDetailsPage } from './pages/ConfirmationQuoteDetails/ConfirmationQuoteDetailsPage';
export { default as CPBOPPaymentPage } from './pages/Payments/CPBOPPaymentPage/PaymentPage';
