import { defineMessages } from "react-intl";

export default defineMessages({
    accountHolder: {
        id: 'account.account pref.account profile.{firstName} {lastName}',
        defaultMessage: '{firstName} {lastName}'
    },
    individual: {
        id: 'account.account pref.account profile.Individual',
        defaultMessage: 'Individual'
    },
    business: {
        id: 'account.account pref.account profile.Business',
        defaultMessage: 'Business, LLC, Trust or Estate'
    }
});
