import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import { CustomIconUtil } from 'gw-portals-util-js';
import { Image } from '@jutro/components';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { withRouter } from 'react-router-dom';
import { LoadingContext } from 'gw-capability-policy-react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import metadata from './CustomNotifications.metadata.json5';
import messages from './CustomNotifications.messages';
import styles from './CustomNotifications.module.scss';
import timeZoneList from './timeZone.json';
import accountPrefTypes from '../../pages/CustomAccountPrefPage/CustomAccountPrefTypes.json';

function CustomNotifications(props) {
    const loader = useContext(LoadingContext);
    const [formData, updateFormData] = useState(props.notificationDetails);
    const [localFormData, updateLocalFormData] = useState(formData);
    const [notificationsEditMode, setNotificationsEditMode] = useState(false);
    const [selectedTimezone, updateSelectedTimezone] = useState(props.notificationDetails.contactTimeZone);
    const [notificationPref, updateNotificationPref] = useState();
    const [notificationDetails, updateNotificationDetails] = useState(props.notificationDetails);
    const [docDetails, updateDocDetails] = useState(props.documentDetails);
    const [warningVisible, setWarningVisible] = useState(false);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const translator = useContext(TranslatorContext);
    const { isLoading } = props;
    const accountPrefOptions = {
        email: 'email',
        textMessages: 'textMessages',
        methodNotSelected: 'Method not selected'
    };
    const validChangeTypes = {
        notification: 'notification'
    };

    function getNotificationDetails(selectedOptions) {
        switch (selectedOptions) {
            case accountPrefTypes.notificationOptionEmail.code:
                return ([accountPrefOptions.email]);
            case accountPrefTypes.notificationOptionSms.code:
                return ([accountPrefOptions.textMessages]);
            case accountPrefTypes.notificationOptionBoth.code:
                return ([accountPrefOptions.textMessages, accountPrefOptions.email]);
            default:
                return ([]);
        }
    }

    const mapNotificationDetails = useCallback((notificationOption) => {
        updateNotificationPref(getNotificationDetails(notificationOption));
    }, []);

    const writeFormValue = useCallback(() => (newValue) => {
        if (!((newValue.length === 0 || newValue[0] === accountPrefOptions.textMessages) && docDetails)) {
            const newFormData = { ...localFormData };
            updateNotificationPref(newValue);
            switch (newValue.length) {
                case 2:
                    newFormData.accountNotification = accountPrefTypes.notificationOptionBoth.code;
                    break;
                case 1:
                    newFormData.accountNotification = (newValue[0] === accountPrefOptions.email ? accountPrefTypes.notificationOptionEmail.code : accountPrefTypes.notificationOptionSms.code);
                    break;
                default:
                    newFormData.accountNotification = accountPrefTypes.notificationOptionNone.code;
                    break;
            }

            updateLocalFormData({ ...localFormData, accountNotification: newFormData.accountNotification });
        } else {
            setWarningVisible(true);
            props.setSaveChangesWarning(false);
        }
    }, [notificationPref, localFormData]);

    function getNotificationString(selectedOption) {
        if (selectedOption === undefined) {
            return accountPrefOptions.methodNotSelected;
        }
        switch (selectedOption) {
            case accountPrefTypes.notificationOptionBoth.code:
                return translator(messages.both);
            case accountPrefTypes.notificationOptionEmail.code:
                return translator(messages.email);
            case accountPrefTypes.notificationOptionSms.code:
                return translator(messages.text);
            default:
                return translator(messages.none);
        }
    }

    const toggleNotificationForm = useCallback(() => () => {
        if (notificationsEditMode === false) {
            setNotificationsEditMode(true);
            setWarningVisible(false);
        } else {
            updateLocalFormData(formData);
            mapNotificationDetails(formData.accountNotification);
            updateSelectedTimezone(formData.contactTimeZone);
            setNotificationsEditMode(false);
            props.setSaveChangesWarning(false);
            setWarningVisible(false);
            props.setToEditMode(false);
            props.dismissNotification('unsavedChangesNotification').call();
        }
    }, [notificationsEditMode, formData]);

    const saveDocChanges = useCallback(() => () => {
        props.setLoadingState(true);
        updateFormData(localFormData);
        props.updatePref(validChangeTypes.notification, localFormData);
        setNotificationsEditMode(false);
        setWarningVisible(false);
    }, [localFormData, props]);

    const getselectedTimeZone = useCallback((formTimeZone) => {
        if (selectedTimezone === undefined) {
            return formTimeZone;
        }
        const timeZoneName = selectedTimezone !== undefined ? timeZoneList.filter((timezone) => {
            return timezone.code === selectedTimezone;
        })[0] : timeZoneList[0];
        return timeZoneName.code;
    }, [selectedTimezone]);

    const dropDownSelect = useCallback((newValue) => {
        updateSelectedTimezone(newValue);
        updateLocalFormData({ ...localFormData, contactTimeZone: newValue });
    }, [selectedTimezone, localFormData]);

    const dismissWarningMessage = useCallback(() => () => {
        setWarningVisible(false);
    }, []);

    const dismissUnsavedChangesWarningMessage = useCallback(() => (event) => {
        event.stopPropagation();
        props.setSaveChangesWarning(false);
    }, []);

    useEffect(() => {
        updateFormData(props.notificationDetails);
        updateLocalFormData(props.notificationDetails);
    }, [props.notificationDetails]);

    useEffect(() => {
        if (!(formData === localFormData || localFormData.contactTimeZone === undefined)) {
            props.setToEditMode(true);
        }
    }, [localFormData]);

    useEffect(() => {
        setShowWarningMessage(props.showSaveChangesWarning);
        if (props.showSaveChangesWarning) {
            setWarningVisible(false);
        }
    }, [props.showSaveChangesWarning]);

    useEffect(() => {
        updateDocDetails(props.documentDetails);
    }, [props.documentDetails]);

    useEffect(() => {
        mapNotificationDetails(notificationDetails.accountNotification);
        loader.setLoading(false);
    }, []);

    const overrideProps = {
        editNotifications: {
            visible: !notificationsEditMode,
            onClick: toggleNotificationForm()
        },
        accountDeliveryNotificationColumn: {
            visible: !notificationsEditMode
        },
        notifications_Details: {
            visible: !notificationsEditMode,
            content: getNotificationString(formData.accountNotification)
        },
        cancelEditNotificationButton: {
            onClick: toggleNotificationForm()
        },
        saveChangesEditNotificationButton: {
            onClick: saveDocChanges(),
            disabled: formData === localFormData || localFormData.contactTimeZone === undefined
        },
        notificationOptions: {
            value: getNotificationDetails(localFormData.accountNotification),
            onValueChange: writeFormValue(),
            className: docDetails ? 'disabledEmailOptions' : 'notificationOptions'
        },
        notificationsTimeZoneSection: {
            visible: notificationsEditMode
        },
        billingDocFormContainer: {
            visible: notificationsEditMode
        },
        typeaheadmultiselect: {
            availableValues: timeZoneList,
            value: getselectedTimeZone(localFormData.contactTimeZone),
            onValueChange: dropDownSelect,
            className: selectedTimezone !== undefined ? 'timeZoneSelector' : null,
            showErrors: selectedTimezone === undefined
        },
        termsofUseLink: {
            href: appConfig.env.NOTIFICATION_TERMS_OF_USE_URL,
            target: '_blank'
        },
        closeWarningMessage: {
            onClick: dismissWarningMessage()
        },
        warningDiv: {
            visible: warningVisible && docDetails && !showWarningMessage,
            className: 'showWarningMessage'
        },
        warningDivForUnsavedChanges: {
            visible: showWarningMessage,
            className: 'showWarningMessage'
        },
        dismissUnsavedChangesWarningMessage: {
            onClick: dismissUnsavedChangesWarningMessage()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    if (isLoading) {
        return (
          <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc("rotating-circle-blue_128.gif")} showLoader={false} />
        );
    }

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomNotifications.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(CustomNotifications));
