import React, { useCallback, useContext } from "react";
import { TranslatorContext } from "@jutro/locale";
import _ from "lodash";
import PropTypes from "prop-types";
import { renderContentFromMetadata } from "@jutro/uiconfig";
import metadata from "./CustomPolicyCommonDetails.metadata.json5";
import styles from "./CustomPolicyCommonDetails.module.scss";
import messages from "./CustomPolicyCommonDetails.messages";
import { LoadingContext } from 'gw-capability-policy-react';
import { CustomIconUtil, CustomFormatterUtil } from 'gw-portals-util-js';
function CustomPolicyCommonDetails(props) {
  const translator = useContext(TranslatorContext);
  const loader = useContext(LoadingContext);
  const { data: policyData } = props;
  const latestPolicyPeriod = props.policyDetails;

  const readValue = useCallback(
    (id, path) => {
      if (path === "producerAgency") {
        return _.get(
          policyData.producerContact,
          "producerCodeOfServiceAgency_Ext"
        );
      }
      return _.get(policyData, path);
    },
    [policyData]
  ); 
 
  const isPolicyStatusInForce = () => {
    return latestPolicyPeriod.status_Ext === "In Force";
  };

  const isPolicyStatusExpired = () => {
    return latestPolicyPeriod.status_Ext === "Expired";
  };

  const overrideProps = {
    policyNumber: {
      content: translator(messages.policyIdValue, {
        id: latestPolicyPeriod.policyNumber,
      }),
    },
    policyEffectiveDate: {
      value: translator(messages.policyEffectiveDate, {
        effectiveDt: CustomFormatterUtil.formatDate(latestPolicyPeriod.effective)
      }),
    },
    mailingAddress: {
      value: translator(messages.mailingAddress, {
        mailingAdd: latestPolicyPeriod.mailingAddress_Ext.displayName
      }),
    },
    totalPremium: {
      value: translator(messages.totalPremium, {
        totalPremAmount: CustomFormatterUtil.formatAmount(latestPolicyPeriod.premium.amount),
      }),
    },
    policyExpirationDate: {
      value: translator(messages.policyExpirationDate, {
        expDate: CustomFormatterUtil.formatDate(latestPolicyPeriod.expiration),
      }),
    },
    phoneNumber: {
      value: translator(messages.phoneNumber, {
        phoneNo: CustomFormatterUtil.formatPhoneNumber(latestPolicyPeriod.phoneNumber_Ext),
      }),
    },
    policyStatus: {
      value: isPolicyStatusInForce()
        ? translator(messages.statusActive)
        : isPolicyStatusExpired()
        ? translator(messages.statusCanceled)
        : latestPolicyPeriod.status_Ext,
    },
    email: {
      value: translator(messages.email, {
        emailAdd: latestPolicyPeriod.email_Ext,
      }),
    },
    taxAndFees: {
      value: translator(messages.taxAndFees, {
        taxes: CustomFormatterUtil.formatAmount(latestPolicyPeriod.charges.amount),
      }),
    },
    totalCosts: {
      value: translator(messages.totalCosts, {
        cost: CustomFormatterUtil.formatAmount(latestPolicyPeriod.cost.amount),
      }),
    },
    policyCardAgentBannerNumber: {
      content: CustomFormatterUtil.formatPhoneNumber(props.agentDetails.producer_details.producerCodeOfServiceProducerPhone_Ext) + "."
    },
    infoLogo: {
      src: CustomIconUtil.getLogoSrc('blue_info_icon.png'),
    }
  };

  const resolvers = {
    resolveClassNameMap: styles,
    resolveValue: readValue,
  };

  return renderContentFromMetadata(
    metadata.componentContent,
    overrideProps,
    resolvers
  );
}

CustomPolicyCommonDetails.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export default CustomPolicyCommonDetails;
