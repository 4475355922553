/* eslint-disable max-len */
import React, {
    useCallback, useState, useEffect, useContext
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { PolicyService } from 'gw-capability-policy';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { BreakpointTrackerContext, Flex, FlexItem } from '@jutro/layout';
import { DataTable, DisplayColumn, FilterBar } from '@jutro/datatable';
import { CustomIconUtil, CustomFormatterUtil } from 'gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import { Tooltip, Link } from '@jutro/components';
import { useAuthentication } from 'gw-digital-auth-react';
import appConfig from 'app-config';

import styles from './PolicyCommonDocuments.module.scss';
import messages from './PolicyCommonDocuments.messages';
import metadata from './PolicyCommonDocuments.metadata.json5';
import FileUploadViewerComponent from './FileUploadViewerComponent/FileUploadViewerComponent';
import policyDocumentTypesList from './policyDocumentTypes.json';
import CustomDocumentService from '../../../../capabilities/gw-capability-policydocument/services/CustomDocumentService';

function PolicyCommonDocuments(props) {
    const { value: policyDetails } = props;
    const [filters, setFilters] = useState({});
    const breakpoint = useContext(BreakpointTrackerContext);
    const translator = useContext(TranslatorContext);
    const { authHeader } = useAuthentication();
    const policyDocumentDTO = policyDetails.documentDTOs;
    const policyDocumentTypes = { ...policyDocumentTypesList };
    const defaultTableConfig = { page: 0, pageSize: 5 };
    const [tableConfig, setTableConfig] = useState(defaultTableConfig);
    const [dateModified, setDateModified] = useState('none');
    const defaultFilterValues = { signatureRequired_Ext: 'All', documentType_Ext: 'All' };

    policyDocumentDTO.map((policyDocDTO, index) => {
        policyDocDTO.id = index;
    });

    const getDocumentLinks = useCallback(async () => {
        policyDocumentDTO.map((policyDocDTO_Ext) => {
            const docURLService = PolicyService.getDocRenderURL({ GUID: policyDocDTO_Ext.docUID, DocumentClass_Ext: policyDocDTO_Ext.documentClass_Ext }, authHeader);
            docURLService.then((result) => {
                policyDocDTO_Ext.docURL = result.url;
            });
        });
    }, []);

    function updateFilters(newFilters) {
        setFilters(newFilters);
        setTableConfig(defaultTableConfig);
    }
    /**
     * 1561098 - To display the documents from filenet via APIM
     */
    const displayDocument = useCallback((event, row) => {
        const url = appConfig.env.RETRIEVE_DOCUMENT_URL + `?guid=${row.docUID}&documentClass=${row.documentClass_Ext}`;
        CustomDocumentService.displayDocument(authHeader, url);        
    },[]);

    const policyDocuments = useCallback((policyDocumentList) => {
        return (
            <>

                <Flex direction="column">
                    <FlexItem>
                        <FilterBar
                            onFiltersChange={updateFilters}
                            uiProps={policyDocumentTypes}
                            customDefaultValue={defaultFilterValues}
                        />
                    </FlexItem>

                    <FlexItem>

                        <DataTable
                            data={policyDocumentList}
                            id="withFilterBar"
                            showSearch={false}
                            defaultConfig={defaultTableConfig}
                            config={tableConfig}
                            onConfigChange={setTableConfig}
                        >
                            <DisplayColumn
                                header="Name"
                                id="name"
                                path="description"
                                textAlign="left"
                                columnProportion={1.5}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}>
                                        {row.signatureRequired_Ext ? (
                                            <div>
                                                <span><Link onClick={(event) => displayDocument(event, row)}  target="_blank" className={styles.linkCell}>{row.description}</Link></span>
                                                <Tooltip content="Sign Required" placement="top" trigger="mouseenter"><img src={CustomIconUtil.getLogoSrc('signRequired.png')} alt="" className={styles.signRequired} /></Tooltip>
                                            </div>
                                        ) : <span><Link onClick={(event) => displayDocument(event, row)}  target="_blank" className={styles.linkCell}>{row.description}</Link></span>}
                                    </div>
                                )}

                            />
                            <DisplayColumn
                                header="Type"
                                id="Type"
                                path="documentType_Ext"
                                textAlign="left"
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}>
                                        <span>
                                            {' '}
                                            {row.documentType_Ext}
                                        </span>
                                    </div>
                                )}
                            />
                            <DisplayColumn
                                header="Date prepared"
                                id="date"
                                path="dateModified"
                                textAlign="left"
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}>
                                        <span>
                                            {' '}
                                            {CustomFormatterUtil.formatDate(row.dateModified)}
                                        </span>
                                    </div>
                                )}

                            />
                        </DataTable>
                    </FlexItem>
                </Flex>
            </>
        );
    }, [filters, tableConfig]);

    const getFilteredDocs = useCallback(() => {
        let filteredDocs = policyDetails.documentDTOs;
        if (!_.isEmpty(filters.search)) {
            filteredDocs = filteredDocs.filter((doc) => {
                return (doc.description.toLowerCase().replace(/\s+/g, '').includes(filters.search.toLowerCase().replace(/\s+/g, '')) || doc.documentType_Ext.toLowerCase().replace(/\s+/g, '').includes(filters.search.toLowerCase().replace(/\s+/g, '')) || doc.dateModified.toLowerCase().replace(/\s+/g, '').includes(filters.search.toLowerCase().replace(/\s+/g, '')));
            });
        }
        if (!_.isEmpty(filters.documentType_Ext) && filters.documentType_Ext !== 'All') {
            filteredDocs = filteredDocs.filter((doc) => {
                return (doc.documentType_Ext === filters.documentType_Ext);
            });
        }
        if (!_.isEmpty(filters.signatureRequired_Ext) && filters.signatureRequired_Ext !== 'All') {
            filteredDocs = filteredDocs.filter((doc) => {
                return (doc.signatureRequired_Ext.toString() === filters.signatureRequired_Ext);
            });
        }
        if (!_.isEmpty(filters.startDate)) {
            filteredDocs = filteredDocs.filter((doc) => {
                return (new Date(CustomFormatterUtil.formatDate(doc.dateModified)) >= new Date(CustomFormatterUtil.formatDate(filters.startDate)));
            });
        }
        if (!_.isEmpty(filters.endDate)) {
            filteredDocs = filteredDocs.filter((doc) => {
                return (new Date(CustomFormatterUtil.formatDate(doc.dateModified)) <= new Date(CustomFormatterUtil.formatDate(filters.endDate)));
            });
        }

        return policyDocuments(filteredDocs, filteredDocs.length);
    }, [filters, tableConfig]);

    const mobileDocumentMessage = useCallback(() => {
        return (
            <div className={styles.rotatepage}>
                <div className={styles.rotatepagecontent}>
                    <img src={CustomIconUtil.getLogoSrc('flipyourphone.png')} alt="" className={styles.rotatepageimage} />
                    <h3 className={styles.rotatemessageheader}>{translator(messages.rotateMessageHeader)}</h3>
                    <div className={styles.rotatepagemessage}>
                        {translator(messages.rotateMessageContent)}
                    </div>
                </div>
            </div>
        );
    }, [translator]);

    let wasDateChanged;

    const onFilterChangeHandler = useCallback((event) => {
        wasDateChanged = false;
        if (dateModified === 'startDate') {
            const startDate = document.getElementById('startDate').value;
            if ((CustomFormatterUtil.formatDate(filters.startDate) !== CustomFormatterUtil.formatDate(startDate))) {
                wasDateChanged = true;
            }
        } else if (dateModified === 'endDate') {
            const endDate = document.getElementById('endDate').value;
            if ((CustomFormatterUtil.formatDate(filters.endDate) !== CustomFormatterUtil.formatDate(endDate))) {
                wasDateChanged = true;
            }
        }

        if (wasDateChanged || (event.target.parentElement !== undefined && (event.target.parentElement.id === 'documentTypeFilter-wrapper--menu-list' || event.target.parentElement.id === 'signatureRequiredFilter-wrapper--menu-list'))) {
            document.getElementById('applyFilter').click();
            setDateModified('none');
        }
    }, [dateModified, filters]);

    const blurDateHandler = useCallback((event) => {
        setDateModified(event.target.id);
    }, []);

    useEffect(() => {
        try {
            getDocumentLinks();
        } catch (e) {
            throw new Error(e);
        }
    }, []);

    useEffect(() => {
        document.addEventListener('click', onFilterChangeHandler);
        const startDateElement = document.getElementById('startDate');
        const endDateElement = document.getElementById('endDate');

        if (startDateElement !== null && endDateElement !== null) {
            startDateElement.addEventListener('blur', blurDateHandler);
            endDateElement.addEventListener('blur', blurDateHandler);
        }

        return () => {
            document.removeEventListener('click', onFilterChangeHandler);
            if (startDateElement !== null && endDateElement !== null) {
                startDateElement.removeEventListener('blur', blurDateHandler);
                endDateElement.removeEventListener('blur', blurDateHandler);
            }
        };
    }, [onFilterChangeHandler, blurDateHandler]);

    const overrides = {

        documentDiv: {
            content: breakpoint !== 'phone' ? getFilteredDocs() : mobileDocumentMessage(),
        }
    };

    const resolvers = {
        resolveComponentMap: {
            documentscomponent: FileUploadViewerComponent
        },
        resolveClassNameMap: styles,
        resolveCallbackMap: {
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}

        />
    );
}

PolicyCommonDocuments.propTypes = {
    value: PropTypes.shape({
        documentDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        policyNumber: PropTypes.string,
        canUploadDocument: PropTypes.bool
    }),
};

PolicyCommonDocuments.defaultProps = {
    value: {
        documentDTOs: [],
        policyNumber: '',
        canUploadDocument: false
    }
};

export default withRouter(PolicyCommonDocuments);
