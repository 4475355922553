import React, { useCallback, useContext } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter, Link } from 'react-router-dom';
import { CustomFormatterUtil } from 'gw-portals-util-js';
import { TranslatorContext } from '@jutro/locale';
import metadata from './CustomMobileTable.metadata.json5';
import styles from './CustomMobileTable.module.scss';
import messages from './CustomMobileTable.messages';

function CustomMobileTable(props) {
    const { data: claimsData, issuedPayments } = props;
    const translator = useContext(TranslatorContext);

    const generateRowOverrides = useCallback((data) => {
        const overrides = data.map((row, index) => {
            return {
				[`claimNumColumn${index}`]: {
					value: <Link to={'/claims/'+row.claimNumber} className={styles.linkCell}>{row.claimNumber}</Link>,
                    visible: !!claimsData
				},
                [`lossDateColumn${index}`]: {
                    value: CustomFormatterUtil.formatDateForTable(row.lossDate),
                    visible: !!claimsData

                },
                [`lossCauseColumn${index}`]: {
                    value: row.lossCause,
                    visible: !!claimsData
                },
                [`claimStatusColumn${index}`]: {
                    value: row.derivedClaimStatus,
                    visible: !!claimsData
                },
                [`viewClaimDetailsLink${index}`]: {
                    to: `/claims/${row.claimNumber}`,
                    visible: !!claimsData
                },
                [`rowNumColumn${index}`]: {
                    value: index + 1,
                    visible: !!issuedPayments
                },
                [`amountColumn${index}`]: {
                    value: row.grossAmount ? CustomFormatterUtil.formatAmount(row.grossAmount.amount): '-' ,
                    visible: !!issuedPayments
                },
                [`disbursementDateColumn${index}`]: {
                    value: CustomFormatterUtil.formatDateForTable(row.scheduledDate),
                    visible: !!issuedPayments
                },
                [`gridSeparator${index}`]: {
                    visible: !!claimsData || (!!issuedPayments && index !==  issuedPayments.length - 1)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [claimsData, issuedPayments]);

    const overrides = {
        mobileTableIterator: {
            data: claimsData || issuedPayments
        },
        noClaimsFoundMessage: {
            visible: (claimsData === undefined || claimsData.length === 0) && (issuedPayments === undefined || issuedPayments.length === 0),
            content: issuedPayments === undefined ? translator(messages.noClaimDataText) : translator(messages.noClaimPaymentDataText)
        },
        ...generateRowOverrides(claimsData || issuedPayments)
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
        }
    };

    return renderContentFromMetadata(metadata.pageContent, overrides, resolvers);
}

export default withRouter(CustomMobileTable);
