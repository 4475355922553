import React, {
    useEffect, useContext, useState, useCallback
} from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withRouter, Link } from 'react-router-dom';
import { ClaimService } from 'gw-capability-claim';
import { useAuthentication } from 'gw-digital-auth-react';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import { BreakpointTrackerContext, Flex, FlexItem } from '@jutro/layout';
import {
    CustomIconUtil, CustomPolicyTypeUtil, CustomFormatterUtil, CustomTooltipUtil
} from 'gw-portals-util-js';
import { LoadingContext } from 'gw-capability-policy-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import messages from './CustomClaimCard.messages';
import styles from '../../pages/CustomClaimCardList/CustomClaimCardList.module.scss';
import metadata from './CustomClaimCard.metadata.json5';
import CustomMobileTable from '../CustomMobileTable/CustomMobileTable';

function CustomClaimCard(props) {
    const { authHeader } = useAuthentication();
    const { data: policyData } = props;
    const [policyClaimData, setpolicyClaimData] = useState({});
    const latestPolicyPeriod = _.first(policyData.periods);
    const loader = useContext(LoadingContext);
    const { capabilitiesConfig } = appConfig;
    const emptyString = '';
    const [apiErrorExists, setAPIErrorExists] = useState(false);
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const [style, setStyle] = useState('hideDetails');
    const isPolicyStatusInForce = () => { return latestPolicyPeriod.status === 'In Force'; };

    const toggleDetails = useCallback((trigger) => () => {
        if (style === 'hideDetails') {
            props.setActiveCard(props.cardId);
        } else if (trigger === 'icon') {
            props.setActiveCard('default');
        }
    }, [props.activeCard, props.setActiveCard, style]);

    useEffect(() => {
        if (props.activeCard === props.cardId) {
            setStyle('displayDetails');
        } else {
            setStyle('hideDetails');
        }
    }, [props]);

    useEffect(() => {
        loader.setLoading(true);
        let claimPromise = Promise.resolve([]);

        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' })) {
            claimPromise = ClaimService.getClaimSummaries({ policyNumber: latestPolicyPeriod.policyId }, {}, authHeader);
        }

        Promise.all([claimPromise.then((resp) => {
            setpolicyClaimData(resp);
        }).catch((error) => {
            setAPIErrorExists(error.errorCode === 'GW_ERROR');
        })]).finally(() => {
            loader.setLoading(false);
        });
    }, []);

    const claims = useCallback((claimDetailsList) => {
        return (
            <>
                <Flex direction="column">
                    <FlexItem>
                        <DataTable showSearch={false} showPagination={false} data={claimDetailsList} noDataText={translator(messages.noDataText)} >
                            <DisplayColumn header="Claim #" id="claim" path="claimNumber" textAlign="left" sortable={false} columnProportion={1.5}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}> <Link to={`/claims/${row.claimNumber}`} className={styles.linkCell}>{row.claimNumber}</Link> </div>
                                )} />
                            <DisplayColumn header="Date of loss" id="dateofloss" path="dateofloss" textAlign="left" columnProportion={1.5}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}> <span> {CustomFormatterUtil.formatDateForTable(row.lossDate)} </span> </div>
                                )} />
                            <DisplayColumn header="Cause of loss" id="losscause" path="losscause" textAlign="left" columnProportion={1.5} sortable={false}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}> <span> {row.lossCause} </span> </div>
                                )} />
                            <DisplayColumn header="Status" id="status" path="status" textAlign="left" columnProportion={1.5} sortable={false}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}> <span> {row.derivedClaimStatus} </span> </div>
                                )} />
                            <DisplayColumn header="" id="status" path="status" textAlign="right" columnProportion={1.5} sortable={false}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}> <Link to={`/claims/${row.claimNumber}`} className={styles.linkCell}>View claim details</Link> </div>
                                )} />
                        </DataTable>
                    </FlexItem>
                </Flex>
            </>
        );
    }, []);

    const getPolicyStatus = (policy) => {
        if (isPolicyStatusInForce(policy)) {
            return translator(messages.statusActive);
        }
        return policy.status;
    };

    if (_.isEmpty(policyClaimData)) {
        return null;
    }

    const overrides = {
        claimsdiv: {
            content: claims(policyClaimData.items),
            visible: !(breakpoint === 'phoneWide' || breakpoint === 'phone')
        },
        viewPolicyButton: {
            to: `/account-policy-details/${latestPolicyPeriod.policyId}`,
            className: 'claim-card-view-policy-button'
        },
        policyTypeAvatar: {
            imageSource: CustomIconUtil.getCustomPolicyTypeIcon(latestPolicyPeriod.policyType)
        },
        policyType: {
            content: CustomPolicyTypeUtil.getCustomPolicyTitle(latestPolicyPeriod),
        },
        policyNumber: {
            content: translator(messages.policyIdValue, {
                id: latestPolicyPeriod.policyId,
            }),
        },
        claimCardContactAgentNumber: {
            content: CustomFormatterUtil.formatPhoneNumber(policyData.producerContact.producerCodeOfServiceProducerPhone_Ext) + ".",
            href: isMobile ? `tel:${CustomFormatterUtil.formatPhoneNumber(policyData.producerContact.producerCodeOfServiceProducerPhone_Ext)}`+ "." : ''
        },
        copyContentContainer: {
            content: (latestPolicyPeriod.policyId) ? CustomTooltipUtil.getTooltip(latestPolicyPeriod.policyId) : emptyString
        },
        policyStatus: {
            content: getPolicyStatus(latestPolicyPeriod)
        },
        claimsMobileTable: {
            visible: (breakpoint === 'phoneWide' || breakpoint === 'phone'),
            data: policyClaimData.items
        },
        toggleDetails: {
            className: style
        },
        toggleClaimsCard: {
            visible: isMobile
        },
        toggleClaimsCardIcon: {
            src: (style === 'displayDetails') ? CustomIconUtil.getLogoSrc('collapse-card.png') : CustomIconUtil.getLogoSrc('expand-card.png'),
            onClick: toggleDetails('icon'),
        },
        claimListCard: {
            onClick: toggleDetails('card'),
        }
    };
    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            claimsmobiletable: CustomMobileTable
        }

    };

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

export default withRouter(CustomClaimCard);
