import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { MetadataForm } from '@jutro/uiconfig';
import { TranslatorContext } from "@jutro/locale";
import metadata from './CustomClaimCardComponent.metadata.json5';
import styles from './CustomClaimCardComponent.module.scss';
import messages from './CustomClaimCardComponent.messages.js';

function CustomClaimCardComponent(props) {    
    const translator = useContext(TranslatorContext);

    const getCompletedProgress = useCallback((policyState) => {
        if (policyState !== null) {
            switch (policyState.toLowerCase()) {
                case 'received':
                    return 1;
                case 'assigned':
                    return 2;
                case 'under review':
                    return 3;
                case 'loss inspection scheduled':
                    return 4;
                case 'waiting on inspection report':
                    return 5;
                case 'inspection report received':
                    return 6;
                case 'paid and open':
                    return 7;
                case 'paid and closed':
                case 'closed':
                    return 8;
                default:
                    return 0;
            }
        } else {
            return 0;
        }
    });

    const getClaimHeader = useCallback(() => {


        if (props.data.multipleVehiclesInvolved_Ext) {
            return translator(messages.multipleVehicles);
        }
        else if (typeof(props.data.vehicleModel_Ext) !== "undefined" && props.data.vehicleModel_Ext !== null) {
            return props.data.vehicleYear_Ext + " " + props.data.vehicleModel_Ext + " / " + props.data.vehicleVIN_Ext;
        }

        return props.data.lossLocation_Ext.substring(0, props.data.lossLocation_Ext.indexOf(',')).toLowerCase() + ".";
    });
    
    const status = (props.data.derivedClaimStatus!== undefined?props.data.derivedClaimStatus:props.data.claimState).toString();
    const overrideProps = {
        claimHeader: {
            content: getClaimHeader()
        },
        claimStatus: {
            content: translator(messages.claimStatus) + status
        },
        claimsProgressBar: {
            completed: getCompletedProgress(status)
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
};

CustomClaimCardComponent.defaultProps = {
};

CustomClaimCardComponent.propTypes = {
};

export default CustomClaimCardComponent;