import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    impnoticeHeaderText: {
        id: 'billing.choose-payment.Important note',
        defaultMessage: 'Important note'
    },
    impnoticeBannerText: {
        id: 'billing.choose-payment.impnoticeBannerText',
        defaultMessage: 'The online payment process is available Monday through Friday 7:00 am - 12:00 am EST. Saturday and Sunday 8:00 am - 9:00 pm EST. Payments made on weekends or holidays will not be applied until the next business day.\n\nPlease allow 24 to 48 hours for your payment to be reflected in your account.'
    },
    makeotpHeaderText: {
        id: 'billing.choose-payment.Make a one-time payment',
        defaultMessage: 'Make a one-time payment'
    },
    makeotpInfoText: {
        id: 'billing.choose-payment.Info Message',
        defaultMessage: 'Use either your credit/debit card or choose EFT to have funds withdrawn from your checking/savings account.'
    },
    makeotpCCButton: {
        id: 'billing.choose-payment.Credit / Debit Card',
        defaultMessage: 'Credit / Debit Card'
    },
    makeotpEFTButton: {
        id: 'billing.choose-payment.Bank account (EFT)',
        defaultMessage: 'Bank account (EFT)'
    },
    makeautopayHeader: {
        id: 'billing.choose-payment.Automate your payments with EZPay',
        defaultMessage: 'Automate your payments with EZPay'
    },
    makeautopayInfoText: {
        id: 'billing.choose-payment.EZPay Message',
        defaultMessage: 'With EZPay, a withdrawal is made from your bank account, or charged to your credit card. We let you pick the date of the month for the payment. Once EZPay begins, you will stop receiving invoices by mail.'
    },
    makeautopayEZPayButton: {
        id: 'billing.choose-payment.Enroll in EZPay now',
        defaultMessage: 'Enroll in EZPay now'
    },
    makephonepayHeader: {
        id: 'billing.choose-payment.Pay by phone',
        defaultMessage: 'Pay by phone'
    },
    makephonepayText: {
        id: 'billing.choose-payment.Pay by Phone Message',
        defaultMessage: 'Call us at 1-800-543-2644'
    },
    makemailpayHeader: {
        id: 'billing.choose-payment.Mail in your payment',
        defaultMessage: 'Mail in your payment'
    },    
    mailpayuspscontent: {
        id: 'billing.choose-payment.Payments delivered via USPS:',
        defaultMessage: 'Payments delivered via USPS:'
    },
    mailpayuspsaddress: {
        id: 'billing.choose-payment.mailpayuspsaddress',
        defaultMessage: 'American Modern Insurance Group\nPO Box 740167\nCincinnati OH 45274-0167'
    },
    mailpayupscontent: {
        id: 'billing.choose-payment.Payments delivered via UPS or Fedex (overnight):',
        defaultMessage: 'Payments delivered via UPS or Fedex (overnight):'
    },
    mailpayupsaddress: {
        id: 'billing.choose-payment.mailpayupsaddress',
        defaultMessage: 'Fifth Third Bank\n5050 Kingsley Drive\nCincinnati, Ohio 45227\nAttn: 1MOC1N - Rlbx 740167'
    },
    mailpayinfocontent: {
        id: 'billing.choose-payment.Please write your policy number on your check, and include the payment coupon.',
        defaultMessage: 'Please write your policy number on your check, and include the payment coupon.'
    }
});