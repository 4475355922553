import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';

import { ModalNextProvider, Loader } from '@jutro/components';
import { ServiceManager } from '@jutro/services';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import { Image } from '@jutro/components';
import { useDependencies } from 'gw-portals-dependency-react';
import { PaymentComponent } from 'gw-components-platform-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as commonMessages } from 'gw-platform-translations';

import metadata from './MakePaymentPage.metadata.json5';
import messages from './MakePaymentPage.messages';

const createNormaliseData = (accountSummaries) => {
    const { activeInvoices: invoiceSummary } = accountSummaries;
    return _.map(invoiceSummary, (invoice) => {
        return {
            dueDate: invoice.dueDate,
            invoiceStatus: invoice.invoiceStatus,
            totalValue: invoice.amountTotal,
            totalPaid: invoice.amountPaid,
            amountDue: invoice.amountDue,
            invoiceNumber: invoice.number,
            invoiceId: invoice.id
        };
    });
};

const createPaymentInstrumentObject = (paymentDetailsVm, paymentMethod) => {
    const paymentDetailsValue = paymentDetailsVm.value;

    const bankAccountData = {
        ...paymentDetailsValue
    };

    const creditCardData = {
        ...paymentDetailsValue
    };

    let detailsValue;
    if (paymentMethod === 'wire') {
        detailsValue = { bankAccountData };
    } else {
        detailsValue = { creditCardData };
    }

    const paymentInstrumentValue = {
        paymentMethod,
        ...detailsValue
    };

    return paymentInstrumentValue;
};

function MakePaymentPage(props) {
    const { authHeader, history } = props;

    const localeService = ServiceManager.getService('locale-service');
    const defaultCurrencyCode = localeService.getDefaultCurrencyCode().toUpperCase();

    const { AccountBillingDetailsService: BillingService } = useDependencies(
        'AccountBillingDetailsService'
    );

    // screen states
    const [isLoadingData, updateIsLoadingData] = useState(true);
    const [selectedInvoicesForPayment, updateSelectedInvoicesForPayment] = useState([]);
    const [selectedScreen, updateSelectedScreen] = useState('default');
    const [selectedCurrency, updateSelectedCurrency] = useState(defaultCurrencyCode);
    const [amountToPay, updateAmountToPay] = useState(null);
    const [invoiceAmountToPay, updateInvoiceAmountToPay] = useState(0);

    // fetched data
    const [billingSummariesFromApi, setBillingSummariesFromApi] = useState({});

    const setFetchedData = useCallback((fetchData) => {
        const { amountTotal } = fetchData;
        if (amountTotal) {
            const { currency } = amountTotal;
            updateSelectedCurrency(currency.toUpperCase());
        }
        setBillingSummariesFromApi(fetchData);
    }, []);

    const fetchBillingSummary = useCallback(() => {
        return BillingService.getAccountBillingSummary(authHeader)
            .then(setFetchedData)
            .finally(() => {
                updateIsLoadingData(false);
            });
    }, [BillingService, authHeader, setFetchedData]);

    const resetInternalStates = useCallback(() => {
        fetchBillingSummary().finally(() => {
            updateSelectedScreen('default');
            updateSelectedInvoicesForPayment([]);
            updateAmountToPay(null);
        });
    }, [fetchBillingSummary]);

    const onClickConfirmMakePayment = useCallback(
        (paymentDetailsVm, paymentMethod) => {
            const paymentInstrumentValue = createPaymentInstrumentObject(
                paymentDetailsVm,
                paymentMethod
            );
            updateIsLoadingData(true);
            BillingService.makeDirectBillPayment(
                selectedInvoicesForPayment,
                amountToPay.amount,
                paymentInstrumentValue,
                authHeader
            )
                .then(() => {
                    updateSelectedScreen('paymentConfirmation');
                })
                .catch(() => {
                    ModalNextProvider.showAlert({
                        title: messages.paymentRequestFailed,
                        message: messages.sorryYourPaymentCouldNotBeProcessedAtThisTime,
                        status: 'error',
                        icon: 'mi-error-outline',
                        confirmButtonText: commonMessages.ok
                    }).then(() => {
                        resetInternalStates();
                    }, _.noop);
                }).finally(() => {
                    updateIsLoadingData(false);
                });
        },
        [BillingService, selectedInvoicesForPayment, amountToPay, authHeader, resetInternalStates]
    );

    const onClickCancelMakePayment = useCallback(() => {
        history.push('/home');
    }, [history]);

    const onClickCompleteMakePayment = useCallback(() => {
        if (appConfig.persona === 'policyholder') {
            history.push('/home');
        } else {
            resetInternalStates();
        }
    }, [history, resetInternalStates]);

    const invoiceSummary = createNormaliseData(billingSummariesFromApi);

    const onUpdateSelectedInvoices = useCallback(
        (selectedInvoiceSummariesId) => {
            const selectedInvoiceObjects = _.filter(invoiceSummary, (anInvoiceSummary) => {
                return selectedInvoiceSummariesId.includes(anInvoiceSummary.invoiceId);
            });

            const selectedTotalAmount = _.sumBy(selectedInvoiceObjects, (invoice) => {
                const { amountDue } = invoice;
                const { amount } = amountDue;
                return Number(amount);
            }).toFixed(2);

            updateInvoiceAmountToPay(selectedTotalAmount);
            const amountObject = {
                amount: selectedTotalAmount,
                currency: selectedCurrency
            };

            updateAmountToPay(amountObject);
            updateSelectedInvoicesForPayment(selectedInvoiceSummariesId);
        },
        [invoiceSummary, selectedCurrency]
    );

    const isAmountToPayValid = useCallback((amount) => {
        return invoiceAmountToPay < amount || amount === 0 || _.isNull(amount);
    }, [invoiceAmountToPay]);

    useEffect(() => {
        updateIsLoadingData(true);
        fetchBillingSummary();
    }, [fetchBillingSummary]);

    if (isLoadingData) {
        return <Image src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} />;
    }

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        makePaymentContainerId: {
            visible: selectedScreen === 'default' && invoiceSummary.length > 0
        },
        makePaymentInvoicesTableComponentId: {
            invoiceSummary: invoiceSummary,
            selectedInvoices: selectedInvoicesForPayment,
            onUpdateSelectedInvoices: onUpdateSelectedInvoices
        },
        makePaymentSourceComponentId: {
            title: messages.setPaymentSource,
            xCenter: 'bc',
            isDisabled: selectedInvoicesForPayment.length === 0,
            isSelectedInvoiceAmount: amountToPay && isAmountToPayValid(amountToPay.amount)
        },
        makePaymentAmountToPayId: {
            defaultCurrency: selectedCurrency,
            disabled: selectedInvoicesForPayment.length === 0,
            onValueChange: updateAmountToPay
        },
        makePaymentConfirmationContainerId: {
            visible: selectedScreen === 'paymentConfirmation'
        },
        makePaymentNoPayableInvoicesContainerId: {
            visible: invoiceSummary.length === 0
        }
    };

    const dataForComponent = {
        amountToPay
    };

    const resolvers = {
        resolveComponentMap: {
            paymentpagecomponent: PaymentComponent
        },
        resolveCallbackMap: {
            onClickConfirmMakePayment,
            onClickCancelMakePayment,
            onClickCompleteMakePayment
        }
    };

    return (
        <ViewModelForm
            model={dataForComponent}
            uiProps={metadata.pageContent}
            overrideProps={overrideProps}
            classNameMap={resolvers.resolveClassNameMap}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

MakePaymentPage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(MakePaymentPage));
