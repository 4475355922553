import React, {
    useContext, useEffect, useState, useCallback
} from 'react';
import { useAuthentication } from 'gw-digital-auth-react';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomAccountPrefContext } from 'gw-capability-profileinfo-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import CustomMobileHeaderComponent from './mobile/CustomMobileHeaderComponent';
import metadata from './CustomHeaderComponent.metadata.json5';
import styles from './CustomHeaderComponent.module.scss';

function CustomHeaderComponent(props) {
    const auth = useAuthentication();
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const validTabs = {
        myPolicies: 'My policies', billingAndPayment: 'Billing and payment', faq: 'FAQ', accountPref: 'Account preferences', myClaims: 'My claims'
    };
    const pageRedirects = {
        myPolicies: '/home', billingAndPayment: '/billing-summary', faq: '/faq', accountPref: '/userprofile', myClaims: '/claims'
    };
    const [currentTab, setCurrentTab] = useState('default');
    const cardStatus = useContext(CustomAccountPrefContext);
    const [cardBeingEdited, setCardBeingEdited] = useState(false);

    const toggleUnsavedChangesBanner = useCallback(() => () => {
        cardStatus.setShowUnsavedChangesBanner(cardStatus.cardBeingEdited);
    }, [cardBeingEdited]);

    useEffect(() => {
        setCardBeingEdited(cardStatus.cardBeingEdited);
    }, [cardStatus.cardBeingEdited]);

    useEffect(() => {
        new MutationObserver((mutations) => {
            setCurrentTab(mutations[0].target.innerHTML);
        }).observe(
            document.querySelector('title'),
            { childList: true }
        );
    }, []);

    const overrideProps = {
        amiglogo: {
            onClick: toggleUnsavedChangesBanner(),
            to: cardBeingEdited ? pageRedirects.accountPref : pageRedirects.myPolicies,
        },
        amigimage: {
            src: CustomIconUtil.getLogoSrc('AMIG-desktopHeaderLogo.png'),
            disabled: true
        },
        avatarwrapper: {
            visible: !isMobile,
        },
        dropdownavatar: {
            title: `${auth.given_name} ${auth.family_name}`,
            username: auth.name,
            imageSource: CustomIconUtil.getLogoSrc('avatar-icon.png')
        },
        myPoliciesLink: {
            visible: !isMobile,
            onClick: toggleUnsavedChangesBanner(),
            to: cardBeingEdited ? pageRedirects.accountPref : pageRedirects.myPolicies,
            className: currentTab.includes(validTabs.myPolicies) ? 'active-button-link' : 'button-link'
        },
        myClaimsLink: {
            visible: !isMobile,
            onClick: toggleUnsavedChangesBanner(),
            to: cardBeingEdited ? pageRedirects.accountPref : pageRedirects.myClaims,
            className: currentTab.includes(validTabs.myClaims) ? 'active-button-link' : 'button-link'
        },
        billingAndPaymentLink: {
            visible: !isMobile,
            onClick: toggleUnsavedChangesBanner(),
            to: cardBeingEdited ? pageRedirects.accountPref : pageRedirects.billingAndPayment,
            className: currentTab.includes(validTabs.billingAndPayment) ? 'active-button-link' : 'button-link'
        },
        getHelpLink: {
            visible: !isMobile,
            onClick: toggleUnsavedChangesBanner(),
            to: cardBeingEdited ? pageRedirects.accountPref : pageRedirects.faq,
            className: currentTab.includes(validTabs.faq) ? 'active-button-link' : 'button-link'
        },
        custommobileheadercomponent: {
            visible: isMobile,
            currentTab: currentTab,
            cardBeingEdited: cardBeingEdited,
            toggleUnsavedChangesBanner: toggleUnsavedChangesBanner
        },
        logOffLink: {
            onClick: auth.logout
        },
        accountPreferencesLink: {
            to: pageRedirects.accountPref,
            onClick: toggleUnsavedChangesBanner(),
            className: currentTab.includes(validTabs.accountPref) ? 'active-avatar-quick-links customHeaderMobile' : 'avatar-quick-links customHeaderMobile'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            custommobileheadercomponent: CustomMobileHeaderComponent
        },
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomHeaderComponent.defaultProps = {
};

CustomHeaderComponent.propTypes = {

};
export default CustomHeaderComponent;
