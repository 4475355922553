import React, {
    useCallback,
    useState, useEffect, useContext
} from 'react';
import _ from 'lodash';
import { TranslatorContext } from '@jutro/locale';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import styles from './QuotePage.module.scss';
import metadata from './QuotePage.metadata.json5';

function QuotePage(props) {
    const { wizardData } = props;
    const { submissionVM } = wizardData;
    const { isLoggedIn } = useAuthentication();
    const [allValuable, setAllValuable] = useState();
    const [allMortgagee, setAllMortgagee] = useState();
    const translator = useContext(TranslatorContext);

    const getDisplayName = (propertyInfos, type) => {
        const availableTypeLists = propertyInfos
            .flat()
            .filter((val) => val.availableTypeListValues)
            .find((availableType) => availableType.id === 'ArticleType');
        const availableTypeListValues = availableTypeLists.availableTypeListValues || [];
        return availableTypeListValues.find((list) => list.code === type).displayKey;
    };

    useEffect(() => {
        const setValuableNames = () => {
            const valuable = _.get(
                submissionVM.value,
                'lobData.homeowners.offerings[0].coverages.schedules',
                []
            );
            const scheduleItems = valuable.map((valuables) => valuables.scheduleItems);
            const propertyInfos = valuable.map((valuables) => valuables.propertyInfos);
            const displayNames = _.flatMap(scheduleItems).map((item) => {
                return !_.isUndefined(item.itemData.ArticleType)
                    ? getDisplayName(propertyInfos, item.itemData.ArticleType.typeCodeValue)
                    : _.get(item, 'itemData.SchedItemDescriptionId.stringValue', '-');
            });
            setAllValuable(displayNames.join(', '));
        };

        const setMortgageeNames = () => {
            const mortgagee = _.get(
                submissionVM.value,
                'lobData.homeowners.coverables.additionalInterests',
                []
            );
            const mortgageeNames = mortgagee.map(
                (mortgagees) => mortgagees.policyAdditionalInterest
            );
            const displayNames = mortgageeNames.map((mortgageeName) => mortgageeName.displayName);
            setAllMortgagee(displayNames.join(', '));
        };
        if (isLoggedIn) {
            setValuableNames();
            setMortgageeNames();
        }
    }, [isLoggedIn, submissionVM]);

    const resolvers = {
        resolveClassNameMap: styles
    };
    const overrideProps = {
        valuables: {
            value: allValuable
        },
        mortgagee: {
            value: allMortgagee
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    return (
        <WizardPage
            cancelLabel={translator(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

QuotePage.propTypes = wizardProps;
export default QuotePage;
