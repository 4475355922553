import { defineMessages } from 'react-intl';

export default defineMessages({
    paCoverages: {
        id: 'endorsement.pa.directives.templates.pa-edit-coverage.Coverages',
        defaultMessage: 'Coverages',
    },
    lineCoverages: {
        id: 'endorsement.pa.directives.templates.pa-edit-coverage.Line Coverages',
        defaultMessage: 'General Coverages (Apply to all vehicles)',
    },
    vehicleCoverages: {
        id: 'endorsement.pa.directives.templates.pa-edit-coverage.Vehicle Coverages',
        defaultMessage: 'Vehicle Coverages',
    },
    saveCoverageError: {
        id: 'endorsement.pa.config.Unable to save coverages change',
        defaultMessage: 'Unable to save coverages change'
    },
    saveCoverageErrorMessage: {
        id: 'endorsement.pa.config.An error occurred while attempting to save coverage changes',
        defaultMessage: 'An error occurred while attempting to save coverage changes'
    }
});
