import React from 'react';
import { Link, Tooltip } from '@jutro/components';
import { CustomIconUtil } from 'gw-portals-util-js';

const getTooltip = (tooltipCopyContent) => {
    const customTooltip = [
        <>
            <Tooltip
                content="Copied"
                id="tooltip"
                placement="top"
                trigger="click">
                <div className="amigTooltip">
                    <Tooltip
                        content="Copy to clipboard"
                        id="tooltip"
                        placement="top">
                        <Link><img onClick={function copyGivenContent() { navigator.clipboard.writeText(tooltipCopyContent); }} className="amigTooltipCopyContent" src={CustomIconUtil.getLogoSrc('copy-content-icon.png')} /></Link>
                    </Tooltip>
                </div>
            </Tooltip>
        </>
    ];

    return customTooltip;
};

export default {
    getTooltip: getTooltip
};