import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import { Image } from '@jutro/components';
import { CustomIconUtil } from 'gw-portals-util-js';
import { LoadingContext } from 'gw-capability-policy-react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import appConfig from 'app-config';
import metadata from './CustomDocumentDelivery.metadata.json5';
import styles from './CustomDocumentDelivery.module.scss';
import messages from './CustomDocumentDelivery.messages';

function CustomDocumentDelivery(props) {
    const loader = useContext(LoadingContext);
    const [policyDocDeliveryEditMode, setPolicyDocDeliveryEditMode] = useState(false);
    const translator = useContext(TranslatorContext);
    const [formData, updateFormData] = useState(props.deliveryData);
    const [localFormData, updateLocalFormData] = useState(formData);
    const [showWarningMessage, setShowWarningMessage] = useState(false);
    const { isLoading } = props;

    const accountPrefOptions = {
        paperless: 'paperless',
        usMail: 'usMail'
    };

    function getOptionLabel(optionID) {
        switch (optionID) {
            case accountPrefOptions.paperless:
                return translator(messages.paperless);
            case accountPrefOptions.usMail:
                return translator(messages.usMail);
            default:
                return ' - ';
        }
    }

    function getSelectedDeliveryMethod(deliveryMethod) {
        if (localFormData.policyDocDelivery === undefined) {
            return deliveryMethod;
        }
        return localFormData.policyDocDelivery;
    }

    const writeFormValue = useCallback((docDeliveryType) => (newValue) => {
        updateLocalFormData({ ...localFormData, [docDeliveryType]: newValue });
    }, [localFormData]);

    const togglePolicyDocForm = useCallback(() => () => {
        if (policyDocDeliveryEditMode === false) {
            setPolicyDocDeliveryEditMode(true);
            updateLocalFormData(formData);
        } else {
            updateLocalFormData(formData);
            setPolicyDocDeliveryEditMode(false);
            props.setSaveChangesWarning(false);
            props.setToEditMode(false);
            props.dismissNotification('unsavedChangesNotification').call();
        }
    }, [policyDocDeliveryEditMode, formData]);

    const saveDocChanges = useCallback(() => () => {
        props.setLoadingState(true);
        updateFormData(localFormData);
        props.deliveryMethods(localFormData);
        props.updatePref('documentDelivery', localFormData);
        setPolicyDocDeliveryEditMode(false);
    }, [localFormData, props]);

    const dismissUnsavedChangesWarningMessage = useCallback(() => (event) => {
        event.stopPropagation();
        props.setSaveChangesWarning(false);
    }, []);

    useEffect(() => {
        loader.setLoading(false);
        updateFormData(props.deliveryData);
    }, [props.deliveryData]);

    useEffect(() => {
        if (!(formData === localFormData)) {
            props.setToEditMode(true);
        }
    }, [localFormData]);

    useEffect(() => {
        setShowWarningMessage(props.showSaveChangesWarning);
    }, [props.showSaveChangesWarning]);

    const overrideProps = {
        policyDeliveryMethodText: {
            visible: policyDocDeliveryEditMode
        },
        policyDocDeliveryDetails: {
            content: getOptionLabel(formData.policyDocDelivery),
            visible: !policyDocDeliveryEditMode
        },
        editPolicyDocDelivery: {
            visible: !policyDocDeliveryEditMode,
            onClick: togglePolicyDocForm()
        },
        policyDocFormContainer: {
            visible: policyDocDeliveryEditMode
        },
        cancelPolicyDocButton: {
            onClick: togglePolicyDocForm()
        },
        saveChangesPolicyDocButton: {
            onClick: saveDocChanges(),
            disabled: formData === localFormData
        },
        policyDeliveryMethod: {
            value: getSelectedDeliveryMethod(formData.policyDocDelivery),
            onValueChange: writeFormValue('policyDocDelivery')
        },
        termsAndConditionsLink: {
            href: appConfig.env.PAPERLESS_TERMS_CONDITIONS_URL,
            target: '_blank'
        },
        docDeliveryWarningDivForUnsavedChanges: {
            visible: showWarningMessage,
            className: 'showWarningMessage'
        },
        docDeliveryDismissWarningMessage: {
            onClick: dismissUnsavedChangesWarningMessage()
        }
    };

    if (isLoading) {
        return (
          <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc("rotating-circle-blue_128.gif")} showLoader={false} />
        );
    }

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomDocumentDelivery.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(CustomDocumentDelivery));
