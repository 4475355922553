import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriver: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.Driver',
        defaultMessage: 'Driver',
    },
    paDrivers: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.Drivers',
        defaultMessage: 'Drivers',
    },
    paNewDriver: {
        id: 'endorsement.pa.directives.templates.pa-edit-driver.New Driver',
        defaultMessage: 'New Driver',
    },
    licenceMatchMsg: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.LicenceMatchMsg',
        defaultMessage: 'Sorry, but this drivers license number matches that of {driver}. Please ensure that all licence numbers are unique.',
    },
    licenseNotUnique: {
        id: 'quoteandbind.pa.controllers.PADriverDetailsCtrl.Licence number is not unique',
        defaultMessage: 'Licence number is not unique'
    },
    addAnotherDriver: {
        id: 'endorsement.pa.views.pa-driver-details.Add Another Driver?',
        defaultMessage: 'Add Another Driver?'
    },
    addDriver: {
        id: 'endorsement.pa.views.pa-driver-details.Add Driver',
        defaultMessage: 'Add Driver'
    }
});
