import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { LoadingContext } from 'gw-capability-policy-react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import appConfig from 'app-config';
import metadata from './CustomLoginSecurityComponent.metadata.json5';
import styles from './CustomLoginSecurityComponent.module.scss';

function CustomLoginSecurityComponent(props) {
    const { env } = appConfig;
    const loader = useContext(LoadingContext);
    const auth = useAuthentication();
    const [contactData, updateContactData] = useState(props.userDetails);

    useEffect(() => {
        loader.setLoading(false);
    }, []);

    useEffect(() => {
        // update contact data
        updateContactData({ emailAddress1: props.userDetails.emailAddress1 });
    }, [props.userDetails]);

    const overrideProps = {
        infoLogo: {
            src: CustomIconUtil.getLogoSrc('blue_info_icon.png')
        },
        updatePasswordButton: {
            onClick: auth.logout
        },
        editSecurity: {
            onClick: props.toggleAccount(env.TENANT_PASSWORD_FLOW)
        },
        editUserID: {
            onClick: props.toggleAccount(env.TENANT_USERID_FLOW)
        },
        userId: {
            value: contactData.emailAddress1,
            onClick: props.toggleAccount()
        },
        updatePasswordText: {
            content:
    <span className="updatePasswordText">
        Need to update password?
        <Link
            className={styles.updatePasswordLink}
            href="/"
            onClick={auth.logout}
        >
                    &nbsp;Click here&nbsp;
        </Link>
        to use &quot;Forgot your password?&quot;
    </span>
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomLoginSecurityComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(CustomLoginSecurityComponent));
