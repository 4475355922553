import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    howCanWeHelp: {
        id: 'faq.directive.How can we help you today?',
        defaultMessage: 'How can we help you today?',
    },
    policyBillingClaimStatus: {
        id: 'faq.sections.sections.Policy, billing and claim status',
        defaultMessage: 'Policy, billing and claim status',
    },
    commNotifications: {
        id: 'faq.sections.Communications and notifications',
        defaultMessage: 'Communications and notifications'
    },
    myDocuments: {
        id: 'faq.sections.My documents',
        defaultMessage: 'My documents'
    },
    troubleshooting: {
        id: 'faq.sections.Troubleshooting',
        defaultMessage: 'Troubleshooting'
    },
    general: {
        id: 'faq.sections.General',
        defaultMessage: 'General'
    },
    faqBillPayQuestion: {
        id: 'faq.topics.policy_billing_claim_status.billPay.question',
        defaultMessage: 'How can I pay my bill?'
    },
    faqBillPayAnswer: {
        id: 'faq.topics.policy_billing_claim_status.billPay.answer.long',
        defaultMessage: '<p>We offer several flexible ways to pay: <ul><li>EZPay automatic payments – You can schedule and manage recurring payments so that they are automatically transferred from your checking or savings account or charged to your debit or credit card. We accept Discover®, MasterCard®, Visa® and American Express® cards.</li><li>Pay online – You can make one-time, electronic payments from a checking or savings account or with a debit or credit card. We accept Discover®, MasterCard®, Visa®, and American Express® cards. The online payment service is available Monday through Friday from 7 a.m. to 12 a.m. EST and Saturday and Sunday from 8 a.m. to 9 p.m.</li><li>Mail a payment – You can mail a check or money order to the address listed on your billing statement. Please be sure to include the billing coupon from the billing notice or policy packet and include it in the envelope with your payment.</li><li>Pay by phone – You can make a payment by phone by calling (855) 469-2644. Our "automated attendant" will walk you through the process quickly and easily.</li></ul></p>'
    },
    faqBillPayLink: {
        id: 'faq.topics.policy_billing_claim_status.billPay.link.Billing and payment',
        defaultMessage: 'Billing and payment'
    },
    faqAddPolicyLink: {
        id: 'faq.topics.policy_billing_claim_status.addPolicy.link.Add policy',
        defaultMessage: 'Add policy'
    },
    faqOnlinePaymentQuestion: {
        id: 'faq.topics.policy_billing_claim_status.onlinePayment.question',
        defaultMessage: 'Can I see my payment history in my online account?'
    },
    faqOnlinePaymentAnswer: {
        id: 'faq.topics.policy_billing_claim_status.onlinePayment.answer.long',
        defaultMessage: 'You can view recent billing information for your account by clicking “My policies” and reviewing payment details. You can view all your Billing and payment information by clicking “Billing and payment” on the top navigation bar. Then select “Payment history” to locate payment history.'
    },
    faqPaymentEmailQuestion: {
        id: 'faq.topics.policy_billing_claim_status.paymentEmail.question',
        defaultMessage: 'Can I receive e-mail or text payment confirmations?'
    },
    faqPaymentEmailAnswer: {
        id: 'faq.topics.policy_billing_claim_status.paymentEmail.answer.long',
        defaultMessage: 'You can sign up for email notifications or text notifications in your “Account preferences” so that you will receive payment confirmations.'
    },
    faqCanceledPolicyQuestion: {
        id: 'faq.topics.policy_billing_claim_status.canceledPolicy.question',
        defaultMessage: 'How can I view the status of my policy in my online account?'
    },
    faqCanceledPolicyAnswer: {
        id: 'faq.topics.policy_billing_claim_status.canceledPolicy.answer.long',
        defaultMessage: 'You can locate your policy status by viewing your policy details on the “My policies” section. Or you can review the billing information by clicking “Billing and payment” on the top navigation bar.'
    },
    faqClaimStatusQuestion: {
        id: 'faq.topics.policy_billing_claim_status.claimStatus.question',
        defaultMessage: 'Can I view information about the status of my filed claims in my online account?'
    },
    faqClaimStatusAnswer: {
        id: 'faq.topics.policy_billing_claim_status.claimStatus.answer.long',
        defaultMessage: 'For a quick status, you can locate the claims status on the "My policies" section. The status of any filed claim is shown under "Claims" heading on the right-hand side. You can also view your claim status and obtain further information such as issued payments, adjuster information, and claim details on the "My claims" section.'
    },
    faqClaimStatusLink: {
        id: 'faq.topics.policy_billing_claim_status.claimStatus.link.My claims',
        defaultMessage: 'My claims'
    },
    faqForgotContactInfoQuestion: {
        id: 'faq.topics.policy_billing_claim_status.forgotContactInfo.question',
        defaultMessage: 'How can I find my agent\'s contact information in my online account?'
    },
    faqForgotContactInfoAnswer: {
        id: 'faq.topics.policy_billing_claim_status.forgotContactInfo.answer.long',
        defaultMessage: 'You can locate your agent\'s contact information below the "Your agent information” heading, which appears underneath the "My policies" section.'
    },
    faqAgencyBillQuestion: {
        id: 'faq.topics.policy_billing_claim_status.agencyBill.question',
        defaultMessage: 'My agent bills and collects my payments. Why am I not able to service my policy?'
    },
    faqAgencyBillAnswer: {
        id: 'faq.topics.policy_billing_claim_status.agencyBill.answer.long',
        defaultMessage: 'Your agent will be able to assist you with all your servicing needs such as billing, making a coverage change, etc. You can locate your agent\'s contact information below the "Your agent information” heading, which appears underneath the "My policies" section. '
    },
    viewPolicy: {
        id: 'faq.topics.view_policy.question',
        defaultMessage: 'Where can I view my policy documents?',
    },
    viewPolicyAnswer: {
        id: 'faq.topics.view_policy.answer.long',
        defaultMessage: 'You can view your policy documents by clicking “My policies” then select the policy you want to review documents for, click “View policy,” and navigate to the “Documents” section.'
    },
    reviewDocuments: {
        id: 'faq.topics.review_documents.question',
        defaultMessage: 'How often should I review my documents?',
    },
    reviewDocumentsAnswer: {
        id: 'faq.topics.review_documents.answer.long',
        defaultMessage: 'You should review your online documents regularly, and, if you have enrolled in notifications, you should review your documents whenever you receive a notification from us that a new document is available. For example, your policy packet includes many items such as the cover letter, billing statement, declarations page, endorsements, and ID cards. While it is a good idea to review these for accuracy, it is even more critical to see if there are other forms included that you are required to complete and return. Failure to do so could result in a loss of coverage or loss of a discount. So, please make sure to get in the habit of reviewing every document carefully.'
    },
    idCards: {
        id: 'faq.topics.id_cards.question',
        defaultMessage: 'How do I download or print my ID cards?',
    },
    idCardsAnswer: {
        id: 'faq.topics.id_cards.answer.long',
        defaultMessage: 'You can locate your ID cards for your recreational policy by clicking “My policies,” then select the policy you need an ID card for and click “View policy.” You will then be able to download and print a copy of your ID cards.'
    },
    billingCoupons: {
        id: 'faq.topics.billing_coupons.question',
        defaultMessage: 'How do I download or print my billing coupons?',
    },
    billingCouponsAnswer: {
        id: 'faq.topics.billing_coupons.answer.long',
        defaultMessage: 'You can view and print your billing coupons by clicking “View policy,” then navigating to the “Documents” section. Then, locate the bill you want to download and print.'
    },
    viewDocuments: {
        id: 'faq.topics.view_documents.question',
        defaultMessage: 'How will I know when new documents are available for viewing?',
    },
    viewDocumentsAnswer: {
        id: 'faq.topics.view_documents.answer.long',
        defaultMessage: 'If you have signed up for email notifications, we will send you an email notification whenever new documents are posted to your account. Your most current documents will be available in your online account as soon as you register. After that, other documentation will be available as it is processed and posted to your account, and you will have access to them faster than if they were being mailed.'
    },
    documentsAndCorrespondence: {
        id: 'faq.topics.documents_and_correspondence.question',
        defaultMessage: 'Will all policy documents be available for viewing in my online account?',
    },
    documentsAndCorrespondenceAnswer: {
        id: 'faq.topics.documents_and_correspondence.answer.long',
        defaultMessage: 'All policy documents will be posted to your online account. You may also still receive some correspondence by mail from either American Modern or your agent. Mailings from your agent, including where your agent bills and collects your payments, will not be posted to your online account. So, because we are not sending the bills out, we cannot post them to your online account.\n\n If you need a document that is no longer available online, you can contact Customer Service at (855) 469-2644 to request a copy.'
    },
    deliveryAndNotificationPreferences: {
        id: 'faq.topics.delivery_and_notification_preferences.question',
        defaultMessage: 'How do I change my notification or delivery preferences?',
    },
    deliveryAndNotificationPreferencesAnswer: {
        id: 'faq.topics.delivery_and_notification_preferences.answer.long',
        defaultMessage: 'You can change your notification or delivery preferences under the “Account preferences” section.'
    },
    addPolicy: {
        id: 'faq.topics.add_policy.question',
        defaultMessage: 'How do I add a new policy to my online account?',
    },
    addPolicyAnswer: {
        id: 'faq.topics.add_policy.answer.long',
        defaultMessage: 'You can add a policy by clicking the link below, or sending an email to onlineservices@amig.com with your name, policy number, and policy effective date. The add policy link is also available on your My policies dashboard and on your Billing and payment page.'
    },
    troubleshootingAccountStatusQuestion: {
        id: 'faq.topics.troubleshooting.question',
        defaultMessage: 'What if I need help with my online account?',
    },
    troubleshootingAccountStatusAnswer: {
        id: 'faq.topics.troubleshooting.answer.long',
        defaultMessage: 'If you have any problems or questions, you can send an email to  onlineservices@amig.com for further assistance. Or if you prefer, you can call our dedicated help line at (855) 469-2644 between 8 a.m. and 8 p.m. EST for assistance.',
    },
    commonBrowserCompatibilityQuestion: {
        id: 'faq.topics.browserCompatibilityCommon.question',
        defaultMessage: 'Which browsers are currently compatible with my online account?',
    },
    commonBrowserCompatibilityAnswer: {
        id: 'faq.topics.browserCompatibilityCommon.answer.long',
        defaultMessage: '<p>Your American Modern online account is currently compatible with the following major browsers: \n <ul><li>Microsoft Edge®</li><li>Apple Safari® </li><li>Mozilla Firefox®</li><li>Google Chrome®</li></ul>To have the best online experience, we recommend staying updated with the most recent version of your browser.</p>',
    },
    faqTncLink: {
        id: 'faq.topics.termsAndConditionsLink.Terms and conditions',
        defaultMessage: 'Terms and conditions'
    },
    generalIncorrectInformationQuestion: {
        id: 'faq.topics.general.information.question',
        defaultMessage: 'What should I do if I see incorrect information in my online account?',
    },
    generalIncorrectInformationAnswer: {
        id: 'faq.topics.general.information.answer.long',
        defaultMessage: 'You can contact your agent to review your policy details or send an email with your name, policy number, and details on your inquiry to onlineservices@amig.com.'
    },
    faqBillDueDateQuestion: {
        id: 'faq.topics.communication_billDueDate.question',
        defaultMessage: 'How will I know when I need to pay my bills?',
    },
    faqInfoChangeQuestion: {
        id: 'faq.topics.communication_infoChange.question',
        defaultMessage: 'How will I know when I receive a document to review?',
    },
    faqBillDuedateAnswer: {
        id: 'faq.topics.communication_billDueDate.answer.long',
        defaultMessage: 'The fastest way to stay up to date on your policy is to allow for email and/or text notifications. You can manage your notifications in the “Account preferences” section. If you have signed up to be notified by email and/or text, we will let you know when a payment is due.',
    },
    faqInfoChangeAnswer: {
        id: 'faq.topics.communication_infoChange.answer.long',
        defaultMessage: 'The fastest way to stay up to date on your policy is to allow for email and/or text notifications. You can manage your notifications in the “Account preferences” section. If you have signed up to be notified by email and/or text, we will let you know when a new policy document has been posted to your online account.',
    }

});
