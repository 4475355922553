import { defineMessages } from 'react-intl';

export default defineMessages({
    locationCoverages: {
        id: 'claim.directives.ho-location-coverages.Location Coverages',
        defaultMessage: 'Location Coverages'
    },
    location: {
        id: 'claim.directives.ho-location-coverages.Location #',
        defaultMessage: 'Location #'
    },
    description: {
        id: 'claim.directives.ho-location-coverages.Description',
        defaultMessage: 'Description'
    },
    address: {
        id: 'claim.directives.ho-location-coverages.Address',
        defaultMessage: 'Address'
    },
    listedItems: {
        id: 'claim.directives.ho-location-coverages.Listed Item(s)?',
        defaultMessage: 'Listed Item(s)?'
    },
    coveragesForLocation: {
        id: 'claim.directives.ho-location-coverages.Coverages for Location #',
        defaultMessage: 'Coverages for Location # {selectedLocationNumber}'
    },
    hideLocationCoverages: {
        id: 'claim.directives.ho-location-coverages.hide location coverages',
        defaultMessage: 'Hide Location Coverages'
    },
    showLocationCoverages: {
        id: 'claim.directives.ho-location-coverages.show location coverages',
        defaultMessage: 'Show Location Coverages'
    }
});
