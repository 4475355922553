import React, {
    useCallback, useContext, useEffect, useState
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { ModalNextProvider } from '@jutro/components';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { ViewModelServiceContext, ViewModelForm } from 'gw-portals-viewmodel-react';
import { ClausesUtil } from 'gw-policycommon-util-js';
import { withAuthenticationContext } from 'gw-digital-auth-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useValidation } from 'gw-portals-validation-react';
import { PolicyChange, messages as paymentMessages } from 'gw-capability-policychange-common-react';
import { messages as commonMessages } from 'gw-platform-translations';
import metadata from './CoveragePage.metadata.json5';
import styles from '../../PAPolicyChange.module.scss';
import messages from './CoveragePage.messages';

const structureCustomQuote = (submissionVM, clauses) => {
    // convert OfferingDTO to CustomQuotedDTO structure
    return {
        coverages: clauses.personalAuto
    };
};
const getCustomQuote = (vm, lobPath, lobName, filterChangedClauses = false) => {
    const lobOffering = _.get(vm, `${lobPath}.value`);
    let clausesToUpdate = {
        [lobName]: lobOffering.coverages
    };
    if (filterChangedClauses) {
        clausesToUpdate = ClausesUtil.structureClausesForServer(
            lobOffering.coverages,
            lobName,
            null
        );
    }
    return structureCustomQuote(vm, clausesToUpdate);
};

function CoveragePage(props) {
    const [loadingClause, updateLoadingClause] = useState();
    const { wizardData, updateWizardData, history } = props;
    const { submissionVM } = wizardData;
    const { authHeader } = props;
    const viewModelService = useContext(ViewModelServiceContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const {
        isComponentValid,
        onValidate,
        initialValidation,
        registerComponentValidation,
        disregardFieldValidation
    } = useValidation('PACoveragePage');

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath) => {
            const lobName = ClausesUtil.getLobNameFromPath(lobPath);
            const customQuote = getCustomQuote(submissionVM, lobPath, lobName, false);
            const oldSubmissionVM = viewModelService.clone(submissionVM);
            const getRemovedClausesID = (newSubmissionVM, path) => ClausesUtil
                .getRemovedClausesID(oldSubmissionVM, newSubmissionVM, path);
            return EndorsementService.updateCoverages(
                submissionVM.value.jobID,
                [],
                { personalAuto: customQuote.coverages },
                authHeader
            ).then((updatedSubmission) => {
                const changedPath = lobPath.replace(/.children/, '');
                submissionVM.value = new PolicyChange(updatedSubmission);
                const updatedClauses = _.get(updatedSubmission, `${changedPath}.coverages`);
                _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                const removedFieldsFromLineCoverages = getRemovedClausesID(
                    submissionVM,
                    `${lobPath}.coverages.lineCoverages`
                );
                const vehicleCoveragePath = `${lobPath}.coverages.vehicleCoverages`;
                const removedFieldsFromVehicleCoverages = _.get(
                    submissionVM,
                    `${vehicleCoveragePath}.value`
                ).flatMap((coverage, index) => getRemovedClausesID(
                    submissionVM,
                    `${vehicleCoveragePath}.children[${index}].coverages`
                ));
                const allRemovedFields = [
                    ...removedFieldsFromLineCoverages,
                    ...removedFieldsFromVehicleCoverages
                ];
                disregardFieldValidation(allRemovedFields);
                updateWizardData(wizardData);
                updateLoadingClause(undefined);
            });
        },
        [
            authHeader,
            disregardFieldValidation,
            submissionVM,
            updateWizardData,
            viewModelService,
            wizardData,
            EndorsementService
        ]
    );

    const onClauseChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.personalAuto.offerings.children[0]';
            writeValue(schedule, path);
            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const changeSubmission = useCallback(
        (value, changedPath) => {
            _.set(submissionVM, changedPath, value);
            ClausesUtil.setClauseValue(submissionVM, value, changedPath);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const getVehicleName = useCallback(() => {
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
        return vehicles.map((vehicle) => {
            return `${vehicle.make} ${vehicle.model} ${vehicle.year}`;
        });
    }, [submissionVM]);

    const getLoadingClause = useCallback((path, baseObject) => {
        const publicID = baseObject.coveragePublicID || baseObject.publicID;
        const parentCoveragePath = ClausesUtil.hasSubCoverages(path)
            ? ClausesUtil.getParentCoveragePath(path)
            : undefined;

        if (parentCoveragePath) {
            const vehicleCoverages = _.get(
                submissionVM,
                'lobData.personalAuto.offerings.children[0].coverages.vehicleCoverages'
            );
            const vehicleCoverage = _.get(
                submissionVM,
                parentCoveragePath
            );
            return {
                index: vehicleCoverages.value.findIndex(
                    (section) => section === vehicleCoverage.value
                ),
                publicID: publicID
            };
        }

        return publicID;
    }, [submissionVM]);

    const syncClauses = useCallback(
        async (value, changedPath) => {
            const basePath = ClausesUtil.getObjectPathFromChangedPath(changedPath);
            const baseObject = _.get(submissionVM, basePath);
            updateLoadingClause(getLoadingClause(basePath, baseObject));
            await onClauseChange();
            updateWizardData(wizardData);
        },
        [onClauseChange, submissionVM, updateWizardData, wizardData, getLoadingClause]
    );

    const changeSubmissionAndSync = useCallback(
        (value, changedPath) => {
            changeSubmission(value, changedPath);
            syncClauses(value, changedPath);
        },
        [changeSubmission, syncClauses]
    );

    const onNext = useCallback(async () => {
        try {
            const updateCoverages = await EndorsementService.updateCoverages(
                submissionVM.value.jobID,
                [],
                {
                    personalAuto: submissionVM.value.lobData.personalAuto.offerings[0].coverages
                },
                authHeader
            );
            submissionVM.value = new PolicyChange(updateCoverages);
        } catch {
            ModalNextProvider.showAlert({
                title: messages.saveCoverageError,
                message: messages.saveCoverageErrorMessage,
                status: 'warning',
                icon: 'mi-error-outline',
                confirmButtonText: commonMessages.cancelModel,
            }).then(() => {
                const policyNumber = _.get(submissionVM, 'policyNumber.value');
                history.push(`/contactAgent/${policyNumber}`);
            }, _.noop);
        }
        const quoteEndorsement = await EndorsementService.quoteEndorsement(
            [submissionVM.jobID.value],
            authHeader
        );
        submissionVM.value = new PolicyChange(quoteEndorsement);
        return wizardData;
    }, [authHeader, submissionVM, wizardData, history, EndorsementService]);

    const generateVehicleOverrides = useCallback(() => {
        const vehicles = _.get(submissionVM, 'lobData.personalAuto.coverables.vehicles.value');
        const overrides = {};
        if (!_.isEmpty(vehicles)) {
            vehicles.forEach((vehicle, i) => {
                const loadingClausePublicID = loadingClause && loadingClause.index === i
                    ? loadingClause.publicID
                    : loadingClause;

                overrides[`vehicleCoverage${i}`] = {
                    loadingClause: loadingClausePublicID
                };
            });
        }
        return overrides;
    }, [loadingClause, submissionVM]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        paDriverSectionId: {
            value: getVehicleName()
        },
        lineCoverage: {
            loadingClause: loadingClause
        },
        ...generateVehicleOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onChangeClause: changeSubmission,
            onSyncCoverages: syncClauses,
            onChangeSubmissionAndSync: changeSubmissionAndSync,
            onValidate: onValidate
        }
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [overrideProps, submissionVM]
    );

    const isPageLoading = useCallback(() => _.isUndefined(loadingClause), [loadingClause]);

    useEffect(() => {
        registerComponentValidation(isPageLoading);
    }, [isPageLoading, registerComponentValidation]);

    return (
        <WizardPage
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            onNext={onNext}
            cancelLabel={(appConfig.persona === 'policyholder' ? paymentMessages.cancelAllChanges : paymentMessages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                onValidationChange={onValidate}
                resolveValue={readValue}
                callbackMap={resolvers.resolveCallbackMap}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}
CoveragePage.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.shape({})
    }).isRequired,
    ...wizardProps
};
export default withRouter(withAuthenticationContext(CoveragePage));
