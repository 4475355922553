import React from 'react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';

import metadata from './ResetPasswordPage.metadata.json5';

const { applicationDetails } = appConfig;

function ResetPasswordPage() {
    const renderedContentFromMetadata = renderContentFromMetadata(metadata.pageContent);

    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

export default ResetPasswordPage;
