import { defineMessages } from 'react-intl';

export default defineMessages({
    sectionTitle: {
        id: 'policy.ho.component.property.Property Details',
        defaultMessage: 'Property Details'
    },
    propertyOccupancy: {
        id: 'policy.ho.component.property.Occupancy',
        defaultMessage: 'Occupancy'
    },
    propertySettlementOption: {
        id: 'policy.ho.component.property.Settlement Option',
        defaultMessage: 'Settlement option'
    },
    propertyDeductible: {
        id: 'policy.ho.component.property.Deductible',
        defaultMessage: 'Deductible'
    },
    propertyAddress: {
        id: 'policy.ho.component.property.Address',
        defaultMessage: 'Address'
    },
    propertyLimit: {
        id: 'policy.ho.component.property.Limit',
        defaultMessage: 'Limit'
    }
});
