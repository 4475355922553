/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react';
import { useAuthentication } from 'gw-digital-auth-react';
import { MetadataForm } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import {
    ButtonLink, BurgerMenu, DropdownMenuLink, DropdownMenuSeparator
} from '@jutro/router';
import { Avatar, Image } from '@jutro/components';
import { CustomIconUtil } from 'gw-portals-util-js';
import metadata from './CustomMobileHeaderComponent.metadata.json5';
import styles from './CustomMobileHeaderComponent.module.scss';
import messages from '../CustomHeaderComponent.messages';

function CustomMobileHeaderComponent(props) {
    const auth = useAuthentication();
    const translator = useContext(TranslatorContext);
    const validTabs = {
        myPolicies: 'My policies', billingAndPayment: 'Billing and payment', faq: 'FAQ', accountPref: 'Account preferences', myClaims: 'My claims'
    };
    const pageRedirects = {
        myPolicies: '/home', billingAndPayment: '/billing-summary', faq: '/faq', accountPref: '/userprofile', myClaims: '/claims'
    };
    const noStyle = '';

    const toggleSidebar = useCallback(() => {
        props.toggleUnsavedChangesBanner().call();
        const sidebarHeader = document.getElementById('burger-menu-side-bar-header');
        if (sidebarHeader !== undefined) {
            const closeNavigation = sidebarHeader.querySelector('[aria-label="Close navigation"]');
            closeNavigation.click();
        }
    }, [props.cardBeingEdited]);

    const getBurgerMenuLinks = useCallback(() => {
        const burgerMenuSidebar = [
            <Image className={styles.amigSidebarHeader} src={CustomIconUtil.getLogoSrc('AMIG-desktopHeaderLogo.png')}></Image>,
            <DropdownMenuLink className={props.currentTab.includes(validTabs.myPolicies) ? styles.activeMobileHeader : noStyle} onClick={() => toggleSidebar()} to={props.cardBeingEdited ? pageRedirects.accountPref : pageRedirects.myPolicies} >{translator(messages.myPolicies)}</DropdownMenuLink>,
            <DropdownMenuLink className={props.currentTab.includes(validTabs.myClaims) ? styles.activeMobileHeader : noStyle} onClick={() => toggleSidebar()} to={props.cardBeingEdited ? pageRedirects.accountPref : pageRedirects.myClaims} >{translator(messages.claims)}</DropdownMenuLink>,
            <DropdownMenuLink className={props.currentTab.includes(validTabs.billingAndPayment) ? styles.activeMobileHeader : noStyle} onClick={() => toggleSidebar()} to={props.cardBeingEdited ? pageRedirects.accountPref : pageRedirects.billingAndPayment}>{translator(messages.billingAndPayment)}</DropdownMenuLink>,
            <DropdownMenuLink className={props.currentTab.includes(validTabs.faq) ? styles.activeMobileHeader : noStyle} onClick={() => toggleSidebar()} to={props.cardBeingEdited ? pageRedirects.accountPref : pageRedirects.faq}>{translator(messages.getHelp)}</DropdownMenuLink>
        ];
        burgerMenuSidebar.push([
            <div className={styles.sidebarSubSection}>
                <DropdownMenuSeparator className={styles.amigSeparator}/>
                <div  className = {styles.sidebarAvatarSection}>
                    <Avatar
                        id="Avatar"
                        className = {styles.sidebarAvatar}
                        imageSource={CustomIconUtil.getLogoSrc('avatar-icon.png')}
                    />
                    <span className={styles.name}>{auth.given_name+' '+auth.family_name}</span>
                </div>
                <DropdownMenuLink className={props.currentTab.includes(validTabs.accountPref) ? styles.activeMobileHeader : noStyle} onClick={() => toggleSidebar()} to={pageRedirects.accountPref}>{translator(messages.accountPreferences)}</DropdownMenuLink>
                <ButtonLink className={styles.mobileLogout} to="/" onClick={auth.logout}>{translator(messages.logout)}</ButtonLink>
            </div>
        ]);

        return burgerMenuSidebar;
    }, [translator, auth, props.currentTab, props.cardBeingEdited]);

    const overrideProps = {
        burgermenu: {
            burgerContent: getBurgerMenuLinks()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            burgermenu: BurgerMenu
        },
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomMobileHeaderComponent.defaultProps = {
};

CustomMobileHeaderComponent.propTypes = {

};
export default CustomMobileHeaderComponent;
