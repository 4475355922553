import { defineMessages } from 'react-intl';

export default defineMessages({
    Copyright: {
        id: 'amig.footer.copyright',
        defaultMessage: '® {year} American Modern Insurance Group. All rights reserved.'
    },
    MobileCopyright: {
        id: 'amig.footer.MobileCopyright',
        defaultMessage: '® {year} American Modern Insurance Group. \nAll rights reserved.'
    },
    TakeAction: {
        id: 'amig.footer.Take Action',
        defaultMessage: 'Take action'
    },
    legalDisclosureText1: {
        id: 'policy.view.policy-details.statement1',
        defaultMessage: `Coverage is subject to policy terms, conditions, limitations, exclusions, underwriting review and approval,
         and may vary or not be available for all risks or in all states. Rates and discounts vary, are determined
         by many factors, and are subject to change. Policies are written by one of the licensed insurers of American
         Modern Insurance Group, Inc., including but not limited to American Modern Property and Casualty Insurance Company (CA Lic. No. 6129-1).`,
    },
    legalDisclosureText2: {
        id: 'policy.view.policy-details.statement2',
        defaultMessage: `American Modern Insurance Group, American Modern, AMsuite, modernLINK, and Homeowners FLEX are registered
         trademarks of American Modern Insurance Group, Inc. All other names, logos, and brands described herein 
         are trademarks or registered trademarks of their respective holders, and use herein does not imply affiliation
         with or sponsorship or endorsement by them of American Modern, its products, or its services.`,
    },
    contactUs: {
        id: "amig.footer.Contact us",
        defaultMessage: "Contact us"
    },
    makeAPayment: {
        id: "amig.footer.Billing and payment",
        defaultMessage: "Billing and payment"
    }, 
    fileAClaim: {
        id: "amig.footer.File a claim",
        defaultMessage: "File a claim"
    },
    giveUsACall: {
        id: "amig.footer.give us a call",
        defaultMessage: "Give us a call"
    }
});

