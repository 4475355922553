import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    updatePhoneHeader: {
        id: 'account.account pref.notifications.Update Phone Number',
        defaultMessage: 'We have successfully updated your phone number.'
    },
    updateUserHeader: {
        id: 'account.account pref.notifications.Update User Header',
        defaultMessage: 'We have successfully updated your User ID.'
    },
    updatePasswordHeader: {
        id: 'account.account pref.notifications.Update Password Header',
        defaultMessage: 'We have successfully updated your password.'
    },
    unsavedChangedHeader: {
        id: 'account.account pref.notifications.Unsaved Changes Header',
        defaultMessage: 'You have unsaved changes.'
    },
    unsavedChangesText: {
        id: 'account.account pref.notifications.Unsaved Changes Body',
        defaultMessage: 'Please cancel or save your changes before navigating away from this page.'
    },
    goPaperlessHeader: {
        id: 'account.account pref.notifications.Go Paperless Header',
        defaultMessage: 'Go Paperless'
    },
    goPaperlessBody: {
        id: 'account.account pref.notifications.Go Paperless Body',
        defaultMessage: 'Simplify your life and help save the planet - go paperless. Don\'t worry, you can always change it back.'
    }
});
