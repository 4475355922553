const LOB_KEYS = {
    MORTGAGEE: {
        Homeowners: 'MORTGAGEE',
        HOPHomeowners: 'ADDITIONALMORTGAGEE'
    }
};

export default function getLobSpecificKeyValue(key, productCode) {
    if (LOB_KEYS[key] && LOB_KEYS[key][productCode]) {
        return LOB_KEYS[key][productCode];
    }
    return key;
}
