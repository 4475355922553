import { defineMessages } from "react-intl";

export default defineMessages({
  statusActive: {
    id: "policy.detail.card.policy-status.ActiveStatus",
    defaultMessage: "Active",
  },
  statusCanceled: {
    id: "policy.detail.card.policy-status.CanceledStatus",
    defaultMessage: "Canceled",
  },
  MyPolicies: {
    id: "policy.detail.card.Policy",
    defaultMessage: "Policy",
  },
  ContactInformation: {
    id: "policy.detail.card.Contact information",
    defaultMessage: "Contact information",
  },
  Premium: {
    id: "policy.detail.card.Premium",
    defaultMessage: "Premium",
  },
  policyEffectiveDate: {
    id: "policy.detail.card.{effectiveDate}",
    defaultMessage: "{effectiveDt}",
  },
  policyExpirationDate: {
    id: "policy.detail.card.{expDate}",
    defaultMessage: "{expDate}",
  },
  mailingAddress: {
    id: "policy.detail.card.{mailingAdd}",
    defaultMessage: "{mailingAdd}",
  },
  totalPremium: {
    id: "policy.detail.card.{totalPremAmount}",
    defaultMessage: "{totalPremAmount}",
  },
  email: {
    id: "policy.detail.card.{emailAdd}",
    defaultMessage: "{emailAdd}",
  },
  phoneNumber: {
    id: "policy.detail.card.{phoneNo}",
    defaultMessage: "{phoneNo}",
  },
  taxAndFees: {
    id: "policy.detail.card.{taxes}",
    defaultMessage: "{taxes}",
  },
  totalCosts: {
    id: "policy.detail.card.{cost}",
    defaultMessage: "{cost}",
  },
  contactAgent: {
    id: "policy.detail.card.contactAgentText",
    defaultMessage: "To update your contact information, please contact your agent at "
  }
});
