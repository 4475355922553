import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import CustomVehicleCoveragesComponent from '../PA/CustomVehicleCoveragesComponent/CustomVehicleCoveragesComponent';
import PolicyCommonCoverables from '../PolicyCommonCoverables/PolicyCommonCoverables';
import metadata from './PolicyCoverages.metadata.json5';
import CustomPolicyPropertyDetails from '../HO/CustomPolicyPropertyDetails/CustomPolicyPropertyDetails';

function PolicyCoverages(props) {
    const { value: policy } = props;

    if (_.isEmpty(policy)) {
        return null;
    }

    const overrides = {
        customRecreationalDetailsComponent: {
            visible: !_.isEmpty(policy.lobs.personalAuto),
            policyType: policy.policyType_Ext,
            policyNumber: policy.policyNumber,
            policyStatus: policy.status_Ext
        },
        genericCoveragesComponent: {
            visible:
                _.isEmpty(policy.lobs.homeOwners)
                && _.isEmpty(policy.lobs.personalAuto)
                && !_.isEmpty(policy.coverables)
        },
        policyHomeOwnersInfoComponent: { 
            policyType: policy.policyType_Ext ,
            policyNumber: policy.policyNumber,
            policyStatus: policy.status_Ext,
            visible: (policy.lobs.homeOwners !==undefined)?!_.isEmpty(policy.lobs.homeOwners):false
        }
    };

    const resolvers = {
        resolveComponentMap: {
            customRecreationalDetailscomponent: CustomVehicleCoveragesComponent,
            policypropertydetailscomponent: CustomPolicyPropertyDetails,
            genericcoveragecomponent: PolicyCommonCoverables
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={policy}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

PolicyCoverages.propTypes = {
    value: PropTypes.shape({})
};

PolicyCoverages.defaultProps = {
    value: {
        lobs: {
            homeOwners: {},
            personalAuto: {},
            coverables: []
        }
    }
};

export default PolicyCoverages;
