import React, { useCallback } from 'react';
import moment from 'moment';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { useValidation } from 'gw-portals-validation-react';
import { PolicyChange, messages } from 'gw-capability-policychange-common-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './AddressPage.metadata.json5';
import styles from '../../PAPolicyChange.module.scss';
import './AddressPage.messages';

const { capabilitiesConfig } = appConfig;

function AddressPage(props) {
    const { wizardData, updateWizardData, authHeader } = props;
    const { submissionVM } = wizardData;
    const { AvailabilityService } = useDependencies('AvailabilityService');
    const { EndorsementService } = useDependencies('EndorsementService');
    const { onValidate, initialValidation, isComponentValid } = useValidation(
        'PAPolicyChangeAddressPage'
    );

    const resolvers = {
        resolveClassNameMap: styles
    };

    const onNext = useCallback(async () => {
        let response = { isAvailable: true };

        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'policycommon' })) {
            const availabilityDTO = {
                address: submissionVM.baseData.policyAddress.value,
                effectiveDate: moment(submissionVM.baseData.effectiveDate.value).format(),
                productCode: submissionVM.baseData.productCode.value
            };
            response = await AvailabilityService.checkAvailabilityBasedOnAddress(
                availabilityDTO,
                authHeader
            );
        }
        if (response.isAvailable) {
            submissionVM.value.beforeSave();
            const policyChangeResponse = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
            submissionVM.value = new PolicyChange(policyChangeResponse);
        }
        return wizardData;
    }, [EndorsementService, AvailabilityService, submissionVM, authHeader, wizardData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left',
            showOptional: true
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                onValidationChange={onValidate}
            />
        </WizardPage>
    );
}

AddressPage.propTypes = wizardProps;
export default withAuthenticationContext(AddressPage);
