import {defineMessages} from 'react-intl';

export default defineMessages({
    claimStatus: {
        id: 'claim-card.details.Status',
        defaultMessage: 'Status: '
    },
    multipleVehicles: {
        id: 'claim-card.details.Multiple Vehicles',
        defaultMessage: 'Multiple vehicles involved'
    }
});