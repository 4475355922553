import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    mobileViewDetails: {
        id: 'custompaymenthistory.View details',
        defaultMessage: 'View details'
    }    
});
