/* eslint-disable max-len */
import React, {
    useCallback, useContext, useState, useEffect
} from 'react';
import { LoadingContext } from 'gw-capability-policy-react';
import { TranslatorContext } from '@jutro/locale';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { CustomIconUtil, CustomPolicyTypeUtil, CustomFormatterUtil, CustomTooltipUtil } from 'gw-portals-util-js';
import { CustomClaimCardComponent } from 'gw-capability-claim-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { ClaimService } from 'gw-capability-claim';
import appConfig from 'app-config';
import { isCapabilityEnabled } from 'gw-portals-config-js';
import { BreakpointTrackerContext } from '@jutro/layout';
import messages from './CustomPolicyCardComponent.messages';
import styles from './CustomPolicyCardComponent.module.scss';
import metadata from './CustomPolicyCardComponent.metadata.json5';

function CustomPolicyCardComponent(props) {
    const translator = useContext(TranslatorContext);
    const loader = useContext(LoadingContext);
    const { data: policyData } = props;
    const { authHeader } = useAuthentication();
    const [policyClaimData, setpolicyClaimData] = useState([]);
    const latestPolicyPeriod = _.first(policyData.periods);
    const { capabilitiesConfig } = appConfig;
    const emptyString = '';
    const [apiErrorExists, setAPIErrorExists] = useState(false);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet';
    useEffect(() => {
        loader.setLoading(true);
        let claimPromise = Promise.resolve([]);
        if (isCapabilityEnabled({ capabilitiesConfig, capabilityName: 'claim' })) {
            claimPromise = ClaimService.getClaimSummaries({ policyNumber: latestPolicyPeriod.policyId }, {}, authHeader);
        }
        Promise.all([claimPromise.then(setpolicyClaimData).catch((error) => {
            setAPIErrorExists(error.errorCode === 'GW_ERROR');
        })]).finally(() => {
            loader.setLoading(false);
        });
    }, []);

    const getPolicyTypeIcon = (policyType) => {
        const formattedPolicyType = policyType.replace(/ +/g, emptyString);
        const icon = CustomIconUtil.getCustomPolicyTypeIcon(formattedPolicyType);
        return icon;
    };

    const doesAPIErrorExists = useCallback(() => { return apiErrorExists; }, [apiErrorExists]);

    const readValue = useCallback(
        (id, path) => {
            if (path === 'producerAgency') {
                return _.get(policyData.producerContact, 'producerCodeOfServiceAgency_Ext');
            }
            return _.get(policyData, path);
        },
        [policyData]
    );

    const isPaymentPastDue = () => { return latestPolicyPeriod.isPaymentPastDue_Ext; };

    const isPolicyStatusInForce = () => { return latestPolicyPeriod.status === 'In Force'; };

    const isPolicyStatusScheduled = () => { return latestPolicyPeriod.status === 'Scheduled'; };

    const isPolicyAgencyBill = () => { return latestPolicyPeriod.billingMethod_Ext === 'Agency Bill'; };

    const isPolicyStatusCanceled = () => { return latestPolicyPeriod.status === 'Canceled'; };

    const getActiveClaims = () => {
        if (policyClaimData.items) {
            switch (policyClaimData.activeClaimsCount_Ext) {
                case 0:
                    return (
                        <p className={styles.claimsMessage}>{translator(messages.noActiveClaim)}</p>
                    );
                case 1:
                    return (
                        <p className={styles.claimsMessage}> {translator(messages.youHave)} <b>1</b> {translator(messages.activeClaim)} </p>
                    );
                default:
                    return (
                        <p className={styles.claimsMessage}> {translator(messages.youHave)} <b>{policyClaimData.activeClaimsCount_Ext}</b> {translator(messages.activeClaims)}</p>
                    );
            }
        }
        return ' ';
    };

    const hasClaims = () => { return (policyClaimData.items && policyClaimData.items.length > 0); };

    const hasMultipleClaims = () => { return (policyClaimData.items && policyClaimData.items.length > 1); };

    const isAmountZero = (policyAmount) => {
        if (policyAmount === undefined || policyAmount.amount === 0) {
          return true;
        }
        return false;
    };

    const getPolicyStatus = (policy) => {
        if (isPolicyStatusInForce(policy)) {
            return translator(messages.statusActive);
        }
        return policy.status;
    };

    const overrideProps = {

        makeAPaymentButton: {
           to: `/choose-payment/${latestPolicyPeriod.policyId}?policyType=${latestPolicyPeriod.policyType}&isHOFlex=${CustomPolicyTypeUtil.isHOFlex(latestPolicyPeriod)}`
        },
        viewPolicyButton: {
            to: `/account-policy-details/${latestPolicyPeriod.policyId}`,
            className: 'policy-card-view-policy-button'
        },
        policyMobileChevronIcon: {
            to: `/account-policy-details/${latestPolicyPeriod.policyId}`,
        },
        policyIcon: {
            src: getPolicyTypeIcon(latestPolicyPeriod.policyType),
            title: latestPolicyPeriod.lines,
            key: latestPolicyPeriod.lines,
        },
        policyType: {
            content: CustomPolicyTypeUtil.getCustomPolicyTitle(latestPolicyPeriod),
        },
        policyNumber: {
            content: translator(messages.policyIdValue, {
                id: latestPolicyPeriod.policyId,
            }),
        },
        policyInForceDateRange: {
            value: CustomFormatterUtil.formatDateRange(latestPolicyPeriod.effective,
                latestPolicyPeriod.expiration),
        },
        policyStatus: {
            value: getPolicyStatus(latestPolicyPeriod)
        },
        lastPaymentReceived: {
            visible: !isPolicyAgencyBill(),
            value: isAmountZero(latestPolicyPeriod.lastPaymentReceivedAmount_Ext)
                ? '-'
                : translator(messages.lastPaymentReceivedValue, {
                    lastPaymentReceivedAmt: CustomFormatterUtil.formatAmount(latestPolicyPeriod.lastPaymentReceivedAmount_Ext.amount),
                    lastPaymentReceivedAmtDate: latestPolicyPeriod.lastPaymentReceivedDate_Ext !== null ? CustomFormatterUtil.formatAccompanyingDate(latestPolicyPeriod.lastPaymentReceivedDate_Ext) : emptyString,
                }),
        },
        remainingBalance: {
            visible: !isPolicyAgencyBill() && (isPolicyStatusInForce() || isPolicyStatusScheduled()),
            value: CustomFormatterUtil.formatAmount(latestPolicyPeriod.remainingPremium_Ext.amount)
        },
        currentPaymentDueContainer: {
            visible: latestPolicyPeriod.canMakeAPayment_Ext,
        },
        currentPaymentDue: {
            value: isAmountZero(latestPolicyPeriod.currentPaymentDue_Ext)
                ? '-'
                : translator(messages.nextPaymentDueValue, {
                    nextPaymentAmt: CustomFormatterUtil.formatAmount(latestPolicyPeriod.currentPaymentDue_Ext.amount),
                    nextPaymentAmtDate: latestPolicyPeriod.nextBillDueDate_Ext !== null ? CustomFormatterUtil.formatAccompanyingDate(latestPolicyPeriod.currentBillDueDate_Ext) : emptyString
                }),
            className: 'policy-card-input-data',
            visible: (!(isPolicyStatusCanceled()) && !isPolicyAgencyBill())
        },
        paymentPastDue: {
            value: ((isPaymentPastDue() && isPolicyStatusCanceled()) && !isPolicyAgencyBill()) ? CustomFormatterUtil.formatAmount(latestPolicyPeriod.pastDueAmount_Ext.amount) : emptyString,
            className: 'policy-card-payment-overdue',
            visible: ((isPolicyStatusCanceled()) && !isPolicyAgencyBill())
        },
        paymentPastDueNotification: {
            visible: isMobile && (isPaymentPastDue() && isPolicyStatusInForce()) && !isPolicyAgencyBill(),
            message: ((isPaymentPastDue() && isPolicyStatusInForce()) && !isPolicyAgencyBill()) ? <p className={styles.pastDueAmt}><b>{CustomFormatterUtil.formatAmount(latestPolicyPeriod.pastDueAmount_Ext.amount)}</b>. {translator(messages.includingFees)} </p> : emptyString
        },
        totalPremium: {
            visible: (isPolicyStatusInForce() || isPolicyStatusScheduled()) && !isPolicyAgencyBill(),
            value: CustomFormatterUtil.formatAmount(latestPolicyPeriod.premium.amount),
        },
        totalPremiumAgency: {
            visible: isPolicyAgencyBill(),
            value: CustomFormatterUtil.formatAmount(latestPolicyPeriod.premium.amount),
        },
        policyBillingMethod: {
            visible: isPolicyAgencyBill(),
            value: latestPolicyPeriod.billingMethod_Ext,
        },
        customerServiceContainer: {
            visible: !(isPolicyStatusInForce() || isPolicyStatusScheduled()) && !isPolicyAgencyBill(),
        },
        customerServiceDescription: {
            content: translator(messages.customerService),
        },
        customerServiceDescriptionPhoneNumber: {
            content: translator(messages.customerServicePhoneNumber),
        },
        customClaimHeader: {
            content: translator(messages.claimsHeader),
            className: hasMultipleClaims() ? 'claim-header claim-minified-header' : 'claim-header',
        },
        activeclaims: {
            content: getActiveClaims(),
            visible: hasClaims() && !doesAPIErrorExists()
        },
        fileClaimButton: {
            icon: 'mi-keyboard-backspace',
            className: hasClaims() ? 'file-claim-link file-claim-minified-link ' : 'file-claim-link',
            href: appConfig.env.FNOL_URL,
            target: '_blank',
            visible: !doesAPIErrorExists()
        },
        noClaim: {
            content: translator(messages.noClaimHistory),
            visible: !doesAPIErrorExists()
        },
        claimAPIError: {
            content: translator(messages.claimAPIErrorHeader),
            visible: doesAPIErrorExists()
        },
        claimAPIErrorMessage: {
            content: translator(messages.claimAPIErrorDescription),
            visible: doesAPIErrorExists()
        },
        lastestclaims: {
            content: translator(messages.latestClaimUpdates),
            visible: !doesAPIErrorExists() && hasClaims()
        },
        trackClaim: {
            content: translator(messages.trackClaims),
            visible: !doesAPIErrorExists()
        },
        claimCardComponentPanel: {
            data: policyClaimData.items ? policyClaimData.items.slice(0, 2) : policyClaimData.items,
        },
        cancellationDate: {
            value: isPolicyStatusCanceled() ? CustomFormatterUtil.formatDate(latestPolicyPeriod.cancellationEffectiveDate_Ext) : emptyString,
            visible: isPolicyStatusCanceled() && latestPolicyPeriod.canMakeAPayment_Ext && !isPolicyAgencyBill()
        },
        permanentCancellationDate: {
            value: isPolicyStatusCanceled() ? CustomFormatterUtil.formatDate(latestPolicyPeriod.cancellationEffectiveDate_Ext) : emptyString,
            visible: isPolicyStatusCanceled() && !latestPolicyPeriod.canMakeAPayment_Ext && !isPolicyAgencyBill()
        },
        permanentCancellationReason: {
            value: isPolicyStatusCanceled() ? latestPolicyPeriod.cancellationReason_Ext : emptyString,
            visible: isPolicyStatusCanceled() && !latestPolicyPeriod.canMakeAPayment_Ext && !isPolicyAgencyBill()
        },
        cancellationReason: {
            value: isPolicyStatusCanceled() ? latestPolicyPeriod.cancellationReason_Ext : emptyString,
            visible: isPolicyStatusCanceled() && latestPolicyPeriod.canMakeAPayment_Ext && !isPolicyAgencyBill()
        },
        chatboticon: {
            src: CustomIconUtil.getLogoSrc('chatbot-green-icon.png'),
            visible: !doesAPIErrorExists()
        },
        copyContentContainer: {
            visible: !isMobile,
            content: (latestPolicyPeriod.policyId) ? CustomTooltipUtil.getTooltip(latestPolicyPeriod.policyId) : emptyString
        },
        desktoppaymentPastDueNotification: {
            visible: !isMobile && (isPaymentPastDue() && isPolicyStatusInForce()) && !isPolicyAgencyBill(),
            message: ((isPaymentPastDue() && isPolicyStatusInForce()) && !isPolicyAgencyBill()) ? <p className={styles.pastDueAmt}><b>{CustomFormatterUtil.formatAmount(latestPolicyPeriod.pastDueAmount_Ext.amount)}</b>. {translator(messages.includingFees)}</p> : emptyString
        },
        desktopagencyBillNotification: {
            visible: !isMobile && isPolicyAgencyBill(),
        },
        scheduledPoliciesNotification: {
            visible: !isPolicyAgencyBill() && policyData.periods.length > 1
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveValue: readValue,
        resolveComponentMap: { CustomClaimCardComponent },
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomPolicyCardComponent.propTypes = {
    data: PropTypes.shape({}).isRequired,
};

export default CustomPolicyCardComponent;
