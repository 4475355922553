import React, { useCallback } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { wizardProps } from 'gw-portals-wizard-react';
import { withRouter } from 'react-router-dom';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './ConfirmationPage.metadata.json5';
import messages from './ConfirmationPage.messages';
import styles from './ConfirmationPage.module.scss';

function ConfirmationPage(props) {
    const { wizardData } = props;
    const { submissionVM } = wizardData;
    const changesAppliedForward = _.get(wizardData, 'changesAppliedForward');
    const isGateway = appConfig.persona === 'producer' || appConfig.persona === 'csr';
    const changesAppliedForwardText = isGateway
        ? messages.contactUnderwriterMessage
        : messages.contactAgentMessage;
    const overrideProps = {
        pageHeaderTitle: {
            className: styles.confirmationLabel
        },
        policyChangeConfirmationMessage: {
            className: styles.inlineNotificationStyle,
            content: changesAppliedForward && changesAppliedForwardText
        },
        backToPolicyButtonId: {
            content: isGateway ? messages.backToPolicy : messages.backToHome
        }
    };

    const goToPolicy = useCallback(() => {
        const { history } = props;
        const policyNumber = _.get(submissionVM.value, 'policyNumber');
        const redirectPath = isGateway ? `/policies/${policyNumber}/summary` : '/';
        history.push(redirectPath);
    }, [isGateway, props, submissionVM.value]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            goToPolicy: goToPolicy
        }
    };

    return (
        <ViewModelForm
            uiProps={metadata.pageContent}
            model={submissionVM}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
        />
    );
}

ConfirmationPage.propTypes = wizardProps;
export default withRouter(ConfirmationPage);
