import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { JsonRPCService } from 'gw-portals-transport-js';

export default class EnrollmentService {
    /**
    * Enrolls a new policy or account for the current user
    *
    * @param {Object} data enrollment data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */
    static addEnrollmentRecord_Ext(data, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyEnrollment'), 'addEnrollmentRecord_Ext', [data], additionalHeaders);
    }

    /**
    * Syncs the policies with the granted authorities for the current user
    *
    * @param {Object} data enrollment data
    * @param {Object} grantedAuthorities authorities data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */
    static syncPolicies(data, grantedAuthorities, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyEnrollment'), 'syncPolicies', [data, grantedAuthorities], additionalHeaders);
    }

    /**
    * Syncs the policies with the granted authorities for the current user
    *
    * @param {Object} data enrollment data
    * @param {Object} grantedAuthorities authorities data
    * @param {Object} additionalHeaders additional headers to pass to the backend (e.g. auth)
    * @returns {Promise} the promise from the backend call
    */
     static getPoliciesToSync(data, grantedAuthorities, additionalHeaders = {}) {
        return JsonRPCService.send(getProxiedServiceUrl('policyEnrollment'), 'getPoliciesToSync_Ext', [data, grantedAuthorities], additionalHeaders);
    }
}
