import React, { useCallback, useContext, useState } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomIconUtil } from 'gw-portals-util-js';
import metadata from './CustomStickyFlyouts.metadata.json5';
import styles from './CustomStickyFlyouts.module.scss';
import CustomChatbotComponent from '../CustomChatbotComponent/CustomChatbotComponent';
import CustomContactUsComponent from '../CustomContactUsComponent/CustomContactUsComponent';

function CustomStickyFlyouts() {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');

    const [contactUsState, setContactUsState] = useState(false);
    const [chatBotState, setChatBotState] = useState(false);
    const [buttonsOverlap, setButtonsOverlap] = useState(false);

    function getContactUsIcon() {
        return isMobile ? CustomIconUtil.getLogoSrc('sticky-icon-purple.png') : CustomIconUtil.getLogoSrc('sticky-icon.png');
    }

    function getChatBotIcon() {
        return isMobile ? CustomIconUtil.getLogoSrc('chatbot-icon-mobile.png') : CustomIconUtil.getLogoSrc('chatbot-icon.png');
    }

    const toggleContactUs = useCallback(() => {
        setContactUsState(!contactUsState);
    }, [contactUsState]);

    const toggleChatBot = useCallback(() => {
        setChatBotState(!chatBotState);
    }, [chatBotState]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            customcontactuscomponent: CustomContactUsComponent,
            customchatbotcomponent: CustomChatbotComponent
        }
    };

    const overrideProps = {
        chatBotImage: {
            src: CustomIconUtil.getLogoSrc('chatbot-icon.png')
        },
        contactUsImage: {
            src: CustomIconUtil.getLogoSrc('sticky-icon.png')
        },
        chatBotButton: {
            onClick: toggleChatBot,
            className: 'chatBotButton'
        },
        contactUsButton: {
            onClick: toggleContactUs,
            className: 'contactUsButton'
        },
        chatBotContainer: {
            className: chatBotState ? 'chatBotContainer displayStickySection' : 'chatBotContainer'
        },
        contactUsContainer: {
            className: contactUsState ? 'contactUsContainer displayStickySection' : 'contactUsContainer'
        },
        closeChatBot: {
            onClick: toggleChatBot
        },
        closeContactUs: {
            onClick: toggleContactUs
        },
        chatBotHeaderImage: {
            src: getChatBotIcon()
        },
        contactUsHeaderImage: {
            src: getContactUsIcon()
        }
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomStickyFlyouts.defaultProps = {
};

CustomStickyFlyouts.propTypes = {

};
export default CustomStickyFlyouts;
