import React, { useEffect, useContext, useCallback } from 'react';
import _ from 'lodash';
import { MetadataForm, validateMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import appConfig from 'app-config';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomIconUtil } from 'gw-portals-util-js';
import { Link } from '@jutro/components';
import metadata from './CustomFooterComponent.metadata.json5';
import styles from './CustomFooterComponent.module.scss';
import messages from './CustomFooterComponent.messages';
import { useHistory } from 'react-router-dom';


function CustomFooterComponent(props) {
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const history = useHistory();
    const isMobile = breakpoint === 'tablet' || breakpoint === 'phoneWide' || breakpoint === 'phone';
    const isChoosePymtScreen = history.location.pathname.includes('/choose-payment');
    const accordionLinks = [
        {
            sectionName: messages.TakeAction,
            sectionLinks: [
                { linkName: messages.makeAPayment, linkUrl: `/billing-summary`, linkTarget: '_self' },
                { linkName: messages.fileAClaim, linkUrl: appConfig.env.FNOL_URL, linkTarget: '_blank' }
            ]
        },
        {
            sectionName: messages.contactUs,
            sectionLinks: [
                { linkName: messages.giveUsACall, linkUrl: appConfig.env.CONTACT_US_URL, linkTarget: '_blank' }
            ]
        }
    ];

    useEffect(() => {
        validateMetadata(metadata);
    }, []);

    const onClickToTopOfPage = useCallback(() => {
        window.scrollTo(0, 0);
    }, []);

    function openCookieSettings() {
        window.UC_UI.showSecondLayer();
    }

    function getAccordionCardLinks(sectionName, sectionLinks) {
        const accordionCardLinks = [];
        sectionLinks.map((link, index) => {
            const linkId = (sectionName.replace(/ /g,''))+"_Link_" + index;
            accordionCardLinks.push(<Link id={linkId} href={link.linkUrl} target={link.linkTarget} rel="noopener noreferrer" className={styles.mobileAccordionLinks}>{translator(link.linkName)}</Link>);
        });
        return accordionCardLinks;
    }

    const generateFooterAccordionOverrides = useCallback(() => {
        const overrides = accordionLinks.map((section, index) => {
            const accordionCardTitle = translator(section.sectionName);
            return {
                [`accordionSectionLinks${index}`]: {
                    title: accordionCardTitle,
                    content: getAccordionCardLinks(accordionCardTitle, section.sectionLinks)
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, []);

    const resolvers = {
        resolveCallbackMap: {
            onClickToTopOfPage
        }
    };

    const overrideProps = {
        footerCopyright: {
            messageProps: {
                copyrightMessage:  isMobile ? translator(messages.MobileCopyright, { year: new Date().getFullYear() }) :translator(messages.Copyright, { year: new Date().getFullYear() })
            }
        },
        amigimage: {
            src: CustomIconUtil.getLogoSrc('AMIG-4ColorLogo.png')
        },
        fileAClaim: {
            href: appConfig.env.FNOL_URL,
            target: '_blank'
        },
        makeAPayment: {
            to: `/billing-summary`,
            onClick: onClickToTopOfPage
        },
        footerLegalDisclosureBlock1: {
            content: translator(messages.legalDisclosureText1)
        },
        footerLegalDisclosureBlock2: {
            content: translator(messages.legalDisclosureText2)
        },
        facebookImg: {
            href: appConfig.env.AM_FACEBOOK_URL,
            target: '_blank'
        },
        twitterImg: {
            href: appConfig.env.AM_TWITTER_URL,
            target: '_blank'
        },
        instaImg: {
            href: appConfig.env.AM_INSTAGRAM_URL,
            target: '_blank'
        },
        facebookImgMobile: {
            href: appConfig.env.AM_FACEBOOK_URL,
            target: '_blank'
        },
        twitterImgMobile: {
            href: appConfig.env.AM_TWITTER_URL,
            target: '_blank'
        },
        instaImgMobile: {
            href: appConfig.env.AM_INSTAGRAM_URL,
            target: '_blank'
        },
        giveUsACall: {
            href: appConfig.env.CONTACT_US_URL,
            target: '_blank'
        },
        amiglogoFooter: {
            onClick: onClickToTopOfPage
        },
        amiglogoFooterMobile: {
            onClick: onClickToTopOfPage
        },
        footerAccordionSections: {
            data: accordionLinks,
            visible: isMobile && !isChoosePymtScreen
        },
        internetPrivacyNavLink: {
            href: appConfig.env.PRIVACY_URL,
        },
        cookieSettingsNavLink: {
            onClick: openCookieSettings
        },
        californiaNavLink: {
            href: `${appConfig.env.PRIVACY_URL}#california-consumer`,
        },
        termsOfUse: {
            href: appConfig.env.NOTIFICATION_TERMS_OF_USE_URL,
        },
        footerMargin: {
            className: !isChoosePymtScreen ? 'amig-footer-margin' : 'cp-footer-margin' 
        },

        ...generateFooterAccordionOverrides(),
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomFooterComponent.defaultProps = {
};

CustomFooterComponent.propTypes = {
};

export default CustomFooterComponent;

