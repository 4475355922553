import React, { useCallback, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { ModalNextProvider } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { messages as commonMessages } from 'gw-platform-translations';
import appConfig from 'app-config';
import { LoadingContext } from 'gw-capability-policy-react';
import { CustomPolicyTypeUtil } from 'gw-portals-util-js';
import metadata from './BillingSummaryPage.metadata.json5';
import styles from './BillingSummaryPage.module.scss';
import messages from './BillingSummaryPage.messages';
import CustomBillingDetails from '../CustomBillingDetails/CustomBillingDetails';
import CustomPaymentHistory from '../CustomPaymentHistory/CustomPaymentHistory';
import CustomPaymentSchedule from '../CustomPaymentSchedule/CustomPaymentSchedule';
import defaultPolicyObject from '../config/defaultPolicyObject.json';

function BillingSummaryPage(props) {
    const { authHeader, history } = props;
    const { AccountBillingDetailsService: BillingService } = useDependencies(
        'AccountBillingDetailsService'
    );

    const { PolicyService } = useDependencies('PolicyService');

    // screen states
    const loader = useContext(LoadingContext);
    const [selectedInvoiceStreamId, updateSelectedInvoiceStreamId] = useState();

    const [activePolicyTab, updateActivePolicyTab] = useState('Initial');
    const [invertChevron, updateInvertChevron] = useState('false');
    // fetched data
    const [accountBillingSummaryFromApi, setAccountBillingSummaryFromApi] = useState({});
    const [invoiceStreamDetailsMapFromApi, setInvoiceStreamDetailsMapFromApi] = useState(new Map());
    const [invoiceMapsFromApi, setinvoiceMapsFromApi] = useState([]);
    const [policySummariesFromApi, setPolicySummariesFromApi] = useState([]);
    const [resetTabNow, setResetTabNow] = useState('tab1');

    const updateMap = useCallback(
        (invoiceStreamId) => (fetchData) => {
            setInvoiceStreamDetailsMapFromApi(// fetchData
                invoiceStreamDetailsMapFromApi.set(invoiceStreamId, fetchData)
            );
        },
        [invoiceStreamDetailsMapFromApi]
    );

    const fetchAccountLevelInvoiceStreams = useCallback(() => {
        let accountInvoicePromise;
        let policySummaryPromise;

        if (_.isEmpty(accountBillingSummaryFromApi)) {
            accountInvoicePromise = BillingService.getAccountInvoiceStreamInformation(
                authHeader
            ).then((accountInvoiceStreams) => {
                const {
                    accountLevelBilling: accountLevelBillingFromApi = false,
                    invoiceStreams: invoiceStreamsFromApi = []
                } = accountInvoiceStreams;
                setinvoiceMapsFromApi(invoiceStreamsFromApi);

                let defaultInvoiceStreamId;

                if (accountLevelBillingFromApi && invoiceStreamsFromApi.length === 1) {
                    defaultInvoiceStreamId = invoiceStreamsFromApi[0].internalId;
                    updateSelectedInvoiceStreamId(invoiceStreamsFromApi[0].internalId);
                }

                setAccountBillingSummaryFromApi(accountInvoiceStreams);
                return defaultInvoiceStreamId;
            });
        } else {
            accountInvoicePromise = Promise.resolve(accountBillingSummaryFromApi);
        }

        if (policySummariesFromApi.length === 0) {
            policySummaryPromise = PolicyService.getAccountPolicySummaries(authHeader).then(
                setPolicySummariesFromApi
            );
        } else {
            policySummaryPromise = Promise.resolve(policySummariesFromApi);
        }

        return Promise.all([accountInvoicePromise, policySummaryPromise]);
    }, [
        BillingService,
        PolicyService,
        authHeader,
        accountBillingSummaryFromApi,
        policySummariesFromApi
    ]);

    const getPaymentDetailsGroupFromMap = useCallback(
        (invoiceStreamId) => {
            return invoiceStreamDetailsMapFromApi.get(invoiceStreamId);
        },
        [invoiceStreamDetailsMapFromApi]
    );

    let selectedPolicyNumber;
    let selectedPolicyData;
    let policyList;
    const getDropdownValues = useCallback(() => {
        const policyLists = [];

        if (!_.isEmpty(policySummariesFromApi)) {
            policySummariesFromApi.map((policyData) => {
                const policy = {
                    code: policyData.periods[0].policyId,
                    name: (`${policyData.periods[0].policyId} - ${CustomPolicyTypeUtil.isHOFlex(policyData.periods[0]) ? 'Homeowners FLEX ®' : policyData.periods[0].policyType}`),
                    data: policyData.periods[0]
                };
                policyLists.push(policy);
            });
            if (policyLists.length > 0) {
                policyLists.sort((a, b) => b.code - a.code);
                selectedPolicyData = policyLists[0].data;
                selectedPolicyNumber = policyLists[0].code;
            }
        }

        return policyLists;
    }, [policySummariesFromApi]);

    const dropDownSelect = useCallback((newValue) => {
        updateActivePolicyTab(newValue);
        updateInvertChevron('false');
        setResetTabNow('tab1');
    });

    const updateActiveTab = useCallback((newValue) => {
        setResetTabNow(newValue);
    });

    const closeDropdown = useCallback(() => {
        updateInvertChevron('false');
    });

    const openDropdown = useCallback(() => {
        updateInvertChevron('true');
    });

    const getActivePolicy = useCallback(() => {
        if (activePolicyTab === 'Initial' && _.isEmpty(policyList)) {
            if (selectedPolicyData === undefined) {
                return defaultPolicyObject;
            }

            return selectedPolicyData;
        }

        return policySummariesFromApi.find((obj) => {
            return obj.periods[0].policyId === activePolicyTab;
        }).periods[0];
    }, [policySummariesFromApi, activePolicyTab]);

    const getActivePolicyInvoices = useCallback(() => {
        return invoiceStreamDetailsMapFromApi.get(selectedInvoiceStreamId);
    }, [invoiceStreamDetailsMapFromApi, selectedInvoiceStreamId]);

    const fetchInvoiceDetailsByStreamId = useCallback(
        (invoiceStreamId, forceRefresh = false) => {
            const invoiceDetails = getPaymentDetailsGroupFromMap(invoiceStreamId);
            let invoiceDetailsPromise;

            if ((invoiceStreamId && _.isEmpty(invoiceDetails)) || forceRefresh) {
                invoiceDetailsPromise = BillingService.getInvoiceStreamDetails(
                    invoiceStreamId,
                    authHeader
                )
                    .then(updateMap(invoiceStreamId))
                    .catch(() => {
                        ModalNextProvider.showAlert({
                            title: messages.failedToLoadPolices,
                            message: messages.sorryWeCanNotLoadYourPolicyInformationAtThisTime,
                            status: 'error',
                            icon: 'mi-error-outline',
                            confirmButtonText: commonMessages.ok
                        }).then(() => {
                            history.push('/home');
                        }, _.noop);
                    });
            } else {
                invoiceDetailsPromise = Promise.resolve(invoiceDetails);
            }

            return invoiceDetailsPromise;
        },
        [getPaymentDetailsGroupFromMap, BillingService, authHeader, updateMap, history]
    );

    const updateSelectedInvoice = useCallback((selectedPolicy) => {
        if (selectedPolicy !== null && getActivePolicy().billingMethod_Ext !== 'Agency Bill') {
            const selectedInvStream = (invoiceMapsFromApi.find((invoice) => {
                return invoice.label === selectedPolicy;
            }));
            if (selectedInvStream !== undefined) {
                const invoiceStreamId = selectedInvStream.internalId;

                fetchInvoiceDetailsByStreamId(invoiceStreamId).finally(() => {
                    updateSelectedInvoiceStreamId(invoiceStreamId);
                });
            }
        }
    }, [policySummariesFromApi, activePolicyTab, invoiceMapsFromApi]);

    const getSelectedPolicy = useCallback(() => {
        if (activePolicyTab === 'Initial' && _.isEmpty(policyList)) {
            updateSelectedInvoice(selectedPolicyNumber);

            return selectedPolicyNumber;
        }
        updateSelectedInvoice(activePolicyTab);

        return activePolicyTab;
    }, [policySummariesFromApi, activePolicyTab]);

    useEffect(() => {
        loader.setLoading(true);
        fetchAccountLevelInvoiceStreams().then((retValues) => {
            if (retValues) {
                const initStreamId = selectedInvoiceStreamId || retValues[0];
                fetchInvoiceDetailsByStreamId(initStreamId).finally(() => {
                    updateSelectedInvoiceStreamId(initStreamId);
                    window.scroll(0, 0);
                    loader.setLoading(false);
                });
            }
        });

        // execure once
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const overrideProps = {
        dropdownSelectPolicy: {
            availableValues: getDropdownValues(),
            onValueChange: dropDownSelect,
            value: getSelectedPolicy(),
            onFocus: openDropdown,
            onBlur: closeDropdown,
            disabled: getDropdownValues().length === 0
        },
        billingDetailsSection: {
            selectedPolicy: getActivePolicy()
        },
        paymentScheduleSection: {
            selectedPolicyInvoices: getActivePolicyInvoices(),
            showPaymentSchedule: getActivePolicy().canMakeAPayment_Ext && getActivePolicy().status !== 'Expired'
        },
        tabsStory: {
            className: (getActivePolicy().billingMethod_Ext === 'Agency Bill') ? 'policyCardTabStory agencyBillDetails' : 'policyCardTabStory',
            activeTab: resetTabNow,
            onTabChange: updateActiveTab
        },
        agencyBillTab: {
            visible: getActivePolicy().billingMethod_Ext === 'Agency Bill'
        },
        policyDropdownContainer: {
            className: invertChevron === 'true' ? 'policyselectorChevron' : '',
        },
        paymentHistorySection: {
            selectedPaymentHistory: getActivePolicyInvoices(),
            selectedPolicy: getActivePolicy()
        },
        ezPayButton: {
            href: appConfig.env.EZPAY_URL,
            target: '_blank',
            visible: getActivePolicy().billingMethod_Ext !== 'Agency Bill' && getActivePolicy().canMakeAPayment_Ext && getActivePolicy().status !== 'Expired',
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            custombillingdetails: CustomBillingDetails,
            custompaymentschedule: CustomPaymentSchedule,
            custompaymenthistory: CustomPaymentHistory
        },
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

BillingSummaryPage.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(BillingSummaryPage));
