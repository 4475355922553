import { defineMessages } from 'react-intl';

export default defineMessages({
    noClaimPaymentDataText: {
        id: 'claim.card.No Payment Data',
        defaultMessage: 'There are no payments yet.'
    },
    noClaimDataText: {
        id: 'claim.card.No Claim Data',
        defaultMessage: 'There are no claims within the selected time frame.'
    },
});
