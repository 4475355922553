import React, { useCallback, useEffect, useContext } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { messages } from 'gw-capability-policychange-common-react';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import wizardConfig from '../../config/ho-wizard-config.json5';
import metadata from './PolicyChangesPage.metadata.json5';
import styles from '../../HOPolicyChange.module.scss';
import './PolicyChangesPage.messages';

function PolicyChangesPage(props) {
    const { steps } = wizardConfig;
    const { authHeader } = useAuthentication();
    const translator = useContext(TranslatorContext);
    const { EndorsementService } = useDependencies('EndorsementService');
    const {
        wizardData, currentStepIndex, changeNextSteps, updateWizardData
    } = props;
    const { submissionVM, selectedData } = wizardData;
    const { initialValidation, isComponentValid, registerComponentValidation } = useValidation(
        'PolicyChangesPage'
    );

    const handleChange = useCallback(
        (options) => {
            _.forEach(selectedData, (value, key) => {
                _.set(wizardData, `selectedData.${key}`, options.includes(key));
            });
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData, selectedData]
    );

    const onNext = useCallback(async () => {
        const response = await EndorsementService.checkEffectiveDateIsValid(
            [submissionVM.value.policyNumber, submissionVM.value.baseData.effectiveDate],
            authHeader
        );
        if (response) {
            submissionVM.value = await EndorsementService.loadEndorsementWithEffectiveDate(
                [submissionVM.value.policyNumber, submissionVM.value.baseData.effectiveDate],
                authHeader
            );
            submissionVM.value = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
        }
        const newSteps = steps.slice(currentStepIndex + 1, steps.length);
        if (!selectedData.mortgagee) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/mortgagee'),
                1
            );
        }
        if (!selectedData.valuables) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/valuables'),
                1
            );
        }
        changeNextSteps(newSteps);
        return wizardData;
    }, [
        EndorsementService,
        authHeader,
        changeNextSteps,
        selectedData,
        steps,
        submissionVM.value,
        wizardData,
        currentStepIndex
    ]);

    const getCheckBoxValue = useCallback(() => {
        const result = [];
        _.forEach(selectedData, (value, key) => {
            if (value) {
                result.push(key);
            }
        });
        return result;
    }, [selectedData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        },
        hoPolicyChangeEfectiveDateId: {
            minDate: _.get(submissionVM, 'baseData.minimumEffectiveDate.value'),
            maxDate: _.get(submissionVM, 'baseData.periodEndDate.value')
        },
        typeSelector: {
            value: getCheckBoxValue(),
            onValueChange: handleChange
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const checkSelectedData = useCallback(() => {
        if (
            selectedData.valuables === false
            && selectedData.mortgagee === false
            && selectedData.coverages === false
        ) {
            return false;
        }
        return true;
    }, [selectedData]);

    useEffect(() => {
        registerComponentValidation(checkSelectedData);
    }, [checkSelectedData, registerComponentValidation]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            showPrevious={false}
            cancelLabel={translator(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                classNameMap={resolvers.resolveClassNameMap}
                onModelChange={updateWizardData}
            />
        </WizardPage>
    );
}

PolicyChangesPage.propTypes = wizardProps;

export default PolicyChangesPage;
