import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    dueDate: {
        id: 'billing.payment.schedule.Due date',
        defaultMessage: 'Due date'
    },
    amountBilled: {
        id: 'billing.payment.schedule.Amount billed',
        defaultMessage: 'Amount billed'
    },
    currentDue: {
        id: 'billing.payment.schedule.Current due',
        defaultMessage: 'Current due'
    },
    pastDue: {
        id: 'billing.payment.schedule.Past due',
        defaultMessage: 'Past due'
    },
    paid: {
        id: 'billing.payment.schedule.Paid',
        defaultMessage: 'Paid'
    }

});