import React, { useCallback, useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { withRouter, Redirect } from 'react-router-dom';
import { Image } from '@jutro/components';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import { isPolicyEnrollment } from '../../EnrollmentUtil';
import EnrollmentComponent from '../../components/EnrollmentComponent/EnrollmentComponent';
import styles from './SignUpEnrollment.module.scss';

import metadata from './SignUpEnrollment.metadata.json5';
import messages from './SignUpEnrollment.messages';

const applicationDetails = _.get(appConfig, 'applicationDetails', {});
const isMultiAccountUser = _.get(appConfig, 'self-enrollment.multiAccountEnrollment', false);
const enrollmentType = _.get(appConfig, 'self-enrollment.type', '');

function SignUpEnrollment(props) {
    const { isLoggedIn, logout } = useAuthentication();
    const { history, location } = props;
    const [isLoading, setLoadingState] = useState(false);

    const onSuccessEnrollment = useCallback(() => {
        setLoadingState(true);
        window.location.replace('/home');
    }, [history]);

    const overrides = {
        enrollmentComponent: {
            enrollmentType: enrollmentType.toUpperCase(),
            onSuccessEnrollment,
            informationText: isPolicyEnrollment(enrollmentType)
                ? messages.provideVerificationInformationPolicy
                : messages.provideVerificationInformationAccount,
            informationAdditionalText:
                isMultiAccountUser === true
                    ? messages.ableToAddMorePolicies
                    : messages.ableToAddOnePolicy,
        }
    };

    if (isLoading) {
        return <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} />;
    }

    if (!isLoggedIn) {
        const nextState = { pathname: '/login-page', state: { from: location } };
        return <Redirect to={nextState} />;
    }

    const resolvers = {
        resolveComponentMap: {
            enrollmentcomponent: EnrollmentComponent
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrides,
        resolvers
    );

    return renderedContentFromMetadata;
}

SignUpEnrollment.propTypes = {
    history: PropTypes.shape({
        push: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({}).isRequired
};

export default withRouter(SignUpEnrollment);
