/* eslint-disable max-len */
import React, { useCallback, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext, Flex, FlexItem } from '@jutro/layout';
import { CustomFormatterUtil } from 'gw-portals-util-js';
import { DataTable, DisplayColumn } from '@jutro/datatable';
import messages from './CustomClaimStatusCardComponent.messages';
import styles from './CustomClaimStatusCardComponent.module.scss';
import metadata from './CustomClaimStatusCardComponent.metadata.json5';

function CustomClaimStatusCardComponent(props) {
    const translator = useContext(TranslatorContext);
    const { claimDetails } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const status = claimDetails.derivedClaimStatus !== undefined ? claimDetails.derivedClaimStatus : '-';
    const isAuto = claimDetails.lossType.toUpperCase() === 'AUTO';
    let claimMessage;

    const getCompletedProgress = useCallback(() => {
        if (status !== null) {
            switch (status.toLowerCase()) {
                case 'received':
                    claimMessage = translator(messages.claimReceivedText);
                    return 1;
                case 'assigned':
                    claimMessage = translator(messages.claimAssignedText);
                    return 2;
                case 'under review':
                    claimMessage = translator(messages.claimUnderReviewText);
                    return 3;
                case 'loss inspection scheduled':
                    claimMessage = translator(messages.claimLossInspectionScheduledText);
                    return 4;
                case 'waiting on inspection report':
                    claimMessage = translator(messages.claimInspectionReportText);
                    return 5;
                case 'inspection report received':
                    claimMessage = translator(messages.claimInspectionReportReceivedText);
                    return 6;
                case 'paid and open':
                    claimMessage = translator(messages.claimPaidAndOpenText);
                    return 7;
                case 'paid and closed':
                    claimMessage = translator(messages.claimPaidAndClosedText);
                    return 8;
                case 'closed':
                    claimMessage = translator(messages.claimClosedText);
                    return 8;
                default:
                    return 0;
            }
        } else {
            return 0;
        }
    });

    const issuedPayments = useCallback((claimPayments) => {
        return (
            <>
                <Flex direction="column">
                    <FlexItem>
                        <DataTable
                            showSearch={false}
                            showPagination={false}
                            data={claimPayments}
                            noDataText={translator(messages.noPaymentDataText)}
                        >

                            <DisplayColumn
                                header="#"
                                id="rowNumber"
                                path="rowNumber"
                                textAlign="left"
                                sortable={false}
                                columnProportion={0.25}
                                renderCell={(row, index) => (
                                    <div className={styles.rowStyleBorder}>
                                        <span className={styles.linkCell}>
                                            {index + 1}
                                        </span>
                                    </div>
                                )}
                            />
                            <DisplayColumn
                                header="Amount"
                                id="amount"
                                path="amount"
                                textAlign="left"
                                sortable={false}
                                columnProportion={0.25}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}>
                                        <span>
                                            {CustomFormatterUtil.formatAmount(row.grossAmount.amount)}
                                        </span>
                                    </div>
                                )}
                            />
                            <DisplayColumn
                                header="Disbursement Date"
                                id="disbursementDate"
                                path="disbursementDate"
                                textAlign="left"
                                columnProportion={0.25}
                                sortable={false}
                                renderCell={(row) => (
                                    <div className={styles.rowStyleBorder}>
                                        <span>
                                            {CustomFormatterUtil.formatDateLong(row.scheduledDate)}
                                        </span>
                                    </div>
                                )}
                            />
                            <DisplayColumn
                                header=""
                                id=""
                                path=""
                                textAlign=""
                                columnProportion={0.25}
                                sortable={false}
                                renderCell={() => (
                                    <div className={styles.rowStyleBorder} />
                                )}
                            />
                        </DataTable>
                    </FlexItem>
                </Flex>
            </>
        );
    }, []);

    const overrideProps = {
        claimStatus: {
            content: `${translator(messages.claimStatus)}`
        },
        claimStatusText: {
            content: CustomFormatterUtil.formatText(status)
        },
        claimsProgressBar: {
            completed: getCompletedProgress(status)
        },
        claimStatusSubText: {
            content: claimMessage
        },
        claimDateOfLoss: {
            value: CustomFormatterUtil.formatDateForTable(claimDetails.lossDate),
        },
        claimCauseOfLoss: {
            value: claimDetails.lossCauseDescription,
        },
        claimAddress: {
            label: !isAuto ? `${translator(messages.address)}` : `${translator(messages.modelVin)}`,
            value: !isAuto ? claimDetails.claimLosslocation : claimDetails.vehicleDisplayInfo,
        },
        lastPaymentReceived: {
            visible: true,
            value: '-',
        },
        claimIssuedPayments: {
            visible: claimDetails.claimPayments.length > 0 && !(breakpoint === 'phoneWide' || breakpoint === 'phone')
        },
        claimsdisbursementdiv: {
            content: issuedPayments(claimDetails.claimPayments),
            visible: claimDetails.claimPayments.length > 0 && !(breakpoint === 'phoneWide' || breakpoint === 'phone')
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {}
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomClaimStatusCardComponent.defaultProps = {
};

CustomClaimStatusCardComponent.propTypes = {
};

export default CustomClaimStatusCardComponent;
