import { setComponentMapOverrides } from '@jutro/uiconfig';
import 'gw-capability-policychange-common-react';
import PCPAPolicyChange from './pages/PolicyChanges/PolicyChangePage';
import PCPAAddressPage from './pages/Address/AddressPage';
import PCPACoveragePage from './pages/Coverages/CoveragePage';
import PCPAVehiclePage from './pages/Vehicles/VehiclesPage';
import PCPAQuotePage from './pages/Quote/QuotePage';
import PCPADriverPage from './pages/Drivers/DriversPage';

setComponentMapOverrides(
    {
        PCPAPolicyChange: { component: 'PCPAPolicyChange' },
        PCPAAddressPage: { component: 'PCPAAddressPage' },
        PCPAVehiclePage: { component: 'PCPAVehiclePage' },
        PCPADriverPage: { component: 'PCPADriverPage' },
        PCPACoveragePage: { component: 'PCPACoveragePage' },
        PCPAQuotePage: { component: 'PCPAQuotePage' }
    },
    {
        PCPAPolicyChange,
        PCPAAddressPage,
        PCPAVehiclePage,
        PCPADriverPage,
        PCPACoveragePage,
        PCPAQuotePage
    }
);

// eslint-disable-next-line import/prefer-default-export
export { default as PAPolicyChangeMessages } from './PAPolicyChange.messages';
export { default as PAPolicyChangeWizard } from './PAPolicyChangeWizard';
