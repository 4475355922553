import React from 'react';

const isHOFlex = (policy) => {
    if (typeof (policy.contractFormType) !== 'undefined' && policy.contractFormType !== null && policy.contractFormType === 'HO3') {
        return true;
    }
    return false;
};

const isHOFlex_Ext = (policy) => {
    if (typeof (policy.contractFormType_Ext) !== 'undefined' && policy.contractFormType_Ext !== null && policy.contractFormType_Ext === 'HO3') {
        return true;
    }
    return false;
};

const getPolicyWithType = (policyPeriod) => {
    if (isHOFlex(policyPeriod)) {
        return [<div className="registeredIcon">Homeowners FLEX<sup>®</sup> #{policyPeriod.policyId}</div>];
    }
    return `${policyPeriod.policyType} #${policyPeriod.policyId}`;
};

const getCustomPolicyTitle = (policyPeriod) => {
    if (isHOFlex(policyPeriod)) {
        return [<div className="registeredIcon">Homeowners FLEX<sup>®</sup> policy</div>];
    }
    return `${policyPeriod.policyType} policy`;
};

const getHOFlexTitle = (withPolicy) => {
    if (!withPolicy) {
        return [<div className="registeredIcon">Homeowners FLEX<sup>®</sup></div>];
    }
    return [<div className="registeredIcon">Homeowners FLEX<sup>®</sup> Policy</div>];
};

const getChoosePymtPolicyTitle = (policyNumber, hoFlexInd, policyType) => {
    if (hoFlexInd === 'true' && policyType === 'Homeowners') {
        return [<div className="registeredIcon">Choose your payment method: Homeowners FLEX<sup>®</sup> # {policyNumber}</div>];
    }
    return `Choose your payment method: ${policyType} # ${policyNumber}`;
}

export default {
    getPolicyWithType: getPolicyWithType,
    getCustomPolicyTitle: getCustomPolicyTitle,
    getHOFlexTitle: getHOFlexTitle,
    isHOFlex: isHOFlex,
    isHOFlex_Ext: isHOFlex_Ext,
    getChoosePymtPolicyTitle: getChoosePymtPolicyTitle
};
