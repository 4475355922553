/* eslint-disable max-len */
import React, {
    useEffect, useState, useCallback, useContext
} from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { useAuthentication } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import {
    CustomPolicyCardComponent, CustomAgentComponent, CustomPolicySummaryContext, LoadingContext
} from 'gw-capability-policy-react';
import { CustomPolicyTypeUtil } from 'gw-portals-util-js';
import appConfig from 'app-config';
import styles from './LandingPage.module.scss';
import metadata from './LandingPage.metadata.json5';
import messages from './LandingPage.messages';
import { CustomIconUtil } from 'gw-portals-util-js';
import { EnrollmentSummaryContext } from 'gw-capability-enrollment-react';
import accountPrefTypes from 'gw-capability-profileinfo-react/pages/CustomAccountPrefPage/CustomAccountPrefTypes.json';

function LandingPage() {
    const translator = useContext(TranslatorContext);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const { given_name } = useAuthentication();
    const [accountData, setAccountData] = useState([]);
    const policySummary = useContext(CustomPolicySummaryContext);
    const loader = useContext(LoadingContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = breakpoint === 'tablet' || breakpoint === 'phoneWide' || breakpoint === 'phone';
    const accountPrefOptions = { paperless: 'paperless', usMail: 'usMail', textMessages: 'textMessages' };
    const [docDeliveryFormData, setDocDeliveryFormData] = useState({});
    const [showNotification, setShowNotification] = useState(true);
    const [accountPref, setAccountPref] = useState({ documentDeliveryPref: undefined });
    const [isLoading, setIsLoading] = useState(false);
    const accountProfile = useContext(EnrollmentSummaryContext);

    useEffect(() => {
        loader.setLoading(true);
        if (policySummary.data.length > 0) {
            setAccountPolicyData(policySummary.data);
            window.scroll(0, 0);
            loader.setLoading(false);
        }
    }, [policySummary]);

    useEffect(() => {
        if (accountProfile.data.profile !== undefined && accountProfile.data.profile.preferences) { getAccountPrefData(); }
    }, [accountProfile.data.profile]);


    let hasMultipleAgents = false;

    const getAgentCards = useCallback(() => {
        const customAgentCard = [];
        const agentList = [];
        accountPolicyData.map((policyData) => {
            const indexFound = agentList.findIndex((producer) => producer.producer_code === policyData.producerContact.producerCodeOfService_Ext);
            const newPolicy = [CustomPolicyTypeUtil.getPolicyWithType(policyData.periods[0])];

            if (indexFound > -1) {
                agentList[indexFound].producer_policeis.push(newPolicy);
            } else {
                const tempPolicy = { producer_code: policyData.producerContact.producerCodeOfService_Ext, producer_details: policyData.producerContact, producer_policeis: newPolicy };
                agentList.push(tempPolicy);
            }
        });

        hasMultipleAgents = agentList.length > 1;

        agentList.map((agentd) => {
            if (agentList.findIndex((ag) => ag === agentd) > 0) {
                customAgentCard.push(
                    <hr className={styles.mobileHr} />
                );
            }
            customAgentCard.push(<div className={styles.agentComponent}><CustomAgentComponent agentDetails={agentd} singlePolicy={false}></CustomAgentComponent></div>);
        });

        return customAgentCard;
    }, [accountPolicyData]);

    const getTitle = useCallback(() => {
        const title = hasMultipleAgents ? messages.contactAgents : messages.contactAgent;

        return translator(title);
    }, [accountPolicyData, translator]);

    const getSubTitle = useCallback(() => {
        const subTitle = hasMultipleAgents ? messages.contactAgentsSubHeader : messages.contactAgentSubHeader;

        return translator(subTitle);
    }, [accountPolicyData, translator]);

    const dismissNotification = useCallback((isPaperless) => () => {
        if (!isPaperless) { setShowNotification(false); }
    });

    const mapDocDelivery = useCallback((isPaperless) => {
        let preference = 'none';
        if (isPaperless) { preference = accountPrefOptions.paperless; }
        return preference;
    });

    const updateDocDelivery = useCallback((deliveryPref) => {
        const docDelivery = { policyDocDelivery: 'default' };
        docDelivery.policyDocDelivery = mapDocDelivery(deliveryPref.documentDeliveryPref);
        setDocDeliveryFormData(docDelivery);
    }, [docDeliveryFormData]);

    const areBothPaperless = useCallback(() => {
        if ((!showNotification || docDeliveryFormData.policyDocDelivery === accountPrefOptions.paperless)) { return false; }
        return true;
    }, [docDeliveryFormData, showNotification]);

    const isNotificationRequired = useCallback(() => {
        if (areBothPaperless()) { return { value: true, type: 'paperlessNotification', icon: CustomIconUtil.getLogoSrc('leaf.png'), header: translator(messages.goPaperlessHeader), body: translator(messages.goPaperlessBody) }; }
        return { value: false, type: ' ', icon: ' ', text: ' ' };
    }, [showNotification, docDeliveryFormData]);

    const getAccountPrefData = () => {
        if (accountProfile.data && accountProfile.data.success) {
            const { preferences, contact } = accountProfile.data.profile;
            let paperlessPolicyDocsPref;
            const paperlessPolicyDocs = preferences.find((e) => e.preferenceName === accountPrefTypes.paperlessPolicyDocs.code);
            if (paperlessPolicyDocs) { paperlessPolicyDocsPref = paperlessPolicyDocs.preferenceValue === accountPrefTypes.paperlessTrue.code; }

            const accPref = { ...accountPref, documentDeliveryPref: paperlessPolicyDocsPref };
            updateDocDelivery(accPref);
            window.scroll(0, 0);
            loader.setLoading(false);
        }
    };

    const changeDeliveryMethod = () => {
        window.accountPref = 'paperless';
    }

    if (_.isEmpty(accountPolicyData)) {
        return null;
    }
    const override = {
        infoLogo: {
            src: isNotificationRequired().value === true ? isNotificationRequired().icon : ' ',
            className: 'iconChecked'
        },
        dismissLogo: {
            src: CustomIconUtil.getLogoSrc('dismiss-icon.png')
        },
        dismissButton: {
            onClick: dismissNotification(accountPref.documentDeliveryPref)
        },
        dismissButtonMobile: {
            onClick: dismissNotification(accountPref.documentDeliveryPref)
        },
        dismissLogoMobile: {
            src: CustomIconUtil.getLogoSrc('dismiss-icon.png')
        },
        changeDeliveryMethodButton: {
            visible: isNotificationRequired().type === 'paperlessNotification',
            onClick: changeDeliveryMethod,
            to: '/userprofile',
            content: isNotificationRequired().type === 'paperlessNotification' ? 'Change delivery method' : ' '
        },
        customNotificationContainer: {
            visible: isNotificationRequired().value === true,
            className: isNotificationRequired().type
        },
        customHeading: {
            content: isNotificationRequired().value === true ? isNotificationRequired().header : ' '
        },
        customText: {
            content: isNotificationRequired().value === true ? isNotificationRequired().body : ' '
        },
        '@field': {
            phone: {
                labelPosition: 'left'
            }
        },
        mediaSidebar: {
            showContact: accountData.length > 1
        },
        policiesTable: {
            data: accountPolicyData
        },
        contactAgentDiv: {
            content: getAgentCards()
        },
        contactAgentTitle: {
            content: getTitle()
        },
        contactAgentSubTitle: {
            content: getSubTitle()
        },

        effective_dates: {
            value: accountPolicyData[0]
        },
        policyCardComponentPanel: {
            data: accountPolicyData
        },
        greetingTitle: {
            content: translator(messages.greetingContent, { user_name: given_name })
        },
        fileAClaimButton: {
            href: appConfig.env.FNOL_URL,
            target: '_blank',
            visible: isMobile
        },
        fileAClaimButtonMobile: {
            href: appConfig.env.FNOL_URL,
            target: '_blank'
        },
        documentDeliverySection: {
            deliveryMethods: setDocDeliveryFormData,
            deliveryData: docDeliveryFormData,
            dismissNotification: dismissNotification,
            setLoadingState: setIsLoading,
            isLoading: isLoading
        }

    };

    const resolvers = {
        resolveClassNameMap: styles,

        resolveComponentMap: {
            CustomPolicyCardComponent
        }

    };

    return renderContentFromMetadata(metadata.pageContent, override, resolvers);
}

export default withRouter(LandingPage);
