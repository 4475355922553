import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicleVIN: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.VIN',
        defaultMessage: 'VIN',
    },
    paVehicleMake: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Make',
        defaultMessage: 'Make',
    },
    paVehicleModel: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Model',
        defaultMessage: 'Model',
    },
    paVehicleYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Year',
        defaultMessage: 'Year',
    },
    paVehicleYearPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.-- Choose Vehicle Year --',
        defaultMessage: '-- Choose Vehicle Year --',
    },
    representativeImage: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.vehiclePhotoMsg',
        defaultMessage: 'This is a representative image of a vehicle of the same year, make, and model as yours. Details, such as color can be different.',
    },
    representativeScreenReader: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Vehicle Image',
        defaultMessage: 'Vehicle Image',
    },
    representativeImagePrefix: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-vehicle.Note:',
        defaultMessage: 'Note:',
    },
    vinInfoText: {
        id: 'quoteandbind.quickquote.pa.directives.Vehicle.The vehicle identification number (VIN) is unique to each car and contains information about its specific features, brand, and manufacturer.',
        defaultMessage: 'The vehicle identification number (VIN) is unique to each car and contains information about its specific features, brand, and manufacturer.'
    }
});
