import { defineMessages } from 'react-intl';

export default defineMessages({
    
    policyTypeValue: {
        id: 'policy.card.{type} PolicyType',
        defaultMessage: '{type} Policy'
    },
    policyIdValue: {
        id: 'policyId.card.#{id}',
        defaultMessage: '#{id}'
    },
    statusActive: {
        id: 'policy.claim.card.policy-status.Active',
        defaultMessage: 'Active'
    },
    statusCanceled: {
        id: 'policy.claim.card.policy-status.Canceled',
        defaultMessage: 'Canceled'
    },
    claimCardViewPolicy: {
       id: 'custom.claim.card.View Policy',
       defaultMessage: 'View policy'
    },
    noDataText: {
        id: 'claim.card.noDataText',
        defaultMessage: 'There are no claims present within the selected time frame.'
    },
    claimContactYourAgent: {
        id: "claim.card.contactYourAgentText",
        defaultMessage: "Do you have a claim with us that is not showing? Contact your agent at "
    }
});