import { defineMessages } from 'react-intl';

export default defineMessages({
    myPolicies: {
        id: 'portal.views.home-page-layout.My Policies',
        defaultMessage: 'My policies',
    },
    policyNotShowing: {
        id: 'portal.views.home-page-layout.Do you have a policy with us but it is not showing?',
        defaultMessage: 'Do you have a policy with us but it is not showing?'
    },
    addPolicy: {
        id: 'portal.views.home-page-layout.Add Policy',
        defaultMessage: 'Add policy.'
    },
    agentInformation: {
        id: 'portal.views.home-page-layout.Your Agent Information',
        defaultMessage: 'Your agent information'
    },
    contactAgent: {
        id: 'portal.views.home-page-layout.Agent',
        defaultMessage: 'Contact your agent'
    },
    contactAgentSubHeader: {
        id: 'portal.views.home-page-layout.AgentSub',
        defaultMessage: 'Your agent is here to help whenever you need them. Reach out via phone or email.'
    },
    contactAgents: {
        id: 'portal.views.home-page-layout.Agents',
        defaultMessage: 'Contact your agents'
    },
    contactAgentsSubHeader: {
        id: 'portal.views.home-page-layout.AgentsSub',
        defaultMessage: 'Your agents are here to help whenever you need them. Reach out via phone or email.'
    },
    greetingContent: {
        id: 'portal.views.home-page-layout.greetingContent',
        defaultMessage: 'Welcome, {user_name}'
    },
    fileAClaimButton: {
        id: 'portal.views.home-page-layout.file-claim-button',
        defaultMessage: 'File a claim'
    }
});
