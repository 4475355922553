import { defineMessages } from 'react-intl';

export default defineMessages({
    paVehicle: {
        id: 'endorsement.pa.directives.templates.pa-edit-vehicle.Vehicles',
        defaultMessage: 'Vehicles',
    },
    paNewVehicle: {
        id: 'endorsement.pa.directives.templates.pa-edit-vehicle.New Vehicle',
        defaultMessage: 'New Vehicle',
    },
    paVehicleNotUniqueTitle: {
        id: 'endorsement.pa.controllers.PAPolicyChangeDriverCtrl.VIN is not unique',
        defaultMessage: 'VIN is not unique',
    },
    paVinMatch: {
        id: 'endorsement.pa.controllers.PAPolicyChangeDriverCtrl.VinMatchMsg',
        defaultMessage: 'Sorry, but this vehicle VIN matches that of {vinMatchedVehicle}. Please ensure that all VINs are unique.'
    },
    atleastOneVehicle: {
        id: 'endorsement.pa.controllers.PAPolicyChangeDriverCtrl.You have to have at least one vehicle',
        defaultMessage: 'You have to have at least one vehicle',
    },
    addVehical: {
        id: 'endorsement.pa.views.pa-driver-details.Add Vehicle',
        defaultMessage: 'Add Vehicle'
    },
    addAnotherVehical: {
        id: 'endorsement.pa.views.pa-driver-details.Add Another Vehicle?',
        defaultMessage: 'Add Another Vehicle?'
    }
});
