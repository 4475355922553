import React, { useEffect } from 'react';
import { MetadataForm } from '@jutro/uiconfig';
import appConfig from 'app-config';
import metadata from './CustomChatbotComponent.metadata.json5';
import styles from './CustomChatbotComponent.module.scss';
import messages from './CustomChatbotComponent.messages.js';

function CustomChatbotComponent() {
    const { env } = appConfig;

    useEffect(() => {
        const script = document.createElement('script');
        script.src = env.AM_CHATBOT_CLIENT_URL;
        script.async = true;
        document.body.appendChild(script);
    }, []);

    const getChatbotContent = () => {
        const chatbotContent = [
            <div className={styles.burgerCustomContent} id={'webchat'} />
        ];

        return chatbotContent;
    };

    const overrideProps = {
        chatbotContainer: {
            content: getChatbotContent(),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomChatbotComponent.defaultProps = {
};

CustomChatbotComponent.propTypes = {
};

export default CustomChatbotComponent;

