import React, { useCallback, useEffect } from 'react';
import _ from 'lodash';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useAuthentication } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { PolicyChange, messages } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import wizardConfig from '../../config/pa-policychange-wizard-config.json5';
import './PolicyChangePage.messages';
import styles from '../../PAPolicyChange.module.scss';
import metadata from './PolicyChangePage.metadata.json5';

function PAPolicyChange(props) {
    const { steps } = wizardConfig;
    const { authHeader } = useAuthentication();
    const {
        updateWizardData, changeNextSteps, currentStepIndex, wizardData
    } = props;
    const { submissionVM, selectedPages } = wizardData;
    const { EndorsementService } = useDependencies('EndorsementService');
    const { isComponentValid, registerComponentValidation } = useValidation('PAPolicyChange');

    const handleChange = useCallback(
        (options) => {
            _.forEach(selectedPages, (value, key) => {
                _.set(wizardData, `selectedPages.${key}`, options.includes(key));
            });
            updateWizardData(wizardData);
        },
        [updateWizardData, wizardData, selectedPages]
    );

    const onNext = useCallback(async () => {
        const response = await EndorsementService.checkEffectiveDateIsValid(
            [submissionVM.policyNumber.value, submissionVM.baseData.effectiveDate.value],
            authHeader
        );
        if (response) {
            const effectiveDateResposne = await EndorsementService.loadEndorsementWithEffectiveDate(
                [submissionVM.policyNumber.value, submissionVM.baseData.effectiveDate.value],
                authHeader
            );
            submissionVM.value = new PolicyChange(effectiveDateResposne);
            submissionVM.value.beforeSave();
            const policyChangeResponse = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
            submissionVM.value = new PolicyChange(policyChangeResponse);
        }
        const newSteps = steps.slice(currentStepIndex + 1, steps.length);
        if (!selectedPages.address) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/address'),
                1
            );
        }
        if (!selectedPages.vehicles) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/vehicles'),
                1
            );
        }
        if (!selectedPages.drivers) {
            newSteps.splice(
                _.findIndex(newSteps, ({ path }) => path === '/driver'),
                1
            );
        }
        changeNextSteps(newSteps);
        return wizardData;
    }, [
        submissionVM,
        authHeader,
        steps,
        selectedPages,
        EndorsementService,
        changeNextSteps,
        wizardData,
        currentStepIndex
    ]);

    const getCheckBoxValue = useCallback(() => {
        const result = [];
        _.forEach(selectedPages, (value, key) => {
            if (value) {
                result.push(key);
            }
        });
        return result;
    }, [selectedPages]);

    const overrideProps = {
        '@field': {
            showOptional: false,
            labelPosition: 'left'
        },
        paPolicyChangeEffectiveDate: {
            minDate: _.get(submissionVM, 'baseData.minimumEffectiveDate.value'),
            maxDate: _.get(submissionVM, 'baseData.periodEndDate.value')
        },
        typeSelector: {
            value: getCheckBoxValue(),
            onValueChange: handleChange
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const checkSelectedPages = useCallback(() => {
        if (
            selectedPages.address === false
            && selectedPages.vehicles === false
            && selectedPages.drivers === false
            && selectedPages.coverages === false
        ) {
            return false;
        }
        return true;
    }, [selectedPages]);

    useEffect(() => {
        registerComponentValidation(checkSelectedPages);
    }, [checkSelectedPages, registerComponentValidation]);

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            showPrevious={false}
            cancelLabel={(appConfig.persona === 'policyholder' ? messages.cancelAllChanges : messages.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={wizardData}
                overrideProps={overrideProps}
                onModelChange={updateWizardData}
                classNameMap={resolvers.resolveClassNameMap}
            />
        </WizardPage>
    );
}

PAPolicyChange.propTypes = wizardProps;
export default PAPolicyChange;
