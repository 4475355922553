import React, { useState, useCallback, Fragment } from 'react';
import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { EntryPageTemplate } from 'gw-components-platform-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import { useAuthentication } from 'gw-digital-auth-react';
import { Image } from '@jutro/components';

import metadata from './EntryPage.metadata.json5';
import styles from './EntryPage.module.scss';
import './EntryPage.messages';

const VIEWS = {
    logInView: 'LOG_IN_VIEW',
    signUpView: 'SIGN_UP_VIEW',
    forgotPasswordView: 'FORGOT_PASSWORD_VIEW'
};

const { applicationDetails } = appConfig;

function EntryPage({ location }) {
    const { isAuthInProgress, isLoggedIn } = useAuthentication();
    const [view, updateView] = useState(VIEWS.logInView);
    const { state } = location;

    const handleForgotPasswordClick = useCallback(() => {
        updateView(VIEWS.forgotPasswordView);
    }, []);

    const handleSignUpClick = useCallback(() => {
        updateView(VIEWS.signUpView);
    }, []);

    const handleSignInClick = useCallback(() => {
        updateView(VIEWS.logInView);
    }, []);

    if (isAuthInProgress) {
        return <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} />;
    }

    const overrideProps = {
        gwLoginComponent: {
            successPath: _.get(state, 'from.pathname') || '/home'
        },
        logInViewContainer: {
            visible: view === VIEWS.logInView
        },
        signUpViewContainer: {
            visible: view === VIEWS.signUpView
        },
        forgotPasswordViewContainer: {
            visible: view === VIEWS.forgotPasswordView
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onForgotPasswordClick: handleForgotPasswordClick,
            onSignUpClick: handleSignUpClick,
            onSignInClick: handleSignInClick
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
    if (isLoggedIn === false) {
        return (
            <Fragment />
        );
    }
    return (
        <EntryPageTemplate
            applicationName={applicationDetails.applicationName}
            applicationLogo={applicationDetails.applicationLogo}
            contentToRender={renderedContentFromMetadata}
        />
    );
}

EntryPage.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({})
    })
};

EntryPage.defaultProps = {
    location: {}
};

export default EntryPage;
