import React, { useContext, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CustomCoveredDriversComponent.metadata.json5';
import messages from './CustomCoveredDriversComponent.messages';
import styles from './CustomCoveredDriversComponent.module.scss';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomFormatterUtil } from 'gw-portals-util-js';

function CustomCoveredDriversComponent(props) {
    const translator = useContext(TranslatorContext);
    const [openItems, setOpenItems] = useState([]);
    const { value: paCoverages } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');

    const generateDriverOverrides = useCallback(() => {
        const overrides = paCoverages.driverDTOs.map((driverDTO, index) => {
            return {
                [`gridSeparator${index}`]: {
                    visible: ((paCoverages.driverDTOs.length - 1) !== index)
                },               
                [`driverDOB${index}`]: {
                    value: CustomFormatterUtil.maskDOB(driverDTO.dateOfBirth),
                },
                [`driverExcluded${index}`]:{
                    value: driverDTO.excluded_Ext?"Yes":"No",
                },
                [`driverLicense${index}`]:{
                    value: CustomFormatterUtil.maskLicense(driverDTO.licenseNumber),
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [paCoverages]);

    const overrides = {
        policyCommonTableHeader: {
            visible: !isMobile
        },
        ...generateDriverOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={paCoverages}
            overrideProps={overrides}
            classNameMap={resolvers.resolveClassNameMap}
            callbackMap={resolvers.resolveCallbackMap}
            componentMap={resolvers.resolveComponentMap}
        />
    );
}

CustomCoveredDriversComponent.propTypes = {
    value: PropTypes.shape({
       
        driverDTOs: PropTypes.arrayOf(PropTypes.shape({})),
       
    }).isRequired
};

export default CustomCoveredDriversComponent;
