import React, { useContext } from 'react';
import _ from 'lodash';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomFormatterUtil, CustomIconUtil } from 'gw-portals-util-js';
import metadata from './CustomAgentComponent.metadata.json5';
import styles from './CustomAgentComponent.module.scss';

function CustomAgentComponent(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const { agentDetails, claimDetails, singlePolicy } = props;

    const getCoveredPolicies = (rowData) => {
        const policies = agentDetails.producer_policeis.map((policy_number) => <li>{policy_number}</li>);

        return policies;
    };

    const isAgentCompany = (rowData) => {
        if (agentDetails && agentDetails.producer_details) {
            return agentDetails.producer_details.producerCodeOfServiceAgency_Ext === props.agentDetails.producer_details.producerCodeOfServiceProducer_Ext;
        }
        return true;
    };

    const isAdjuster = () => {
        if (claimDetails) { return true; }
        return false;
    };

    const overrideProps = {
        agentName: {
            content: isAdjuster() ? claimDetails.claimAdjuster.displayName : agentDetails.producer_details.producerCodeOfServiceProducer_Ext,
            className: isAgentCompany() ? 'company agent-name' : 'agent-name'
        },
        agentCompany: {
            content: isAdjuster() ? '' : agentDetails.producer_details.producerCodeOfServiceAgency_Ext,
            visible: !isAgentCompany()
        },
        phoneNumber: {
            content: CustomFormatterUtil.formatPhoneNumber(isAdjuster() ? claimDetails.claimAdjuster.phoneNumber : agentDetails.producer_details.producerCodeOfServiceProducerPhone_Ext),
            href: isMobile ? `tel:${CustomFormatterUtil.formatPhoneNumber(isAdjuster() ? claimDetails.claimAdjuster.phoneNumber : agentDetails.producer_details.producerCodeOfServiceProducerPhone_Ext)}` : ''
        },
        agentEmail: {
            content: isAdjuster() ? claimDetails.claimAdjuster.email : agentDetails.producer_details.producerCodeOfServiceProducerEmail_Ext,
            href: `mailto:${isAdjuster() ? claimDetails.claimAdjuster.email : agentDetails.producer_details.producerCodeOfServiceProducerEmail_Ext}`
        },
        policyList: {
            content: agentDetails && agentDetails.producer_policeis ? getCoveredPolicies() : [],
            visible: !(singlePolicy)
        },
        agentAvatarLogo: {
            imageSource: CustomIconUtil.getLogoSrc('avatar-icon.png')
        },
        phoneIcon: {
            src: isMobile ? CustomIconUtil.getLogoSrc('phone-icon-green.png') : CustomIconUtil.getLogoSrc('phone-icon.png')
        },
        emailIcon: {
            src: CustomIconUtil.getLogoSrc('email-icon.png')
        },
        policiesCoveredTitle: {
            visible: !(singlePolicy)
        },
        agentContactPhoneWrapper: {
            className: (singlePolicy) ? 'agent-contact-wrapper agent-contact-wrapper-minified' : 'agent-contact-wrapper'
        },
        agentContactEmailWrapper: {
            className: (singlePolicy) ? 'agent-contact-wrapper agent-contact-wrapper-minified' : 'agent-contact-wrapper'
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomAgentComponent.defaultProps = {
};

CustomAgentComponent.propTypes = {
};

export default CustomAgentComponent;
