import { useCallback, useMemo } from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import PropTypes from 'prop-types';
import { ModalNextProvider } from '@jutro/components';
import styles from '../../Claims.module.scss';
import ClaimServicesVendorPopupMetaData from './VendorContactDetailsPopup.metadata.json5';
import metadata from './VendorLinkComponent.metadata.json5';

function VendorLinkComponent(props) {
    const { vendors } = props;

    const getServiceVendorPopupModalContent = useCallback((item) => {
        const resolvers = {
            resolveValue: (id, path) => _.get(item, path),
            resolveClassNameMap: styles
        };
        return renderContentFromMetadata(
            ClaimServicesVendorPopupMetaData.pageContent,
            {},
            resolvers
        );
    }, []);

    const openVendorPopup = useCallback(
        (item) => {
            const displayName = _.get(item, 'contactDTO.displayName', '');
            const renderServiceVendorContent = getServiceVendorPopupModalContent(item.contactDTO);
            ModalNextProvider.showAlert({
                title: displayName,
                message: renderServiceVendorContent,
                status: 'info',
                icon: 'mi-error-outline'
            }).catch(_.noop);
        },
        [getServiceVendorPopupModalContent]
    );

    const generateOverrides = useMemo(() => {
        const overrides = vendors.map((item, index) => {
            return {
                [`serviceVendorLink${index}`]: {
                    content: item.contactDTO.displayName,
                    onClick: () => {
                        openVendorPopup(item);
                    }
                }
            };
        });
        return Object.assign({}, ...overrides);
    }, [vendors, openVendorPopup]);

    const overrides = {
        vendorsIterable: {
            data: vendors
        },
        ...generateOverrides
    };

    return renderContentFromMetadata(metadata.componentContent, overrides, {});
}

VendorLinkComponent.propTypes = {
    vendors: PropTypes.arrayOf(PropTypes.shape({})).isRequired
};

VendorLinkComponent.defaultProps = {
    vendors: []
};

export default VendorLinkComponent;
