import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    paymentDetails: {
        id: 'payment.payment receipt modal.Payment details',
        defaultMessage: 'Payment details'
    },
    americanModern: {
        id: 'payment.payment receipt modal.American Modern',
        defaultMessage: 'American Modern Property and Casualty Insurance Company'
    },
    americanModernContact: {
        id: 'payment.payment receipt modal.American Modern Contact',
        defaultMessage: '7000 Midland Blvd. | Amelia, OH 45102 | www.amig.com'
    },
    receiptNumber: {
        id: 'payment.payment receipt modal.Receipt number',
        defaultMessage: 'Receipt number:'
    },
    policyNumber: {
        id: 'payment.payment receipt modal.Policy number',
        defaultMessage: 'Policy number:'
    },
    namedInsured: {
        id: 'payment.payment receipt modal.Named insured',
        defaultMessage: 'Named insured:'
    },
    policyTerm: {
        id: 'payment.payment receipt modal.Policy term',
        defaultMessage: 'Policy term:'
    },
    paymentMethod: {
        id: 'payment.payment receipt modal.Payment method',
        defaultMessage: 'Payment method:'
    },
    paymentDate: {
        id: 'payment.payment receipt modal.Payment date',
        defaultMessage: 'Payment date:'
    },
    paymentAmount: {
        id: 'payment.payment receipt modal.Payment amount',
        defaultMessage: 'Payment amount:'
    }
});
