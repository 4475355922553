import { defineMessages } from 'react-intl';

export default defineMessages({
    policyChanges: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Policy Changes',
        defaultMessage: 'Policy Changes',
    },
    policyChangeEffectiveDate: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date',
    },
    typeOfChange: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Select Type of Changes',
        defaultMessage: 'Select Type of Changes',
    },
    address: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Address Change',
        defaultMessage: 'Address Change',
    },
    vehicles: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Add, Edit or remove Vehicle',
        defaultMessage: 'Add, Edit or remove Vehicle',
    },
    drivers: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Add, Edit or remove Driver',
        defaultMessage: 'Add, Edit or remove Driver',
    },
    coverages: {
        id: 'endorsement.pa.directives.templates.pa-whats-next.Coverage change',
        defaultMessage: 'Coverage change',
    },
    paPolicyChangeTitle: {
        id: 'endorsement.directives.templates.bank-account-details-quote.Policy Change - Personal Auto',
        defaultMessage: 'Policy Change - Personal Auto ({quoteNumber})'
    }
});
