import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    modalErrorMessageHeader: {
        id: 'account.account pref modal.Error Message Header',
        defaultMessage: 'Something went wrong'
    },
    modalErrorMessageContent: {
        id: 'account.account pref modal.Error Message Content',
        defaultMessage: 'There was a problem getting your account preferences information. Please click Return to home to continue. If this error persists, please call us at 1(855) 469-2644.'
    },
    modalErrorReturnButton: {
        id: 'account.account pref modal.Return to home',
        defaultMessage: 'Return to home'
    },
});
