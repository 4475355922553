/* eslint-disable max-len */
import React, { useContext, useState, useEffect, useCallback } from 'react';
import _ from 'lodash';
import { CustomTooltipUtil, CustomPolicyTypeUtil } from 'gw-portals-util-js';
import { withRouter } from 'react-router-dom';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { ClaimService } from 'gw-capability-claim';
import { useAuthentication } from 'gw-digital-auth-react';
import { CustomAgentComponent, LoadingContext } from 'gw-capability-policy-react';
import { CustomPolicySummaryContext } from 'gw-capability-policy-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { CustomClaimStatusCardComponent, CustomMobileTable } from 'gw-capability-claim-react';
import metadata from './CustomClaimDetails.metadata.json5';
import styles from './CustomClaimDetails.module.scss';
import messages from '../../Claims.messages';

function CustomClaimDetails(props) {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const { authHeader } = useAuthentication();
    const loader = useContext(LoadingContext);
    const { claimNumber } = props.match.params;
    const translator = useContext(TranslatorContext);
    const [claimData, setClaimData] = useState([]);
    const policySummary = useContext(CustomPolicySummaryContext);
    const [claimPolicyType, setClaimPolicyType] = useState('');
    const breadcrumbLinks = [
        {
            to: '/home',
            text: translator(messages.breadcrumbHome)
        },
        {
            to: '/claims',
            text: translator(messages.breadcrumbClaims)
        },
        {
            to: '/',
            text: translator(messages.breadcrumbClaimNumber, { claimNumber: claimNumber })
        }
    ];

    const getReportedByName = (claimDetailsData) => {
        if (claimDetailsData.claimReporter.reportedBy.displayName) {
            return claimDetailsData.claimReporter.reportedBy.displayName;
        }
        return '-';
    };

    const getClaimDetailsResponse = useCallback(async () => {
        loader.setLoading(true);
        ClaimService.getClaimDetail(claimNumber, authHeader)
            .then((claimResponse) => {
                const claimInfo = {
                    claimAdjuster: claimResponse.adjuster,
                    claimPayments: claimResponse.checks,
                    derivedClaimStatus: claimResponse.derivedClaimStatus_Ext,
                    reporter: getReportedByName(claimResponse),
                    lossType: claimResponse.lossType,
                    lossDate: claimResponse.lossDate,
                    lossCauseDescription: claimResponse.lossCauseDescription_Ext,
                    claimLosslocation: claimResponse.lossLocation.displayName,
                    multipleVehiclesInvolved: claimResponse.multipleVehiclesInvolved_Ext,
                    policyNumber: claimResponse.policy.policyNumber,
                    policyType: claimResponse.policy.policyType,
                };
                if (claimResponse.lossType.toUpperCase() === 'AUTO') {
                    claimInfo.vehicleDisplayInfo = !claimInfo.multipleVehiclesInvolved ? `${claimResponse.vehicleYear_Ext} ${claimResponse.vehicleMake_Ext} ${claimResponse.vehicleModel_Ext} / ${claimResponse.vehicleVIN_Ext}` : translator(messages.multipleVehicles);
                }
                setClaimData(claimInfo);
                loader.setLoading(false);
            })
            .catch(() => {
                loader.setLoading(false);
            });
    });

    useEffect(() => {
        try {
            getClaimDetailsResponse();
        } catch (e) {
            throw new Error(e);
        }
    }, []);

    useEffect(() => {
        if (policySummary.data.length > 0 && claimData) {
            const policyPeriod = policySummary.data.find((policy) => _.first(policy.periods).policyId === claimData.policyNumber);
            if (policyPeriod !== undefined) {
                setClaimPolicyType(CustomPolicyTypeUtil.getCustomPolicyTitle(_.first(policyPeriod.periods)));
            } else {
                setClaimPolicyType(`${claimData.policyType} policy`);
            }
        }
    }, [claimData, policySummary.data]);

    const overrides = {
        claimDetailsPageBreadcrumb: {
            visible: isMobile,
            links: breadcrumbLinks
        },
        contactAdjusterDiv: {
            className: 'adjuster-column',
        },
        claimStatusTitle: {
            content: `${translator(messages.claimStatusTitle)} #${claimNumber}`,
            className: 'claim-status-title',
        },
        claimDetailsStatusCard: {
            visible: (Object.keys(claimData).length > 0),
            className: 'tableContainer claim-status-card',
        },
        claimStatusMobileCard: {
            visible: claimData.claimPayments && claimData.claimPayments.length > 0 && (breakpoint === 'phoneWide' || breakpoint === 'phone') && (Object.keys(claimData).length > 0),
            className: 'tableContainer claim-status-card-mobile',
        },
        claimdetails: {
            claimDetails: claimData,
        },
        claimsdisbursementmobiletable: {
            visible: (breakpoint === 'phoneWide' || breakpoint === 'phone') && (Object.keys(claimData).length > 0),
            issuedPayments: claimData.claimPayments ? claimData.claimPayments : [],
        },
        adjusterCard: {
            visible: (Object.keys(claimData).length > 0),
            className: 'tableContainer claim-adjuster-card',
        },
        adjusterdetails: {
            claimDetails: claimData,
            singlePolicy: true,
        },
        backToClaimsButton: {
            to: '/claims'
        },
        trackClaimsPolicyType: {
            content: claimPolicyType
        },
        trackClaimsPolicyNumber: {
            content: `#${claimData.policyNumber}`,
            hideLabel: !isMobile
        },
        trackClaimsPolicyNumberTooltip: {
            content: CustomTooltipUtil.getTooltip(claimData.policyNumber),
            hideLabel: !isMobile
        },
        claimTitle: {
            content: translator(messages.breadcrumbClaimNumber, { claimNumber: claimNumber })
        },
        trackClaimsViewPolicyButton: {
            to: `/account-policy-details/${claimData.policyNumber}`
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            claimStatusCard: CustomClaimStatusCardComponent,
            claimsmobiletable: CustomMobileTable,
            adjusterCard: CustomAgentComponent
        }
    };

    if (!loader.loading) {
        return (
            <>
                <ViewModelForm
                    uiProps={metadata.pageContent}
                    model={claimData}
                    overrideProps={overrides}
                    componentMap={resolvers.resolveComponentMap}
                    classNameMap={resolvers.resolveClassNameMap}
                    callbackMap={resolvers.resolveCallbackMap}
                />
            </>
        );
    }
    return <> </>;
}
CustomClaimDetails.propTypes = {
};
export default withRouter(CustomClaimDetails);
