import { defineMessages } from 'react-intl';

export default defineMessages({
    paDriverFirstName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.First Name',
        defaultMessage: 'First Name',
    },
    paDriverLastName: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Last Name',
        defaultMessage: 'Last Name',
    },
    paDriverDateOfBirth: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Date of Birth',
        defaultMessage: 'Date of Birth',
    },
    paDriverGender: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Gender',
        defaultMessage: 'Gender',
    },
    paDriverGenderPlaceholder: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose Driver Gender --',
        defaultMessage: '-- Choose Driver Gender --',
    },
    paDriversLicenseNumber: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Drivers License Number',
        defaultMessage: 'Drivers License Number',
    },
    paDriverLicenceState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.License State',
        defaultMessage: 'License State',
    },
    paDriverYearFirstLicensed: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Year First Licensed',
        defaultMessage: 'Year First Licensed',
    },
    paDriverNumberOfAccidents: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Accidents',
        defaultMessage: 'Number Of Accidents',
    },
    paDriverNumberOfViolations: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.Number Of Violations',
        defaultMessage: 'Number Of Violations',
    },
    paDriverLicenseYear: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License Year --',
        defaultMessage: '-- Choose License Year --',
    },
    paDriverLicenseState: {
        id: 'quoteandbind.pa.directives.templates.pa-edit-driver.-- Choose License State --',
        defaultMessage: '-- Choose License State --',
    }
});
