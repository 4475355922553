import React, { useEffect, useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomPolicyTypeUtil, CustomIconUtil } from 'gw-portals-util-js';
import { CustomPolicySummaryContext } from 'gw-capability-policy-react';
import metadata from './CustomContactUsComponent.metadata.json5';
import styles from './CustomContactUsComponent.module.scss';
import CustomStickyCardComponent from '../CustomStickyCardComponent/CustomStickyCardComponent';

function CustomContactUsComponent() {
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const policySummary = useContext(CustomPolicySummaryContext);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const [isLoading, setLoadingState] = useState(false);

    const getEmailIcon = useCallback(() => {
        return isMobile ? CustomIconUtil.getLogoSrc('mail-icon.png') : CustomIconUtil.getLogoSrc('email-icon-dark.png');
    });

    const getPhoneIcon = useCallback(() => {
        return isMobile ? CustomIconUtil.getLogoSrc('sticky-icon.png') : CustomIconUtil.getLogoSrc('phone-icon.png');
    });

    const getAgentCards = useCallback(() => {
        const customAgentCard = [];
        const agentList = [];
        accountPolicyData.map((policyData) => {
            const indexFound = agentList.findIndex((policy) => policy.policy_type === (CustomPolicyTypeUtil.isHOFlex(policyData.periods[0])?'Homeowners FLEX':policyData.periods[0].policyType));
            const new_policy = '#' + policyData.periods[0].policyId;
            const policy_details = { policy_number: new_policy, agent_contact: policyData.producerContact };

            if (indexFound > -1) {
                agentList[indexFound].policies.push(policy_details);
            } else {
                const temp_policy = { policy_type: CustomPolicyTypeUtil.isHOFlex(policyData.periods[0])?'Homeowners FLEX':policyData.periods[0].policyType, policies: [policy_details] };
                agentList.push(temp_policy);
            }
        });

        let isCardOpen = true;
        agentList.map((policy_agent) => {
            customAgentCard.push(<CustomStickyCardComponent agentDetails={policy_agent} openCard={isCardOpen}></CustomStickyCardComponent>);
            isCardOpen = false;
        });

        return customAgentCard;
    }, [accountPolicyData]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {

        }
    };

    useEffect(() => {
        setLoadingState(true);
        if (policySummary.data.length > 0) {
            setAccountPolicyData(policySummary.data);
            setLoadingState(false);
        }
    }, [policySummary]);

    const overrideProps = {
        amigSupportPhoneIcon: {
            src: getPhoneIcon()
        },
        amigSupportEmailIcon: {
            src: getEmailIcon()
        },
        customAgentPoliciesComponent: {
            content: getAgentCards()
        },
        phoneLink: {
            href: "tel:+1855-469-2644"
        },
        phoneLinkForPolicy: {
            href: "tel:+1855-469-2644"
        }


    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomContactUsComponent.defaultProps = {
};

CustomContactUsComponent.propTypes = {

};
export default CustomContactUsComponent;
