import React, { useContext, useState, useCallback } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { LoadingContext } from 'gw-capability-policy-react';
import { Image } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import metadata from './CustomVehicleCoveragesComponent.metadata.json5';
import messages from './CustomVehicleCoveragesComponent.messages';
import styles from './CustomVehicleCoveragesComponent.module.scss';
import { DocumentService } from "gw-capability-policydocument";
import { useAuthentication } from 'gw-digital-auth-react';
import { CustomIconUtil, CustomFormatterUtil } from 'gw-portals-util-js';

function CustomVehicleCoveragesComponent(props) {
    const translator = useContext(TranslatorContext);
    const loader = useContext(LoadingContext);
    const { value: paCoverages, policyType: policyType, policyNumber: policyNumber, policyStatus: policyStatus } = props;
    const { authHeader } = useAuthentication();
   
    const isCollectorVehicle = () => { 
        const trimmedPolicyType = policyType.replace(/\s+/g, '').toLowerCase();
        return (trimmedPolicyType  === "collectorvehicle");
    };

    const generateDeclarationPage = useCallback(() => {
        loader.setLoading(true);
        Promise.all([DocumentService.generateOnlineDocument('printDeclarationPage', policyNumber, authHeader)])
            .finally(() => { loader.setLoading(false); });
    }, []);

    const getLabel = useCallback(() => {
        let label = translator(messages.vin);

        if (!isCollectorVehicle()) {
            label = translator(messages.vinHinSerial);
        }

        return label;
    }, [translator]);

    const getDetailGridClassName = useCallback((index) => {       
        let gridClass = index === 0 ? "vehicleCoverageDetailGridTitles"   : "vehicleCoverageDetailGridTitles hideLabel";
        return gridClass;
    });

    const generateVehicleOverrides = useCallback(() => {
        const overrides = paCoverages.vehicleDTOs.map((vehicleDTO, index) => {
            return {
                idCardAvatar: {
                    src: CustomIconUtil.getLogoSrc('download.svg'),
                },
				[`vehicleDetailGridRow${index}`]: {
					columns: !isCollectorVehicle()? ["1fr", "1fr", "1fr", "1fr", "1fr"] : ["1fr", "1fr", "1fr", "1fr", "1fr", "1fr"],
				},
				[`vehicleMake${index}`]: {
					labelClassName: getDetailGridClassName(index)  },
				[`vehicleModel${index}`]: {
					labelClassName: getDetailGridClassName(index)  },
				[`vehicleYear${index}`]: {
					labelClassName: getDetailGridClassName(index)  },
				[`vehicleVIN${index}`]: {
					labelClassName: getDetailGridClassName(index),
					label: getLabel(),
				},
				[`vehicleMileagePlan${index}`]: {
					labelClassName: getDetailGridClassName(index),
					visible: isCollectorVehicle(),
                    value: vehicleDTO.mileagePlan_Ext?translator(messages.mileage,{mileage:CustomFormatterUtil.formatMeasurements(vehicleDTO.mileagePlan_Ext)}):'-'
				},
				[`vehicleStorageAddress${index}`]: {
					labelClassName: getDetailGridClassName(index) },
				[`gridSeparator${index}`]: {
					visible: paCoverages.vehicleDTOs.length - 1 !== index,
				},
			};
        });
        return Object.assign({}, ...overrides);
    }, [paCoverages]);

    const generateDriverOverrides = useCallback(() => {
        const overrides = paCoverages.driverDTOs.map((driverDTO, index) => {
            return {
                [`driverDOB${index}`]: {
                    value: CustomFormatterUtil.formatDate(new Date(
                        driverDTO.dateOfBirth.year,
                        driverDTO.dateOfBirth.month,
                        driverDTO.dateOfBirth.day
                    ))
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [paCoverages]);

    const overrides = {
        idCardAvatar: {
            src: CustomIconUtil.getLogoSrc('download.svg')
        },
        showVehicleCoveragesLink: {    
            onClick: generateDeclarationPage,
			target: "_blank",
		},
        showVehiclePolicyContainer: {
            visible: (policyStatus !== 'Expired' && policyStatus !== 'Canceled')
        },
		...generateDriverOverrides(),
		...generateVehicleOverrides(),
	};
    const resolvers = {
        resolveClassNameMap: styles,
    };

    if (loader.loading) {
      return (
        <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc("rotating-circle-blue_128.gif")} showLoader={false} />
      );
    }

    return (
            <ViewModelForm
                uiProps={metadata.componentContent}
                model={paCoverages}
                overrideProps={overrides}
                classNameMap={resolvers.resolveClassNameMap}
                callbackMap={resolvers.resolveCallbackMap}
                componentMap={resolvers.resolveComponentMap}
            />
    );
}

CustomVehicleCoveragesComponent.propTypes = {
    value: PropTypes.shape({
        coverageDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        vehicleDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        driverDTOs: PropTypes.arrayOf(PropTypes.shape({})),
        additionalLiabilityCoverages: PropTypes.arrayOf(PropTypes.shape({})),
        additionalPropertyCoverages: PropTypes.arrayOf(PropTypes.shape({}))
    }).isRequired,
    policyType: PropTypes.shape({}).isRequired
};

export default CustomVehicleCoveragesComponent;
