import _ from 'lodash';
import queryString from 'query-string';
import { getProxiedUrl, encodeFormData } from 'gw-portals-url-js';

import iframeUtil from './utils/IframeUtil/IframeUtil';
import { OAuthUtil } from './utils/OAuthUtil';
import ERRORS from './AuthErrors';

// METHODS
/**
 * If user is not logged in and iframe is loaded properly-
 * returns an XUaaCsrf value and sets a "XUaaCsrf" cookie
 *
 * @returns {Promise}
 */
async function getXUaaCsrf() {
    const iframeData = await iframeUtil.loadIframe({
        src: getProxiedUrl('login'),
    });
    /**
     * @type {HTMLInputElement}
     */
    const XUaaCsrfIframeInput = iframeData.iframeDoc.querySelector('[name="X-Uaa-Csrf"]');
    const XUaaCsrf = XUaaCsrfIframeInput.value;
    return XUaaCsrf;
}

async function sendLoginRequest(XUaaCsrf, username, password) {
    const formData = encodeFormData({
        username: username,
        password: password,
        'X-Uaa-Csrf': XUaaCsrf
    });

    const res = await fetch(getProxiedUrl('login.do'), {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: formData
    });
    const urlQueryString = queryString.extract(res.url);
    const urlQueryParams = queryString.parse(urlQueryString);
    const urlQueryError = [].concat(urlQueryParams.error).join('');
    if (_.isEmpty(urlQueryError)) {
        return res;
    }
    const loginError = {
        error: urlQueryError // reflected in the ERRORS variable
    };
    throw loginError; // trigger the Promise reject
}

/**
 * This class represent the base service used for authentication
 */
export class AuthenticationService {
    constructor(authConfig) {
        // singleton
        if (AuthenticationService.instance) {
            if (!_.isEqual(authConfig, AuthenticationService.instance.authConfig)) {
                throw new Error('Attempt to create another AuthenticationServiceFactory with a different configuration');
            }
            return AuthenticationService.instance;
        }
        AuthenticationService.instance = this;

        this.authConfig = authConfig;
        this.oAuthUtil = new OAuthUtil(authConfig);

        this.forgotPassword = this.oAuthUtil.forgotPassword;
        this.changePassword = this.oAuthUtil.changePassword;
        this.signUp = this.oAuthUtil.signUp;
        this.verifyResetCode = this.oAuthUtil.verifyResetCode;
        this.onLoginStateChange = this.oAuthUtil.addLoginStateChangeListener;
    }

    testForOAuthToken = async () => {
        await this.oAuthUtil.testForOAuthToken({ onRefreshError: this.logout });
    }

    loginWithCurrentCookies = async () => {
        await this.oAuthUtil.loginWithCurrentCookies({ onRefreshError: this.logout });
    }

    logout = async () => {
        let token;
        try {
            const { logoutMode, endpoints } = this.authConfig;
            window.location.href = endpoints.logout;
        } catch (err) {
            const { logoutMode, endpoints } = this.authConfig;
            window.location.href = endpoints.logout;
        }
    }

    prepareLogin = async () => {
        try {
            await this.oAuthUtil.prepareLogin({ onRefreshError: this.logout });
        } catch (err) {
            if (err.error !== ERRORS.notLoggedIn) {
                // notLoggedIn is a valid error as we're trying to login
                throw err;
            }
        }
    }

    login = async ({ username, password }) => {
        const csrfToken = await getXUaaCsrf();
        await sendLoginRequest(csrfToken, username, password);
        await this.loginWithCurrentCookies();
    }

    loginWithGoogle = async () => {
        try {
            // first try to retrieve a token so that we will be redirected back to the
            // correct page after authentication
            await this.testForOAuthToken();
        } catch (_e) {
            // not authenticated so load the login page and get the 'login with google' link
            const iframeData = await iframeUtil.loadIframe({
                src: '/login',
                // detect isn't redirected (not logged in already)
                expectedSrcPartOnLoad: '/login'
            });
            // go to the google authorize url
            /**
             * @type {HTMLLinkElement}
             */
            const googleLoginLink = iframeData.iframeDoc.querySelector('a[href^="https://accounts.google.com/o/oauth2"]');
            window.top.location.href = googleLoginLink.href;
        }
    }
}

// EXPORT
export default (oAuthConfig) => {
    return new AuthenticationService(oAuthConfig);
};
