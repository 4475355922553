/* eslint-disable max-len */
import React, {
    useCallback, useEffect, useState, useContext
} from 'react';
import PropTypes from 'prop-types';
import appConfig from 'app-config';
import { withRouter } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { TranslatorContext } from '@jutro/locale';
import { EnrollmentSummaryContext } from 'gw-capability-enrollment-react';
import { CustomCRMService } from 'gw-capability-profileinfo';
import { ButtonLink } from '@jutro/router';
import { CustomIconUtil } from 'gw-portals-util-js';
import { Image } from '@jutro/components';
import metadata from './CustomAccountPrefModal.metadata.json5';
import styles from './CustomAccountPrefModal.module.scss';
import messages from './CustomAccountPrefModal.messages';
import timeZoneList from '../CustomNotifications/timeZone.json';
import accountPrefTypes from './CustomAccountPrefTypes.json';
function CustomAccountPrefModal(props) {
    const translator = useContext(TranslatorContext);
    const [modalState, setModalState] = useState(false);
    const { authHeader, oid, email } = useAuthentication();
    const accountProfile = useContext(EnrollmentSummaryContext);
    const [accountPref, setAccountPref] = useState({ documentDeliveryPref: undefined, notificationPref: undefined, notificationTimezonePref: undefined });
    const accountPrefOptions = {
        paperless: 'paperless',
        usMail: 'usMail',
        textMessages: 'textMessages'
    };
    const validModalStates = {
        active: 'showModal',
        error: 'error',
        loading: 'loading',
        done: 'done'
    };
    const validChangeTypes = {
        documentDelivery: 'documentDelivery',
        timezone: 'timezone',
        notification: 'notification'
    };

    const getAccountPrefData = () => {
        if (accountProfile.data === undefined) {
            setModalState(validModalStates.error);
        }
        if (accountProfile.data && accountProfile.data.success) {
            const { preferences } = accountProfile.data.profile;
            let policyAlertsPref;
            let paperlessPolicyDocsPref;
            let contactTimezonePref;
            const policyAlerts = preferences.find((e) => e.preferenceName === accountPrefTypes.policyAlerts.code);
            if (policyAlerts) {
                policyAlertsPref = policyAlerts.preferenceValue;
            }
            const paperlessPolicyDocs = preferences.find((e) => e.preferenceName === accountPrefTypes.paperlessPolicyDocs.code);
            if (paperlessPolicyDocs) {
                paperlessPolicyDocsPref = paperlessPolicyDocs.preferenceValue === accountPrefTypes.paperlessTrue.code ? accountPrefOptions.paperless : accountPrefOptions.usMail;
            }
            const contactTimezone = preferences.find((e) => e.preferenceName === accountPrefTypes.contactTimezone.code);
            if (contactTimezone) {
                contactTimezonePref = contactTimezone.preferenceValue;
            }
            setAccountPref({
                ...accountPref,
                notificationPref: policyAlertsPref,
                documentDeliveryPref: paperlessPolicyDocsPref,
                notificationTimezonePref: contactTimezonePref
            });

            if (paperlessPolicyDocsPref === undefined) {
                setModalState(validModalStates.active);
            } else {
                setModalState(validModalStates.done);
            }
        }
    };

    const updateAccountPrefData = useCallback((changedField, change) => {
        if (changedField === validChangeTypes.documentDelivery) {
            setAccountPref({ ...accountPref, documentDeliveryPref: change });
        } else if (changedField === validChangeTypes.notification) {
            setAccountPref({ ...accountPref, notificationPref: change });
        } else if (changedField === validChangeTypes.timezone) {
            setAccountPref({ ...accountPref, notificationTimezonePref: change });
        }
    }, [accountPref]);

    const updateAccountPreferences = useCallback(() => () => {
        setModalState(validModalStates.loading);
        const accPref = [{
            preferenceName: accountPrefTypes.paperlessPolicyDocs.code,
            preferenceValue: (accountPref.documentDeliveryPref === accountPrefOptions.paperless ? accountPrefTypes.paperlessTrue.code : accountPrefTypes.paperlessFalse.code)
        }];

        if (accountPref.notificationPref !== undefined) {
            accPref.push({ preferenceName: accountPrefTypes.policyAlerts.code, preferenceValue: accountPref.notificationPref });
        }
        if (accountPref.notificationTimezonePref !== undefined && accountPref.notificationPref === accountPrefTypes.notificationOptionBoth.code) {
            accPref.push({ preferenceName: accountPrefTypes.contactTimezone.code, preferenceValue: accountPref.notificationTimezonePref });
        }

        Promise.all([
            CustomCRMService.updateAccountPreferences(authHeader, appConfig.env.CRM_CREATE_PREFERENCES_PATH, {
                ciamId: oid,
                emailAddress: email,
                preferences: accPref
            })
        ]).then((resp) => {
            if (resp[0] === undefined) {
                setModalState(validModalStates.error);
            }
            if (resp[0].preferenceUpdated === true) {
                accountProfile.data.profile.preferences = accPref;
                accountProfile.updateCall({
                    ...accountProfile.data
                });
                setModalState(validModalStates.done);
            } else {
                setModalState(validModalStates.error);
            }
        }).catch((error) => {
            if (error) {
                setModalState(validModalStates.error);
            }
        });
    }, [accountPref, authHeader, email, oid]);

    const writeFormValue = useCallback((changeType) => (newValue) => {
        let changedValue = newValue;
        if (changeType === validChangeTypes.notification) {
            switch (newValue.length) {
                case 1:
                    changedValue = accountPrefTypes.notificationOptionBoth.code;
                    break;
                default:
                    changedValue = accountPrefTypes.notificationOptionEmail.code;
                    break;
            }
        }
        updateAccountPrefData(changeType, changedValue);
    }, [accountPref]);

    const getDocDeliveryPref = useCallback(() => {
        return accountPref.documentDeliveryPref;
    }, [accountPref]);

    const getNotificationPref = useCallback(() => {
        if (accountPref.notificationPref !== undefined) {
            switch (accountPref.notificationPref) {
                case accountPrefTypes.notificationOptionSms.code:
                case accountPrefTypes.notificationOptionBoth.code:
                    return ([accountPrefOptions.textMessages]);

                default:
                    return ([]);
            }
        }
        return undefined;
    }, [accountPref]);

    const getTimezonePref = useCallback(() => {
        return accountPref.notificationTimezonePref;
    }, [accountPref]);

    const getErrorBody = useCallback(() => {
        return (
            <div className="error-page-overlay">
                <div className={styles.modalError}>
                    <img alt="missing-page" className="modal-error-image" />
                    <h1 className={styles.errorHeader}>{translator(messages.modalErrorMessageHeader)}</h1>
                    <div className={styles.modalErrorMessage}>
                        {translator(messages.modalErrorMessageContent)}
                    </div>
                    <ButtonLink className={styles.modalButton} href="/home">{translator(messages.modalErrorReturnButton)}</ButtonLink>
                </div>
            </div>
        );
    }, []);

    const getLoader = useCallback(() => {
        return (
            <Image className={styles.spinner} src={CustomIconUtil.getLogoSrc('rotating-circle-blue_128.gif')} showLoader={false} />
        );
    }, []);

    useEffect(() => {
        if (modalState !== validModalStates.done) { getAccountPrefData(accountProfile); }
    }, [accountProfile]);

    const overrideProps = {
        modalLoader: {
            content: (modalState === validModalStates.loading) ? getLoader() : null
        },
        disabledModalBg: {
            visible: (modalState === validModalStates.active || modalState === validModalStates.error || modalState === validModalStates.loading) && modalState !== validModalStates.done
        },
        accountPrefModalContainer: {
            visible: (modalState === validModalStates.active || modalState === validModalStates.error || modalState === validModalStates.loading) && modalState !== validModalStates.done
        },
        accountPrefModalContent: {
            visible: (modalState !== validModalStates.loading && modalState !== validModalStates.error && !props.modalError)
        },
        accountPrefError: {
            visible: props.modalError || modalState === validModalStates.error,
            content: getErrorBody()
        },
        submitPrefButton: {
            disabled: (getDocDeliveryPref() === undefined || (accountPref.notificationPref === accountPrefTypes.notificationOptionBoth.code && accountPref.notificationTimezonePref === undefined)),
            onClick: updateAccountPreferences()
        },
        typeaheadmultiselect: {
            availableValues: timeZoneList,
            value: getTimezonePref(),
            onValueChange: writeFormValue(validChangeTypes.timezone),
            className: getTimezonePref() !== undefined ? 'timeZoneSelector timezoneBorder' : 'timeZoneSelector',
            showErrors: getTimezonePref() === undefined
        },
        policyDeliveryMethod: {
            value: getDocDeliveryPref(),
            onValueChange: writeFormValue(validChangeTypes.documentDelivery)
        },
        billingDeliveryMethod: {
            value: getNotificationPref(),
            onValueChange: writeFormValue(validChangeTypes.notification)
        },
        timeZoneContainer: {
            visible: (accountPref.notificationPref === accountPrefTypes.notificationOptionSms.code || accountPref.notificationPref === accountPrefTypes.notificationOptionBoth.code)
        },
        termsAndConditionsLink: {
            href: appConfig.env.PAPERLESS_TERMS_CONDITIONS_URL,
            target: '_blank'
        },
        termsofUseLink: {
            href: appConfig.env.NOTIFICATION_TERMS_OF_USE_URL,
            target: '_blank'
        }

    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomAccountPrefModal.propTypes = {
    authHeader: PropTypes.shape({}).isRequired
};

export default withAuthenticationContext(withRouter(CustomAccountPrefModal));
