import { defineMessages } from 'react-intl';

export default defineMessages({
    claimStatus: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Status',
        defaultMessage: 'Status'
    },
    claimStatusReceived: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Received',
        defaultMessage: 'Received'
    },
    claimReceivedText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Received Sub Text',
        defaultMessage: 'We have received your inspection report. Please allow 5 business days for us to review and contact you. Please feel free to reach out to your adjuster directly should you have additional questions. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimAssignedText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Assigned Sub Text',
        defaultMessage: 'Please allow 2-3 business days for a claim representative to contact you.'
    },
    claimUnderReviewText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Under Review Sub Text',
        defaultMessage: 'Your Adjuster is in the process of reviewing your claim. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimLossInspectionScheduledText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Loss Inspection Scheduled Sub Text',
        defaultMessage: 'The inspector will contact you within 2 business days to schedule the appointment. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimInspectionReportText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Waiting On Inspection Report Sub Text',
        defaultMessage: 'Your inspection has been performed; we await receipt of the report. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimInspectionReportReceivedText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Inspection Report Received Sub Text',
        defaultMessage: 'We have received your inspection report. Please allow 5 business days for us to review and contact you. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimPaidAndOpenText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Paid And Open Sub Text',
        defaultMessage: 'Your claim payment has been issued. Please allow 2-3 business days after the issued date for receipt. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimPaidAndClosedText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Paid And Closed Sub Text',
        defaultMessage: 'Your claim payment has been issued and your file is closed. Please allow 7-10 business days after the issued date for receipt. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    claimClosedText: {
        id: 'gw.capability.claim.customClaimStatusCardComponent.Claim Closed Sub Text',
        defaultMessage: 'Your claim has been closed. Please feel free to reach out to your adjuster directly should you have additional questions.'
    },
    noPaymentDataText: {
        id: 'claim.card.noPaymentDataText',
        defaultMessage: 'There are no payments yet.'
    },
    address: {
        id: 'claim.card.Address',
        defaultMessage: 'Address'
    },
    modelVin: {
        id: 'claim.card.Model Vin',
        defaultMessage: 'Model/VIN'
    }
});
