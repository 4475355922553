import { useContext, useCallback } from 'react';
import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { CustomIconUtil } from 'gw-portals-util-js';
import metadata from './CustomPaymentHistoryModal.metadata.json5';
import styles from './CustomPaymentHistoryModal.module.scss';
import messages from './CustomPaymentHistoryModal.messages';
import { EnrollmentSummaryContext } from 'gw-capability-enrollment-react';

function CustomPaymentHistoryModal(props) {
    const prefSummary = useContext(EnrollmentSummaryContext);
    const { modalPaymentDetails } = props;

    function printPaymentDetails() {
        document.body.classList.add(styles.printPage);
        window.print();
        document.body.classList.remove(styles.printPage);
    }

    const getNamedInsured = useCallback(() => {
        if (prefSummary) {
            const prefSummaryProfile = prefSummary.data.profile;
            const nullCheckPNI = (prefSummaryProfile.enrollments[0].primaryNamedInsured !== null);
            const primaryNamedInsured = nullCheckPNI ? prefSummaryProfile.enrollments[0].primaryNamedInsured
                : (`${prefSummaryProfile.contact.firstName} ${prefSummaryProfile.contact.lastName}`);
            return primaryNamedInsured;
        }
        return null;
    }, [prefSummary]);

    const overrideProps = {
        receiptNumberData: {
            content: modalPaymentDetails.paymentReceipt
        },
        policyNumberData: {
            content: modalPaymentDetails.policyNumber
        },
        namedInsuredData: {
            content: getNamedInsured()
        },
        policyTermData: {
            content: modalPaymentDetails.policyTerm
        },
        paymentMethodData: {
            content: modalPaymentDetails.paymentMethod
        },
        paymentDateData: {
            content: modalPaymentDetails.paymentDate
        },
        paymentAmountData: {
            content: modalPaymentDetails.paymentAmt
        },
        dismissPaymentModal: {
            src: CustomIconUtil.getLogoSrc('dismiss-icon.png'),
            onClick: props.dismissModal
        },
        printPaymentDetails: {
            onClick: printPaymentDetails
        },
        amigPrintLogo: {
            src: CustomIconUtil.getLogoSrc('AMIG-4ColorLogo.png'),
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return renderContentFromMetadata(
        metadata.componentContent,
        overrideProps,
        resolvers
    );
}

CustomPaymentHistoryModal.propTypes = {};

export default CustomPaymentHistoryModal;
