import { useContext } from 'react';
import PropTypes from 'prop-types';
import { TranslatorContext } from '@jutro/locale';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import metadata from './AuthMessageComponent.metadata.json5';
import messages from './AuthMessageComponent.messages';
import styles from './AuthMessageComponent.modules.scss';

function AuthErrorComponent(props) {
    const translator = useContext(TranslatorContext);
    const {
        linkTo, header, linkText, onCallback, description
    } = props;

    const overrides = {
        authErrorHeader: {
            content: translator(header)
        },
        authErrorDescription: {
            content: translator(description)
        },
        authErrorLink: {
            content: linkText,
            to: linkTo,
            onClick: onCallback
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            handleCallback: onCallback
        }
    };

    return renderContentFromMetadata(metadata.componentContent, overrides, resolvers);
}

AuthErrorComponent.propTypes = {
    header: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    description: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    linkText: PropTypes.shape({
        id: PropTypes.string,
        defaultMessage: PropTypes.string
    }),
    linkTo: PropTypes.string,
    onCallback: PropTypes.func
};

AuthErrorComponent.defaultProps = {
    header: undefined,
    description: undefined,
    linkText: messages.backToSignIn,
    onCallback: undefined,
    linkTo: '/login-page'
};

export default AuthErrorComponent;
