import { getProxiedServiceUrl } from 'gw-portals-url-js';
import { DocumentDownloadService } from 'gw-portals-document-js';

export default class DocumentService {
    /**
     * Retrieves a document link.
     *
     * @param {String} documentID the documents id to retrieve
     * @param {String} token the security token
     */

    static downloadPolicyDocument(documentID, token) {
        return DocumentDownloadService.getDocumentLink(getProxiedServiceUrl('policyDocument'), documentID, token);
    }

    static generateOnlineDocument(action, policyNumber, header) {     
      return DocumentDownloadService.getDocument(getProxiedServiceUrl('onlineDocument' ) + '/?action=' + action + '&policyNumber=' + policyNumber, header);
    }  
    
}
