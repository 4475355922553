import {
    defineMessages
} from 'react-intl';

export default defineMessages({

    DownloadIDCard: {
        id: 'policy.view.policy-details.Download ID Card',
        defaultMessage: 'Download ID Card'
    },
    policyNumberValue: {
        id: 'policy.detail.#{id}',
        defaultMessage: '#{id}'
    },
    yourAgent: {
        id: 'policy.view.policy-details.Agent',
        defaultMessage: 'Your agent'
    },
    policyCoveragesTitle: {
        id: "policyDetails.component.policy-vehicle-info.Property Details",
        defaultMessage: "Property details"
    },
    policycontactTitle: {
        id: "policyDetails.component.policy-contacts",
        defaultMessage: "Policy contacts"
    },
    policyCoveredDriversTitle: {
        id: "policyDetails.component.policy-covered-drivers",
        defaultMessage: "Covered drivers"
    },
    custompolicydetailsTitle: {
        id: "policyDetails.component.policy-details",
        defaultMessage: "Details"
    },
    policyDocumentsTitle: {
        id: "policyDetails.component.policy-documents",
        defaultMessage: "Documents"
    }
});