import React, {
    useCallback, useState, useEffect, useMemo
} from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { renderContentFromMetadata, validateContentFromMetadata } from '@jutro/uiconfig';

import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
import { useAuthentication } from 'gw-digital-auth-react';

import metadata from './CreateNote.metadata.json5';
import styles from './CreateNote.module.scss';

function CreateNote(props) {
    const { authHeader } = useAuthentication();
    const { ClaimService } = useDependencies('ClaimService');
    const [note, updateNote] = useState({
        subject: '',
        body: ''
    });
    const { match, history } = props;
    const { isComponentValid, registerComponentValidation } = useValidation('CreateNote');

    const writeValue = useCallback(
        (value, path) => {
            const clonsedNote = _.clone(note);
            _.set(clonsedNote, path, value);
            updateNote(clonsedNote);
        },
        [note]
    );

    const redirectToPreviousState = useCallback(() => {
        const {
            location: {
                state: { redirectPath }
            }
        } = history;
        const nextPath = `/claims/${match.params.claimNumber}`;
        history.push({
            pathname: nextPath,
            state: {
                redirectPath,
                selectedTab: 'notesTab'
            }
        });
    }, [history, match]);

    const handleSave = useCallback(() => {
        return ClaimService.createClaimNote(match.params.claimNumber, note, authHeader).then(() => {
            redirectToPreviousState();
        });
    }, [ClaimService, authHeader, match, note, redirectToPreviousState]);

    const onBackClicked = useCallback(
        (e) => {
            e.preventDefault();
            redirectToPreviousState();
        },
        [redirectToPreviousState]
    );

    const readValue = useCallback(
        (id, path) => {
            return _.get(note, path);
        },
        [note]
    );

    const overrides = useMemo(
        () => ({
            '@field': {
                onValueChange: writeValue
            },
            saveBtnId: {
                disabled: !isComponentValid
            }
        }),
        [isComponentValid, writeValue]
    );

    const resolvers = useMemo(
        () => ({
            resolveValue: readValue,
            resolveCallbackMap: {
                handleSave: handleSave,
                handleCancel: redirectToPreviousState,
                onBackClicked: onBackClicked
            },
            resolveClassNameMap: styles
        }),
        [handleSave, onBackClicked, readValue, redirectToPreviousState]
    );

    const validateForm = useCallback(() => {
        return validateContentFromMetadata(metadata.pageContent, overrides, resolvers);
    }, [overrides, resolvers]);

    useEffect(() => {
        registerComponentValidation(validateForm);
    }, [registerComponentValidation, validateForm]);

    return (
        <div className={styles.createNote}>
            {renderContentFromMetadata(metadata.pageContent, overrides, resolvers)}
        </div>
    );
}

CreateNote.propTypes = {
    location: PropTypes.shape({
        state: PropTypes.shape({
            summarystate: PropTypes.shape({}).isRequired
        }).isRequired
    }).isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            claimNumber: PropTypes.string
        })
    }).isRequired,
    history: PropTypes.shape({
        push: PropTypes.func,
        location: PropTypes.shape({
            state: PropTypes.shape({
                redirectPath: PropTypes.string
            })
        })
    }).isRequired
};
export const CreateNoteComponent = CreateNote;
export default withRouter(CreateNote);
