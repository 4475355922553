import { defineMessages } from 'react-intl';

export default defineMessages({
    myPolicies: {
        id: 'amig.links.My Policies',
        defaultMessage: 'My policies'
    },
    billingAndPayment: {
        id: 'amig.links.Billing and payment',
        defaultMessage: 'Billing and payment'
    },
    claims: {
        id: 'amig.links.My claims',
        defaultMessage: 'My claims'
    },
    getHelp: {
        id: 'amig.links.Get help',
        defaultMessage: 'Get help'
    },
    accountPreferences: {
        id: 'amig.links.Account Preferences',
        defaultMessage: 'Account preferences'
    },
    logout: {
        id: 'amig.links.Logout',
        defaultMessage: 'Log out'
    }
});
