import { defineMessages } from 'react-intl';

export default defineMessages({
    hideVehicleCoverages: {
        id: 'claim.directives.pa-vehicle-coverages.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'claim.directives.pa-vehicle-coverages.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    coverage: {
        id: 'claim.directives.pa-vehicle-coverages.Coverage',
        defaultMessage: 'Coverage'
    },
    coveragesFor: {
        id: 'claim.directives.policy-level-coverages.Coverages for',
        defaultMessage: 'Coverages for {vehicleMake} {vehicleModel}'
    },
});
