import { useContext, useCallback, useEffect, useState } from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { withRouter } from 'react-router-dom';
import { BreakpointTrackerContext } from '@jutro/layout';
import { CustomClaimCard } from 'gw-capability-claim-react';
import { LoadingContext, CustomPolicySummaryContext } from 'gw-capability-policy-react';
import metadata from './CustomClaimCardList.metadata.json5';
import styles from './CustomClaimCardList.module.scss';
import appConfig from 'app-config';

function CustomClaimCardList() {
    const breakpoint = useContext(BreakpointTrackerContext);
    const [accountPolicyData, setAccountPolicyData] = useState([]);
    const [activeCardId, setActiveCardId] = useState('default');
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const policySummary = useContext(CustomPolicySummaryContext);
    const loader = useContext(LoadingContext);

    useEffect(() => {
        loader.setLoading(true);
        if (policySummary.data.length > 0) {
            setAccountPolicyData(policySummary.data);
            window.scroll(0, 0);
            loader.setLoading(false);
        }
    }, [policySummary]);

    const generateRowOverrides = useCallback(() => {
        const overrides = policySummary.data.map((row, index) => {
            return {
				[`ClaimCardComponent${index}`]: {
					activeCard: activeCardId,
                    setActiveCard: setActiveCardId,
                    cardId: 'claimCard'+index
				},
			};
        });
        return Object.assign({}, ...overrides);
    }, [policySummary, activeCardId]);

    const overrides = {
        pageBreadcrumb: {
            visible: isMobile
        },
        fileAClaimButton: {
            href: appConfig.env.FNOL_URL,
            target: '_blank',
            visible: !isMobile
        },
        fileAClaimButtonMobile: {
            href: appConfig.env.FNOL_URL,
            target: '_blank'
        },
        claimCardComponentPanel: {
            data: accountPolicyData
        },
        desktopFileAClaimContainer: {
            className: isMobile ? styles.mobileClaimDiv : styles.desktopClaimDiv
        },
        ...generateRowOverrides()
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            CustomClaimCard
        }
    };

    return renderContentFromMetadata(metadata.pageContent, overrides, resolvers);
}

export default withRouter(CustomClaimCardList);
