import { defineMessages } from 'react-intl';

export default defineMessages({
    quoteRenewalEffectiveDate: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Renewal Effective Date',
        defaultMessage: 'Renewal Effective Date'
    },
    quoteQuoteTax: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.(Tax)',
        defaultMessage: '(Tax)'
    },
    quoteIncreasePremium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.There is an increase in the premium',
        defaultMessage: 'There is an increase in the premium'
    },
    quoteTotalPremiumTaxFees: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Total Premium + Taxes and Fees (Tax)',
        defaultMessage: '{newPremiumBeforeTaxes} + {taxes} (Tax)'
    },
    quoteSaveExit: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Save and Exit',
        defaultMessage: 'Save and Exit'
    },
    quoteDecreasePremium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.There is a decrease in the premium',
        defaultMessage: 'There is a decrease in the premium'
    },
    quoteNoChange: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.There are no changes to the premium.',
        defaultMessage: 'There are no changes to the premium.'
    },
    quoteDontAffectPremium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.The changes youve made to the policy dont affect the premium.',
        defaultMessage: 'The changes you\'ve made to the policy don\'t affect the premium.'
    },
    quoteNewPremiumValueIncrease: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.The new premium is now value. This is an increase of value on the current premium and will be reflected in upcoming invoices.',
        defaultMessage: 'The new premium is now {newPremium}. This is an increase of {changeInCost} on the current premium and will be reflected in upcoming invoices.'
    },
    quoteValuePercentage: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.(value%)',
        defaultMessage: '({value}%)'
    },
    quoteNewPremiumValueDecrease: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.The new premium is now value. That is value less than the current premium and will be reflected in upcoming invoices.',
        defaultMessage: 'The new premium is now {newPremium}. That is {changeInCost} less than the current premium and will be reflected in upcoming invoices.'
    },
    quoteIncrease: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.increase',
        defaultMessage: '+{val}'
    },
    quoteDecrease: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.decrease',
        defaultMessage: '{val}'
    },
    currentPremium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Current Premium',
        defaultMessage: 'Current Premium'
    },
    changeInCost: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Change in Cost',
        defaultMessage: 'Change in Cost'
    },
    newPremium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.New Premium',
        defaultMessage: 'New Premium'
    },
    policyPeriod: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Policy Period',
        defaultMessage: 'Policy Period'
    },
    premium: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Premium',
        defaultMessage: 'Premium'
    },
    effectiveDate: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    }
});
