import { defineMessages } from 'react-intl';

export default defineMessages({
    accountInformation: {
        id: 'usersprofile.views.amp-users-profile.Account Information',
        defaultMessage: 'Account Information'
    },
    issueSavingDetails: {
        id: 'usersprofile.views.amp-users-profile.Issue saving details, please try again',
        defaultMessage: 'Issue saving details, please try again'
    },
    accountDetails: {
        id: 'usersprofile.views.amp-users-profile.Account Details',
        defaultMessage: 'Account Details'
    },
    accountTitleWithInForcePolicies: {
        id: 'usersprofile.views.amp-users-profile.Account Details:',
        defaultMessage: 'Account Details: {accountName} ({accountNumber})'
    },
    accountTitleWithNoInForcePolicies: {
        id: 'usersprofile.views.amp-users-profile.Below are the details of the account that has no active policies',
        defaultMessage: 'Below are the details of the account that has no active policies'
    },
    billingDetails: {
        id: 'usersprofile.views.amp-users-profile.Billing Details',
        defaultMessage: 'Billing Details'
    },
    billingEqualAddress: {
        id: 'usersprofile.views.amp-users-profile.Is your Billing Address the same as the one above?',
        defaultMessage: 'Is your Billing Address the same as the one above?'
    },
    billingIsTheSame: {
        id: 'usersprofile.views.amp-users-profile.Your billing address is the same.',
        defaultMessage: 'Your billing address is the same.'
    },
    billingDelivery: {
        id: 'usersprofile.views.amp-users-profile.Billing Delivery',
        defaultMessage: 'Billing Delivery'
    },
    deliveryMethod: {
        id: 'usersprofile.views.amp-users-profile.Delivery Method',
        defaultMessage: 'Delivery Method'
    },
    sentByMail: {
        id: 'usersprofile.views.amp-users-profile.Sent in the mail',
        defaultMessage: 'Sent in the mail'
    },
    sentByEmail: {
        id: 'usersprofile.views.amp-users-profile.Sent by email',
        defaultMessage: 'Sent by email'
    },
    applyToAllAccountsTitle: {
        id: 'usersprofile.views.amp-users-profile.Apply Contact Details to Other Policies',
        defaultMessage: 'Apply Contact Details to Other Policies'
    },
    applyToAllAccounts: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.Do you want to use these contact details for policies that belong to other accounts?',
        defaultMessage: 'Do you want to use these contact details for policies that belong to other accounts?'
    },
    applyToAllAccountsYes: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.Yes',
        defaultMessage: 'Yes'
    },
    applyToAllAccountsNo: {
        id: 'usersprofile.directives.templates.user-details-apply-to-all.No',
        defaultMessage: 'No'
    },
    cancel: {
        id: 'usersprofile.views.amp-users-profile.Cancel',
        defaultMessage: 'Cancel'
    },
    save: {
        id: 'usersprofile.views.amp-users-profile.Save',
        defaultMessage: 'Save'
    },
    cancelEditTitle: {
        id: 'usersprofile.views.amp-users-profile.Cancel contact information update',
        defaultMessage: 'Cancel contact information update'
    },
    cancelEditMessage: {
        id: 'usersprofile.views.amp-users-profile.Are you sure you want to cancel your account contact profile changes?',
        defaultMessage: 'Are you sure you want to cancel your account contact profile changes?'
    },
    inValidPaperlessTitle: {
        id: 'usersprofile.views.amp-users-profile.Invalid Paperless Billing Settings',
        defaultMessage: 'Invalid Paperless Billing Settings'
    },
    inValidPaperlessMessage: {
        id: 'usersprofile.views.amp-users-profile.A valid email address is required for paperless billing',
        defaultMessage: 'A valid email address is required for paperless billing'
    },
    accountSettings: {
        id: 'usersprofile.views.amp-users-profile.Account Settings',
        defaultMessage: 'Account Settings'
    },
    accountLanguage: {
        id: 'usersprofile.views.amp-users-profile.Language',
        defaultMessage: 'Language'
    }
});
