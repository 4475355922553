/* eslint-disable max-len */
import React, {
    useContext
} from 'react';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { CustomPolicyTypeUtil, CustomFormatterUtil, CustomTooltipUtil } from 'gw-portals-util-js';
import { BreakpointTrackerContext } from '@jutro/layout';
import appConfig from 'app-config';
import metadata from './CustomBillingDetails.metadata.json5';
import styles from './CustomBillingDetails.module.scss';
import messages from './CustomBillingDetails.messages';

function CustomBillingDetails(props) {
    const { selectedPolicy } = props;
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobileLandscape = (breakpoint === 'tablet');
    
    const emptyString = '';

    const isAmountZero = (policyAmount) => {
        if (policyAmount !== null && policyAmount.amount === 0) {
            return true;
        }
        return false;
    };

    const isPolicyStatusInForce = (policy) => {
        return policy.status === 'In Force';
    };

    const isPolicyStatusCanceled = (policy) => {
        return policy.status === 'Canceled';
    };

    const isPolicyAgencyBill = (policy) => {
        return policy.billingMethod_Ext === 'Agency Bill';
    };

    const isPolicyStatusScheduled = (policy) => {
        return policy.status === 'Scheduled';
    };

    const isPolicyStatusExpired = (policy) => {
        return policy.status === 'Expired';
    };

    const isPaymentPastDue = (policy) => {
        return (policy.isPaymentPastDue_Ext && !isPolicyAgencyBill(policy));
    };

    const isPolicyExpiredOrPermanentCanceled = (policy) => {
        return !isPolicyAgencyBill(policy) && (isPolicyStatusExpired(policy) || (isPolicyStatusCanceled(policy) && !policy.canMakeAPayment_Ext));
    };

    const getPaymentDue = (policy) => {
        if (!(isPaymentPastDue(policy) && isPolicyStatusCanceled(policy))) {
            if (isAmountZero(policy.nextPaymentDue_Ext)) {
                return '-';
            }
            return translator(messages.nextPaymentDueValue, {
                nextPaymentAmt: CustomFormatterUtil.formatAmount(policy.nextPaymentDue_Ext.amount),
                nextPaymentAmtDate:
                policy.nextBillDueDate_Ext !== null
                    ? CustomFormatterUtil.formatAccompanyingDate(policy.nextBillDueDate_Ext)
                    : emptyString,
            });
        }
        return CustomFormatterUtil.formatAmount(policy.pastDueAmount_Ext.amount);
    };

    const getCurrentPaymentDue = (policy) => {
            if (isAmountZero(policy.currentPaymentDue_Ext)) {
                return '-';
            }
            return translator(messages.nextPaymentDueValue, {
                nextPaymentAmt: CustomFormatterUtil.formatAmount(policy.currentPaymentDue_Ext.amount),
                nextPaymentAmtDate:
                    policy.currentBillDueDate_Ext !== null
                        ? CustomFormatterUtil.formatAccompanyingDate(policy.currentBillDueDate_Ext)
                        : emptyString,
            });
    };

    const getPolicyStatus = (policy) => {
        if (isPolicyStatusInForce(policy)) {
            return translator(messages.statusActive);
        }
        return policy.status;
    };

    const overrideProps = {
        noPolicyNotification: {
            visible: selectedPolicy.policyId === null
        },
        policyDetailsContainer: {
            visible: selectedPolicy.policyId !== null
        },
        paymentPastDueAmount: {
            content: isPaymentPastDue(selectedPolicy) ? CustomFormatterUtil.formatAmount(selectedPolicy.pastDueAmount_Ext.amount) : '-'
        },
        mobilePaymentPastDueAmount: {
            content: isPaymentPastDue(selectedPolicy) ? CustomFormatterUtil.formatAmount(selectedPolicy.pastDueAmount_Ext.amount) : '-'
        },
        policyType: {
            content: CustomPolicyTypeUtil.getCustomPolicyTitle(selectedPolicy)
        },
        policyNumber: {
            content: selectedPolicy.policyId
        },
        policyEffectiveDate: {
            value: CustomFormatterUtil.formatDateRange(selectedPolicy.effective,
                selectedPolicy.expiration)
        },
        lastPaymentReceived: {
            value: isAmountZero(selectedPolicy.lastPaymentReceivedAmount_Ext)
                ? '-'
                : translator(messages.lastPaymentReceivedValue, {
                    lastPaymentReceivedAmt: CustomFormatterUtil.formatAmount(selectedPolicy.lastPaymentReceivedAmount_Ext.amount),
                    lastPaymentReceivedAmtDate:
                        selectedPolicy.lastPaymentReceivedDate_Ext !== null
                            ? CustomFormatterUtil.formatAccompanyingDate(selectedPolicy.lastPaymentReceivedDate_Ext)
                            : emptyString,
                }),
            visible: !isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        totalAmountReceived: {
            visible: isPolicyStatusInForce(selectedPolicy) && !isPolicyAgencyBill(selectedPolicy),
            value: (selectedPolicy.totalAmountReceived_Ext) ? CustomFormatterUtil.formatAmount(selectedPolicy.totalAmountReceived_Ext.amount) : emptyString
        },
        scheduledTotalAmountReceived: {
            visible: isPolicyStatusScheduled(selectedPolicy) && !isPolicyAgencyBill(selectedPolicy),
            value: (selectedPolicy.totalAmountReceived_Ext) ? CustomFormatterUtil.formatAmount(selectedPolicy.totalAmountReceived_Ext.amount) : emptyString
        },
        policyStatus: {
            value: getPolicyStatus(selectedPolicy)
        },
        paymentDue: {
            label: (isPolicyStatusScheduled(selectedPolicy))?translator(messages.nextPaymentDue):translator(messages.currentPaymentDue),
            value: (isPolicyStatusScheduled(selectedPolicy))? getPaymentDue(selectedPolicy):getCurrentPaymentDue(selectedPolicy),
            className:  'policy-card-input-data',
            visible: (isPolicyStatusInForce(selectedPolicy) || (isPolicyStatusScheduled(selectedPolicy))) &&  !isPolicyAgencyBill(selectedPolicy)
        },
        activeNextPaymentDue: {           
            label: (isPolicyStatusCanceled(selectedPolicy) && isPaymentPastDue(selectedPolicy)) ? translator(messages.paymentPastDue) : translator(messages.nextPaymentDue),
            value: getPaymentDue(selectedPolicy),
            className: (isPaymentPastDue(selectedPolicy) && isPolicyStatusCanceled(selectedPolicy)) ? 'policy-card-payment-overdue' : 'policy-card-input-data',
            visible: !isPolicyExpiredOrPermanentCanceled(selectedPolicy) && isPolicyStatusInForce(selectedPolicy) && !isMobileLandscape &&  !isPolicyAgencyBill(selectedPolicy)
        },
        activeLandscapeNextPaymentDue: {           
            value: getPaymentDue(selectedPolicy),
            className: 'policy-card-input-data',
            visible: !isPolicyExpiredOrPermanentCanceled(selectedPolicy) && isPolicyStatusInForce(selectedPolicy) && isMobileLandscape
        },
        totalPremium: {
            visible: !isPolicyStatusInForce(selectedPolicy) || isPolicyAgencyBill(selectedPolicy) || isMobileLandscape,
            value: CustomFormatterUtil.formatAmount(selectedPolicy.premium.amount),
        },
        activeTotalPremium: {
            visible: isPolicyStatusInForce(selectedPolicy) && !isMobileLandscape,
            value: CustomFormatterUtil.formatAmount(selectedPolicy.premium.amount),
        },
        paidThruDate: {
            visible: (!(isPolicyStatusInForce(selectedPolicy) || isPolicyStatusScheduled(selectedPolicy)) && !isPolicyAgencyBill(selectedPolicy)),
            value: CustomFormatterUtil.formatDate(selectedPolicy.paidThroughDate_Ext)
        },
        cancellationReason: {
            visible: (isPolicyStatusCanceled(selectedPolicy) && !selectedPolicy.canMakeAPayment_Ext),
            value: selectedPolicy.cancellationReason_Ext
        },
        cancellationDate: {
            visible: !(isPolicyStatusInForce(selectedPolicy) || isPolicyStatusScheduled(selectedPolicy) || isPolicyStatusExpired(selectedPolicy)),
            value: CustomFormatterUtil.formatDate(selectedPolicy.cancellationEffectiveDate_Ext)
        },
        activationNumber: {
            content: (selectedPolicy.activationCode_Ext) ? selectedPolicy.activationCode_Ext : '-',
            visible: !(isPolicyAgencyBill(selectedPolicy) || isPolicyStatusCanceled(selectedPolicy) || isPolicyExpiredOrPermanentCanceled(selectedPolicy))
        },
        activationNumberLabel: {
            visible: !(isPolicyAgencyBill(selectedPolicy) || isPolicyStatusCanceled(selectedPolicy) || isPolicyExpiredOrPermanentCanceled(selectedPolicy))
        },
        copyActivationCodeContainer: {
            visible: !(isPolicyAgencyBill(selectedPolicy) || isPolicyStatusCanceled(selectedPolicy)),
            content: (selectedPolicy.activationCode_Ext) ? CustomTooltipUtil.getTooltip(selectedPolicy.activationCode_Ext) : emptyString
        },
        copyContentContainer: {
            content: (selectedPolicy.policyId) ? CustomTooltipUtil.getTooltip(selectedPolicy.policyId) : emptyString
        },
        policyPaymentPlan: {
            value: selectedPolicy.paymentPlan_Ext,
            visible: (isPolicyStatusInForce(selectedPolicy) || isPolicyExpiredOrPermanentCanceled(selectedPolicy)) && !(isPolicyStatusScheduled(selectedPolicy)),
        },
        scheduledPolicyPaymentPlan: {
            value: selectedPolicy.paymentPlan_Ext,
            visible: isPolicyStatusScheduled(selectedPolicy)
        },
        billingType: {
            value: selectedPolicy.billingType_Ext,
            visible: isPolicyStatusInForce(selectedPolicy) || isPolicyStatusScheduled(selectedPolicy),
        },
        cancelExpBillingType: {
            value: selectedPolicy.billingType_Ext,
            visible: isPolicyExpiredOrPermanentCanceled(selectedPolicy),
        },
        remainingBalance: {
            visible: isPolicyStatusInForce(selectedPolicy) && !isPolicyAgencyBill(selectedPolicy),
            value: isAmountZero(selectedPolicy.remainingPremium_Ext.amount)
                ? '-' : CustomFormatterUtil.formatAmount(selectedPolicy.remainingPremium_Ext.amount)
        },
        totalRemainingBalance: {
            visible: !(isPolicyStatusInForce(selectedPolicy) || isPolicyStatusScheduled(selectedPolicy) || isPolicyExpiredOrPermanentCanceled(selectedPolicy)),
            value: isAmountZero(selectedPolicy.remainingPremium_Ext.amount)
                ? '-' : CustomFormatterUtil.formatAmount(selectedPolicy.remainingPremium_Ext.amount)
        },
        billingMethod: {
            value: selectedPolicy.billingMethod_Ext,
            visible: isPolicyAgencyBill(selectedPolicy) || isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        policyPaymentCol: {
            visible: !isPolicyAgencyBill(selectedPolicy) && !isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        policyContactCol: {
            visible: !isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        policyBillingCol: {
            visible: !isPolicyAgencyBill(selectedPolicy) && !isPolicyStatusExpired(selectedPolicy)
        },
        policyActivationCol: {
            visible: !isPolicyAgencyBill(selectedPolicy) && !(isPolicyExpiredOrPermanentCanceled(selectedPolicy) && (breakpoint === 'phone' || breakpoint === 'phoneWide' || breakpoint === 'tablet'))
        },
        permanentCancelExpiredBillingCol: {
            visible: isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        mobileCancelExpPolicyPaymentPlan: {
            visible: isPolicyExpiredOrPermanentCanceled(selectedPolicy) && (breakpoint === 'phone' || breakpoint === 'phoneWide' || breakpoint === 'tablet'),
            value: selectedPolicy.paymentPlan_Ext
        },
        tabletCol2: {
            visible: !isPolicyStatusExpired(selectedPolicy)
        },
        agencyBillNotification: {
            visible: isPolicyAgencyBill(selectedPolicy)
        },
        canceledExpiredNotification: {
            visible: isPolicyExpiredOrPermanentCanceled(selectedPolicy)
        },
        makeAPaymentButtonCurrent: {
            to: `/choose-payment/${selectedPolicy.policyId}?policyType=${selectedPolicy.policyType}&isHOFlex=${CustomPolicyTypeUtil.isHOFlex(selectedPolicy)}`,
            visible: isPolicyStatusScheduled(selectedPolicy) || (((!isPolicyAgencyBill(selectedPolicy) && !isPolicyExpiredOrPermanentCanceled(selectedPolicy))) && (!(isAmountZero(selectedPolicy.currentPaymentDue_Ext))))
        },
        makeAPaymentButtonNext: {
            to: `/choose-payment/${selectedPolicy.policyId}?policyType=${selectedPolicy.policyType}&isHOFlex=${CustomPolicyTypeUtil.isHOFlex(selectedPolicy)}`,
            visible: (!isPolicyAgencyBill(selectedPolicy) && !isPolicyStatusScheduled(selectedPolicy) && !isPolicyExpiredOrPermanentCanceled(selectedPolicy) && (isAmountZero(selectedPolicy.currentPaymentDue_Ext)) && !isMobileLandscape)
        },
        landscapeMakeAPaymentButtonNext: {
            /*Bug#1443472 - MaP-Choose your payment method screen - Exit button does not navigate back to prior screen for landscape mode for Payment due Next column*/
            to: `/choose-payment/${selectedPolicy.policyId}?policyType=${selectedPolicy.policyType}&isHOFlex=${CustomPolicyTypeUtil.isHOFlex(selectedPolicy)}`,
            visible: (!isPolicyAgencyBill(selectedPolicy) && !isPolicyStatusScheduled(selectedPolicy) && !isPolicyExpiredOrPermanentCanceled(selectedPolicy) && (isAmountZero(selectedPolicy.currentPaymentDue_Ext)) && isMobileLandscape)
        },
        viewPolicyButton: {
            to: `/account-policy-details/${selectedPolicy.policyId}`
        },
        policyDetailGrid: {
            className: (isPolicyStatusInForce(selectedPolicy) && isPaymentPastDue(selectedPolicy)) || isPolicyAgencyBill(selectedPolicy) ? 'overdue-policy-card-detail-grid' : 'policy-card-detail-grid'
        },
        policyGridContainer: {
            className: (isPolicyStatusInForce(selectedPolicy) && isPaymentPastDue(selectedPolicy)) || isPolicyAgencyBill(selectedPolicy) ? 'overdue-policy-grid-container' : 'policy-grid-container'
        },
        paymentPastDueNotification: {
            visible: (isPolicyStatusInForce(selectedPolicy) && isPaymentPastDue(selectedPolicy)) && !(breakpoint === 'phone' || breakpoint === 'phoneWide')
        },
        mobilePaymentPastDueNotification: {
            visible: (isPolicyStatusInForce(selectedPolicy) && isPaymentPastDue(selectedPolicy)) && (breakpoint === 'phone' || breakpoint === 'phoneWide')
        }

    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomBillingDetails.propTypes = {
};

export default CustomBillingDetails;
