import VehicleInfoLookupComponent from './VehicleInfoLookupComponent/VehicleInfoLookupComponent';

const VehicleInfoLookupComponentMap = {
    VehicleInfoLookupComponent: { component: 'VehicleInfoLookupComponent' }
};

const VehicleInfoLookupComponents = {
    VehicleInfoLookupComponent
};

export {
    VehicleInfoLookupComponentMap,
    VehicleInfoLookupComponents,
    // export single component
    VehicleInfoLookupComponent
};
