import { defineMessages } from 'react-intl';

export default defineMessages({
    makeAPayment: {
        id: 'billing.make-payment-page.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    setPaymentSource: {
        id: 'billing.make-payment-page.Set Payment Source',
        defaultMessage: 'Set Payment Source'
    },
    selectInvoicesToPay: {
        id: 'billing.make-payment-page.Select Invoices to Pay',
        defaultMessage: 'Select Invoices to Pay'
    },
    payNow: {
        id: 'billing.make-payment-page.Pay Now',
        defaultMessage: 'Pay Now'
    },
    paymentOfHasBeenAppliedToYourAccount: {
        id: 'billing.make-payment-page.Payment of x has been applied to your account.',
        defaultMessage: 'Payment of {amountToPay} has been applied to your account.'
    },
    amountToPay: {
        id: 'billing.make-payment-page.Amount to Pay',
        defaultMessage: 'Amount to Pay'
    },
    paymentRequestFailed: {
        id: 'billing.make-payment-page.Payment request failed',
        defaultMessage: 'Payment request failed'
    },
    sorryYourPaymentCouldNotBeProcessedAtThisTime: {
        id: 'billing.make-payment-page.Sorry your payment could not be processed at this time',
        defaultMessage: 'Sorry your payment could not be processed at this time'
    },
    noPaymentsAreCurrentlyDue: {
        id: 'billing.make-payment-page.No payments are currently due.',
        defaultMessage: 'No payments are currently due.'
    },
    paymentComplete: {
        id: 'billing.make-payment-page.Payment Complete',
        defaultMessage: 'Payment Complete'
    },
    messageForPaymentComplete: {
        id: 'billing.make-payment-page.Thank you for your payment which is now being processed. The status of your invoice will be updated once payment has been successfully processed.',
        defaultMessage: 'Thank you for your payment which is now being processed. The status of your invoice will be updated once payment has been successfully processed.'
    },
    returnToSummary: {
        id: 'billing.make-payment-page.Return to Summary',
        defaultMessage: 'Return to Summary'
    }
});
