import appConfig from 'app-config';

const normalizedPolicyTypeIcons = {
    CV: {
        image: 'Collector Vehicle',
        path: 'collectorvehicle.png'
    },
    HO: {
        image: 'Homeowners',
        path: 'house.png'
    },
    MH: {
        image: 'Manufactured Home',
        path: 'manufacturedhome.png'
    },
    TN: {
        image: 'Tenant',
        path: 'renter.png'
    },
    DB: {
        image: 'Dwelling Basic',
        path: 'house.png'
    },
    DS: {
        image: 'Dwelling Special',
        path: 'house.png'
    },
    MS: {
        image: 'Motorsports',
        path: 'motorsports.png'
    },
    BO: {
        image: 'Boat',
        path: 'boat.png'
    },
    YT: {
        image: 'Yacht',
        path: 'yacht.png'
    },
    EQ: {
        image: 'Earthquake',
        path: 'earthquake.png'
    },
    CO: {
        image: 'Condo',
        path: 'renter.png'
    },
    default: {
        image: 'no-icon', // Using an existing empty image as default to avoid 404s
        path: 'mi-receipt'
    }
};

const policyTypeKeys = {
    CollectorVehicle: 'CV',
    Homeowners: 'HO',
    ManufacturedHome: 'MH',
    Tenant: 'TN',
    DwellingBasic: 'DB',
    DwellingSpecial: 'DS',
    Motorsports: "MS",
    Boat: 'BO',
    Yacht: 'YT',
    Earthquake: 'EQ',
    Condo: 'CO',

};

const { env } = appConfig;
const getIconImageSrc = (directoryPath, imageName) => {
    const url = _.get(env, 'DEPLOYMENT_URL', '').replace(/\/$/, '');
    return `${url}${directoryPath}/${imageName}`;
};

const getNormalizedObject = (policyTypeCode) => {
    const normalizedKey = policyTypeKeys[policyTypeCode] || 'default';
    return normalizedPolicyTypeIcons[normalizedKey];
};

const getImageIcon = (policyTypeCode) => {
    return getNormalizedObject(policyTypeCode).image;
};

const getIconPath = (policyTypeCode) => {
    return getNormalizedObject(policyTypeCode).path;
};

const getCustomPolicyTypeIcon = (policyTypeCode) => {
    if (typeof policyTypeCode !== "undefined" && policyTypeCode !== null) {
        policyTypeCode = policyTypeCode.replace(/ +/g, "");
    }
    const iconPath = getIconPath(policyTypeCode);
    return getIconImageSrc("/branding/amig/policy-type-summary-page", iconPath);
};

const getLogoSrc =(imageName) => {
    const url = _.get(env, 'DEPLOYMENT_URL', '').replace(/\/$/, '');
    return `${url}/branding/amig/${imageName}`;
};

export default {
    getImageIcon: getImageIcon,
    getIconImageSrc: getIconImageSrc,
    getIconPath: getIconPath,
    getCustomPolicyTypeIcon: getCustomPolicyTypeIcon,
    getLogoSrc : getLogoSrc
};
