import styles from './CustomChoosePymtHeaderComponent.module.scss';
import metadata from './CustomChoosePymtHeaderComponent.metadata.json5';
import messages from './CustomChoosePymtHeaderComponent.messages';
import { MetadataForm } from '@jutro/uiconfig';
import { CustomIconUtil } from 'gw-portals-util-js';
import React, { useCallback, useContext } from 'react';
import { TranslatorContext } from '@jutro/locale';
import { BreakpointTrackerContext } from '@jutro/layout';
import { useHistory } from 'react-router-dom';

function CustomChoosePymtHeaderComponent(props) {
    const history = useHistory();
    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const pageRedirects = {
        myPolicies: '/home'
    };

    const handleClose = useCallback(() => {
        if(!history.location.key) {
            history.push(pageRedirects.myPolicies)
        }  else {
            history.goBack(); 
        }       
    }, []);

    const overrideProps = {
        amiglogo: {
            to: pageRedirects.myPolicies,
        },
        amigimage: {
            src: CustomIconUtil.getLogoSrc('AMIG-desktopHeaderLogo.png'),
            disabled: true
        },
        exitLink: {
            visible: !isMobile,
            content: translator(messages.exitLink),
            onClick: handleClose,
            className: 'exit-button'
        },
        closeButtonMobile: {
            visible: isMobile,
            onClick: handleClose
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
        },
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomChoosePymtHeaderComponent.propTypes = {

};
export default CustomChoosePymtHeaderComponent;