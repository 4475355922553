const nameValidation = (newValue) => {
    const regex = new RegExp(/^[a-zA-Z]*$/);
    const inputValue = newValue.trim();
    if (!regex.exec(inputValue)) {
        return 'Please enter only letters.';
    }
    if (inputValue.length > 30) {
        return 'You have entered too many letters.';
    }
    return null;
};

const businessNameValidation = (newValue) => {
    const inputValue = newValue.trim();
    if (inputValue.length > 80) {
        return 'You have entered too many characters.';
    }
    return null;
};

const zipCodeValidation = (newValue, invalidCode) => {
    const digitsRegex = new RegExp(/^[0-9-]*$/);
    const zipRegex = new RegExp(/^\d{5}((?:[-\s]\d{4})|(\d{4}))?$/);

    const inputValue = newValue.trim();
    if (!digitsRegex.exec(inputValue)) {
        return 'Please enter only digits.';
    }
    if (!zipRegex.exec(inputValue) && inputValue.length > 9) {
        return 'You have entered too many digits. Please check the ZIP Code and reenter.';
    }
    if (inputValue.length > 0 && !zipRegex.exec(inputValue) && invalidCode) {
        return 'The ZIP Code you entered is invalid. Please check the ZIP Code and reenter.';
    }
    if (inputValue.length > 0 && !zipRegex.exec(inputValue)) {
        return 'The ZIP Code you entered does not match our records. Please check the ZIP Code and reenter.';
    }
    return null;
};

const policyNumberValidation = (newValue) => {
    const regex = new RegExp(/^[0-9]*$/);
    const inputValue = newValue.trim();
    if (inputValue.length > 9) {
        return 'You have entered too many digits. Please check the policy number and reenter.';
    }
    if (!regex.exec(inputValue)) {
        return 'Please enter only digits.';
    }
    if (inputValue.length > 0 && inputValue.length < 9) {
        return ['Please enter the complete policy number.'];
    }
    return null;
};

export default {
    nameValidation: nameValidation,
    businessNameValidation: businessNameValidation,
    zipCodeValidation: zipCodeValidation,
    policyNumberValidation: policyNumberValidation,
};
