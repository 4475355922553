import React, { useCallback, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { LoadingContext } from 'gw-capability-policy-react';import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import './CustomPolicyPropertyDetails.messages';
import { BreakpointTrackerContext } from '@jutro/layout';
import { DocumentService } from 'gw-capability-policydocument';
import { useAuthentication } from 'gw-digital-auth-react';
import { CustomIconUtil, CustomFormatterUtil } from 'gw-portals-util-js';
import metadata from './CustomPolicyPropertyDetails.metadata.json5';
import styles from './CustomPolicyPropertyDetails.module.scss';

function CustomPolicyPropertyDetails(props) {
    const { value: propertyDetails, policyNumber: policyNumber, policyStatus: policyStatus } = props;
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const loader = useContext(LoadingContext);
    const { authHeader } = useAuthentication();
    const isTenant = (props.policyType === 'Tenant');
    const isEarthquake = (props.policyType === 'Earthquake');
    const generateDeclarationPage = useCallback(() => {
        loader.setLoading(true);
        Promise.all([DocumentService.generateOnlineDocument('printDeclarationPage', policyNumber, authHeader)])
            .finally(() => { loader.setLoading(false);
            });
    }, []);

    const getDeductible = (dwelling) => {
        if (isTenant) {
            return '';
        }
        if (isEarthquake) {
            return dwelling.dwellingDeductibleOpt_Ext;
        }
        return CustomFormatterUtil.formatAmount(dwelling.dwellingDeductible_Ext.amount);
    };

    const getFormattedAddress = (address) => {
        const endOfLineOne = address.indexOf(',');
        var formattedAddress = <p>{address.substring(0, endOfLineOne)}<br/>{address.substring(endOfLineOne + 2)}</p>;
        return formattedAddress;
    };

    const generatePropertyOverrides = useCallback( () => {
        const overrides = propertyDetails.dwellingDTOs.map((property, index) => {
            return {
                [`propertyDetailGridRow${index}`]: {
                    columns: isTenant ? ['2.5fr', '1fr', '1fr', '1fr', '1fr'] : ['3fr', '1fr', '1fr', '1fr', '1fr' ]
                },
                [`propertyAddress${index}`]: {
                    value: isMobile ? getFormattedAddress(property.address.displayName) : property.address.displayName
                },
                [`propertyOccupancy${index}`]: {
                    className: 'propertyDetailsGridValues'
                },
                [`propertySettlementOption${index}`]: { visible: !isTenant },
                [`propertyLimit${index}`]: { visible: !isTenant,
                    value: isTenant ? '' : CustomFormatterUtil.formatAmount(property.dwellingLimit_Ext.amount)
                },
                [`propertyDeductible${index}`]: { visible: !isTenant,
                    value: getDeductible(property)
                },
                [`gridSeparator${index}`]: {
                    visible: ((propertyDetails.dwellingDTOs.length - 1) !== index)
                },
                [`gridSeparatorLeftColumn${index}`]: { visible: ((index) % 2 === 0) && index > 1 },
                [`gridSeparatorRightColumn${index}`]: { visible: ((index) % 2 === 0) && index > 1 }
            };
        });
        return Object.assign({}, ...overrides);
    }, [propertyDetails]);

    const overrides = {
        idCardAvatar: {
            src: CustomIconUtil.getLogoSrc('download.svg'),
        },
        showPropertyCoveragesLink: {
            onClick: generateDeclarationPage,
            target: '_blank',
        },
        SettlememtOptionTitle: {
            visible: !isTenant
        },
        LimitTitle: {
            visible: !isTenant
        },
        DeductibleTitle: {
            visible: !isTenant
        },
        OccupancyTitle: {
            className: 'policyDetailTableTitle'
        },
        propertyDetailTableHeader: {
            columns: isTenant ? ['2.5fr', '1fr', '1fr', '1fr', '1fr'] : ['3fr', '1fr', '1fr', '1fr', '1fr' ],
            visible: !isMobile
        },
        showPropertyDetailsContainer: {
            visible: (policyStatus !== 'Expired' && policyStatus !== 'Canceled')
        },

        ...generatePropertyOverrides(),
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(
                metadata.componentContent,
                propertyDetails,
                id,
                path,
                overrides
            );
        },
        [propertyDetails, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={propertyDetails}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

CustomPolicyPropertyDetails.propTypes = {
    value: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
};

CustomPolicyPropertyDetails.defaultProps = {
    value: []
};

export default CustomPolicyPropertyDetails;
