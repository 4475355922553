import { defineMessages } from 'react-intl';

export default defineMessages({
    rotateMessageHeader: {
        id: 'policyDocuments.component.Flip your phone',
        defaultMessage: 'Flip your phone'
    },
    rotateMessageContent: {
        id: 'policyDocuments.component.Flip your phone message',
        defaultMessage: 'Documents are best viewed in mobile landscape view or on a desktop computer.'
    },
    searchInput: {
        id: 'jutro-datatable.filterBar.searchInput',
        defaultMessage: 'Search insurance...'
    },
    clearFilters: {
        id: 'jutro-datatable.filterBar.clearFilters',
        defaultMessage: 'Clear'
      },
});
