import React, {
    useState, useContext, useCallback, useMemo
} from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { ModalNextProvider, Tooltip } from '@jutro/components';
import { TranslatorContext } from '@jutro/locale';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { useValidation } from 'gw-portals-validation-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { useAuthentication } from 'gw-digital-auth-react';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { PolicyChange, messages as commonMessage } from 'gw-capability-policychange-common-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import { messages as platformMessages } from 'gw-platform-translations';
import metadata from './VehiclesPage.metadata.json5';
import styles from '../../PAPolicyChange.module.scss';
import messages from './VehiclesPage.messages';

const unassignedDrivers = 'lobData.personalAuto.coverables.unassignedDrivers';
const unassignedVehicles = 'lobData.personalAuto.coverables.unassignedVehicles';
const drivers = 'lobData.personalAuto.coverables.drivers';
const vehicles = 'lobData.personalAuto.coverables.vehicles';

function MetadataTooltip({ children, ...rest }) {
    const translator = useContext(TranslatorContext);
    const child = Array.isArray(children) ? children[0] : children;
    return (
        <Tooltip {...rest} content={translator(messages.atleastOneVehicle)}>
            {child}
        </Tooltip>
    );
}
MetadataTooltip.propTypes = {
    children: PropTypes.shape({}).isRequired
};
function VehiclesPage(props) {
    const translator = useContext(TranslatorContext);
    const [vehiclePath, setVehiclePath] = useState('');
    const { EndorsementService } = useDependencies('EndorsementService');
    const [selectedVehicle, setSelectedVehicle] = useState({});
    const { wizardData, updateWizardData } = props;
    const { submissionVM } = wizardData;
    const [isVmIntitialized, updateIsVmInitialized] = useState(false);
    const {
        onValidate,
        initialValidation,
        isComponentValid,
        disregardFieldValidation
    } = useValidation('PAPolicyChangeVehiclePage');
    const { authHeader } = useAuthentication();

    const cleanUnassigned = useCallback(() => {
        const unassignedVehiclesList = _.get(submissionVM.value, unassignedVehicles);
        const unassignedDriversList = _.get(submissionVM.value, unassignedDrivers);
        if (!unassignedDriversList || unassignedDriversList.length === 0) {
            _.set(submissionVM, 'lobData.personalAuto.coverables.value.unassignedDrivers', []);
        }
        if (!unassignedVehiclesList || unassignedVehiclesList.length === 0) {
            _.set(submissionVM, 'lobData.personalAuto.coverables.value.unassignedVehicles', []);
        }
    }, [submissionVM]);

    const toggleDriverAssignment = useCallback(
        (driver, vehicle) => {
            if (
                submissionVM.value.lobData.personalAuto.coverables.isDriverAssigned(driver, vehicle)
            ) {
                submissionVM.value.lobData.personalAuto.coverables.removeDriverAssignment(
                    driver,
                    vehicle
                );
            } else {
                submissionVM.value.lobData.personalAuto.coverables.assignDriver(driver, vehicle);
            }
            submissionVM.value.updateUnAssignedMapping();
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const showUniqueVinModal = useCallback(
        (duplicateVehicleArray) => {
            return ModalNextProvider.showAlert({
                title: messages.paVehicleNotUniqueTitle,
                message: translator(messages.paVinMatch, {
                    vinMatchedVehicle: duplicateVehicleArray[0].displayName
                        ? duplicateVehicleArray[0].displayName
                        : duplicateVehicleArray[0].getDisplayName()
                }),
                status: 'error',
                icon: 'mi-error-outline',
                confirmButtonText: platformMessages.ok
            }).catch(_.noop);
        },
        [translator]
    );

    const uniqueVin = useCallback(() => {
        let vincheckerror = false;
        const vehicleListArray = _.get(
            submissionVM.value,
            'lobData.personalAuto.coverables.vehicles'
        );
        const groupedVehicles = _.groupBy(vehicleListArray, (vehicle) => {
            return vehicle.vin;
        });
        Object.keys(groupedVehicles).forEach((vehicle) => {
            if (groupedVehicles[vehicle].length > 1) {
                showUniqueVinModal(groupedVehicles[vehicle]);
                vincheckerror = true;
                return false;
            }
            return true;
        });
        return vincheckerror;
    }, [showUniqueVinModal, submissionVM.value]);

    const showVehicleDetails = useCallback((vehicle, index) => {
        setVehiclePath(`lobData.personalAuto.coverables.vehicles.children[${index}].value`);
        setSelectedVehicle(vehicle);
    }, []);

    const removeVehicle = useCallback(
        (vehicleList, vehicle) => {
            const vehicleIndex = vehicleList.value.findIndex(
                (driverFromList) => _.isEqual(driverFromList, vehicle)
            );
            submissionVM.value.lobData.personalAuto.coverables.removeVehicle(vehicle);
            disregardFieldValidation(`vehicle${vehicleIndex}`);
            submissionVM.value.updateUnAssignedMapping();
            updateWizardData(wizardData);
        },
        [disregardFieldValidation, submissionVM.value, updateWizardData, wizardData]
    );

    const getDriverFromList = useCallback((driversList, VehicleDriverID) => {
        const [, driverID] = VehicleDriverID.split('_');
        return driversList.value.find((driver) => driver.fixedId === Number(driverID));
    }, []);

    const getAssignedDriverList = useCallback(
        (vehicle) => {
            const driverVehicle = submissionVM.value.lobData.personalAuto
                .coverables.getAssignedDrivers(vehicle);
            return driverVehicle.map(
                (v) => `${vehicle.fixedId || vehicle.tempID}_${v.fixedId.toString()}`
            );
        },
        [submissionVM.value.lobData.personalAuto.coverables]
    );

    const handleValueChange = useCallback(
        (value, vehicle, driversList) => {
            if (
                vehicle.tempID === selectedVehicle.tempID
                || vehicle.fixedId === selectedVehicle.fixedId
            ) {
                if (value.length) {
                    const vehicleID = selectedVehicle.fixedId || selectedVehicle.tempID;
                    const assignedVehicleDriver = _.get(
                        submissionVM.value,
                        'lobData.personalAuto.coverables.vehicleDrivers'
                    );
                    _.remove(assignedVehicleDriver, (vehicleDrivers) => {
                        return (
                            vehicleDrivers.vehicle.fixedId === vehicleID
                            || vehicleDrivers.vehicle.tempID === vehicleID
                        );
                    });
                    value.forEach((element) => {
                        toggleDriverAssignment(getDriverFromList(driversList, element), vehicle);
                    });
                } else {
                    const driver = submissionVM.value.lobData.personalAuto.coverables
                        .getAssignedDrivers(vehicle);
                    toggleDriverAssignment(driver[0], vehicle);
                }
                cleanUnassigned();
            }
        },
        [selectedVehicle, cleanUnassigned, submissionVM, toggleDriverAssignment, getDriverFromList]
    );

    const unassignedVehiclesOverrides = useCallback(() => {
        const unassignedVehiclesList = _.get(submissionVM.value, unassignedVehicles);
        let overrides = [];
        if (!_.isEmpty(unassignedVehiclesList)) {
            overrides = unassignedVehiclesList.map((vehicle, index) => {
                return {
                    [`errorNotificationVehicle${index}`]: {
                        message: translator(commonMessage.noVehicleError, {
                            vehicle: vehicle.getDisplayName()
                        }),
                        visible: true
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [translator, submissionVM]);

    const unassignedDriversOverrides = useCallback(() => {
        const unassignedDriversList = _.get(submissionVM.value, unassignedDrivers, []);
        let overrides = [];
        if (!_.isEmpty(unassignedDriversList)) {
            overrides = unassignedDriversList.map((driver, index) => {
                return {
                    [`errorNotificationDriver${index}`]: {
                        message: translator(commonMessage.noDriverError, {
                            driver: driver.getDisplayName()
                        }),
                        visible: true
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [translator, submissionVM]);

    const vehicleOverrides = useMemo(() => {
        const availableVehiclePath = 'lobData.personalAuto.coverables.vehicles.children';
        const vehiclesList = _.get(submissionVM, vehicles, []);
        const driversList = _.get(submissionVM, drivers, []);
        let overrides = [];
        if (!_.isEmpty(vehiclesList)) {
            overrides = vehiclesList.value.map((vehicle, index) => {
                const modelName = _.get(vehicle, 'make')
                    ? `${_.get(vehicle, 'make')} ${_.get(vehicle, 'model')}`
                    : '';
                const yearData = _.get(vehicle, 'year') || '';
                const driversInfo = driversList.value.map((driver) => {
                    return {
                        code: `${vehicle.fixedId || vehicle.tempID}_${driver.fixedId}`,
                        name: driver.displayName
                    };
                });
                return {
                    [`paVehicleContainer${index}`]: {
                        visible: `${availableVehiclePath}[${index}].value` === vehiclePath
                    },
                    [`paVehicleSectionModelId${index}`]: {
                        value: modelName || translator(messages.paNewVehicle),
                        className: styles.vehicleMakeModelLabel
                    },
                    [`paVehicleSectionYearId${index}`]: {
                        value: yearData || ' '
                    },
                    [`paVehicleSectionTrashId${index}`]: {
                        disabled:
                            vehiclesList.length === 1
                            || (`${availableVehiclePath}[${index}].value` !== vehiclePath
                                && !isComponentValid),
                        onClick: () => {
                            removeVehicle(vehiclesList, vehicle);
                        }
                    },
                    [`paVehicleSectionEditId${index}`]: {
                        disabled:
                            `${availableVehiclePath}[${index}].value` !== vehiclePath
                            && !isComponentValid,
                        visible: `${availableVehiclePath}[${index}].value` !== vehiclePath,
                        onClick: () => {
                            showVehicleDetails(vehicle, index);
                        }
                    },
                    [`driverAssignment${index}`]: {
                        availableValues: driversInfo,
                        value: getAssignedDriverList(vehicle),
                        onValueChange: (value) => handleValueChange(value, vehicle, driversList)
                    }
                };
            });
        }
        return Object.assign({}, ...overrides);
    }, [
        submissionVM,
        vehiclePath,
        translator,
        isComponentValid,
        getAssignedDriverList,
        removeVehicle,
        showVehicleDetails,
        handleValueChange
    ]);

    const setDefaultNewEntry = useCallback(() => {
        const vehicle = submissionVM.value.lobData.personalAuto.coverables.createVehicle();
        setSelectedVehicle(vehicle);
    }, [submissionVM]);

    const onAddNew = useCallback(() => {
        setDefaultNewEntry();
        const vehicleIndex = submissionVM.lobData.personalAuto.coverables.vehicles.length - 1;
        setVehiclePath(`lobData.personalAuto.coverables.vehicles.children[${vehicleIndex}].value`);
    }, [setDefaultNewEntry, submissionVM]);

    const onNext = useCallback(async () => {
        if (!uniqueVin()) {
            if (!submissionVM.value.validate()) {
                // re-render the component, if vehicles aren't mapped to drivers
                updateWizardData(wizardData);
                return false;
            }
            submissionVM.value.beforeSave();
            const policyChangeResponse = await EndorsementService.saveEndorsement(
                [submissionVM.value],
                authHeader
            );
            submissionVM.value = new PolicyChange(policyChangeResponse);
            cleanUnassigned();
            return wizardData;
        }
        return false;
    }, [
        EndorsementService,
        uniqueVin,
        submissionVM,
        authHeader,
        cleanUnassigned,
        wizardData,
        updateWizardData
    ]);

    const resolvers = {
        resolveClassNameMap: styles,
        resolveCallbackMap: {
            onAddVehicleClick: onAddNew,
            onValidate: onValidate
        },
        resolveComponentMap: {
            tooltipcomponent: MetadataTooltip
        }
    };

    const overrideProps = {
        '@field': {
            showOptional: true
        },
        addVehicle: {
            disabled: !isComponentValid
        },
        ...vehicleOverrides,
        ...unassignedVehiclesOverrides(),
        ...unassignedDriversOverrides()
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.pageContent, submissionVM, id, path, overrideProps);
        },
        [submissionVM, overrideProps]
    );
    const handleModelChange = useCallback(
        (newModel) => {
            updateWizardData({
                ...wizardData,
                submissionVM: newModel
            });
        },
        [updateWizardData, wizardData]
    );
    if (!isVmIntitialized) {
        updateWizardData(wizardData);
        updateIsVmInitialized(true);
        cleanUnassigned();
        return null;
    }
    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={(appConfig.persona === 'policyholder' ? commonMessage.cancelAllChanges : commonMessage.cancel)}
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                onModelChange={handleModelChange}
                onValidationChange={onValidate}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
                resolveValue={readValue}
                classNameMap={resolvers.resolveClassNameMap}
                componentMap={resolvers.resolveComponentMap}
            />
        </WizardPage>
    );
}

VehiclesPage.propTypes = wizardProps;
export default VehiclesPage;
