import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import {
    withAuthenticationContext, useAuthentication
} from 'gw-digital-auth-react';
import { LoadingContext } from 'gw-capability-policy-react';
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { CustomFormatterUtil, CustomIconUtil } from 'gw-portals-util-js';
import metadata from './CustomAccountProfileComponent.metadata.json5';
import styles from './CustomAccountProfileComponent.module.scss';
import messages from './CustomAccountProfileComponent.messages';

function CustomAccountProfileComponent(props) {
    const translator = useContext(TranslatorContext);
    const loader = useContext(LoadingContext);
    const [contactData, updateContactData] = useState(props.userDetails);
    const { env } = appConfig;
    const {
        given_name, family_name
    } = useAuthentication();
    useEffect(() => {
        loader.setLoading(false);
        // update contact data
        updateContactData({cellNumber: props.userDetails.cellNumber });
    }, [props.userDetails]);

    const overrideProps = {
        infoLogo: {
            src: CustomIconUtil.getLogoSrc('blue_info_icon.png'),
        },
        phoneInput: {
            content: CustomFormatterUtil.formatPhoneNumber(contactData.cellNumber),
        },
        phoneInputMobile: {
            content: CustomFormatterUtil.formatPhoneNumber(contactData.cellNumber),
        },
        editPhone: {
            onClick: props.toggleAccount(env.TENANT_PHONE_FLOW)
        },
        accountHolderInput: {
            content: translator(messages.accountHolder, { firstName: given_name, lastName: family_name })
        },
        accountHolderInputMobile: {
            content: translator(messages.accountHolder, { firstName: given_name, lastName: family_name })
        }
    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return renderContentFromMetadata(
        metadata.pageContent,
        overrideProps,
        resolvers
    );
}

CustomAccountProfileComponent.propTypes = {
    authHeader: PropTypes.shape({}).isRequired,
};

export default withAuthenticationContext(
    withRouter(CustomAccountProfileComponent)
);
