// eslint doesn't seem to play nicely with the webpack aliases
// eslint-disable-next-line import/no-unresolved
import { capabilities, env } from 'app-config';
const expandTemplate = require('expand-template');


function ensureSingleTrailingSlash(url) {
    return `${url}/`.replace(/(\/){2,}$/, '/');
}

/**
 * Returns the Real service url (not proxied)
 * @param {string} serviceName the name of the service to invoke
 *                  (the service name is defined in the ServiceEnpoint of each capability)
 * @returns {string} the real service url
 */
export function getProxiedServiceUrl(serviceName) {
    const endpoint = capabilities[serviceName];
    const baseUrl = ensureSingleTrailingSlash(env.API_GATEWAY_URL);
    const sanatizedURL =  replaceParamsInUrl(baseUrl, endpoint.server);
    return sanatizedURL + endpoint.service ;
}

/**
 * Returns a url which will be proxied depending on the base url
 *
 * This is generally meant for *non-EDGE* URLs.
 * If you you want to proxy an edge capability, you should probably use
 * `getProxiedServiceUrl`
 *
 * @see {@link getProxiedServiceUrl}
 * @param {string} urlToProxy the url that should be proxied
 * @returns {string} the real url which accounts for the deployment url
 */
export function getProxiedUrl(urlToProxy) {
    const baseUrl = ensureSingleTrailingSlash(env.API_GATEWAY_URL);
    return baseUrl + urlToProxy;
}

function replaceParamsInUrl(urlPattern, serverId) {
    const serverLowerCase = serverId.toLowerCase();
    const parser = expandTemplate({
        sep: '{{}}'
    });
    const serverUrlString = parser(urlPattern, {
        SUITE_APPLICATION: serverLowerCase
    });

    // ensures the serverUrl ends in /
    const sanitizedServerUrl = `${serverUrlString}/`.replace(/(\/){2,}$/, '/');

    return sanitizedServerUrl;
}
