import { defineMessages } from 'react-intl';

export default defineMessages({
    choosePaymentMethod: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Choose Payment Method',
        defaultMessage: 'Choose Payment Method'
    },
    pay: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Pay',
        defaultMessage: 'Pay'
    },
    or: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.or',
        defaultMessage: 'or'
    },
    policyPeriod: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Policy Period',
        defaultMessage: 'Policy Period'
    },
    effectiveDate: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
    paymentPlan: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Payment Plan',
        defaultMessage: 'Payment Plan'
    },
    changeInCost: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Change in Cost',
        defaultMessage: 'Change in Cost'
    },
    creditCard: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Credit Card',
        defaultMessage: 'Credit Card'
    },
    payAndFinish: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Pay and Finish',
        defaultMessage: 'Pay and Finish'
    },
    existingPlan: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Spread over existing payment plan?',
        defaultMessage: 'Spread over existing payment plan?'
    },
    makeFullPaymentNow: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Make full payment now?',
        defaultMessage: 'Make full payment now?'
    },
    spreadPayments: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Spread Payments',
        defaultMessage: 'Spread Payments'
    },
    bankAccount: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Bank Account',
        defaultMessage: 'Bank Account'
    },
    payInFull: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Pay in Full',
        defaultMessage: 'Pay in Full'
    },
    paymentMethod: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Payment Method',
        defaultMessage: 'Payment Method'
    },
    cancel: {
        id: 'gw-portals-policytransaction-common-angular.components.payment.payment.Cancel',
        defaultMessage: 'Cancel'
    }
});
