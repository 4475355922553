import { defineMessages } from 'react-intl';

export default defineMessages({
    PolicyStart: {
        id: 'policycommon.component.policy-common-details.Policy Start',
        defaultMessage: 'Policy Start'
    },
    PolicyExpiration: {
        id: 'policycommon.component.policy-common-details.Policy Expiration',
        defaultMessage: 'Policy Expiration'
    },
    PolicyType: {
        id: 'policycommon.component.policy-common-details.Policy Type',
        defaultMessage: 'Policy Type'
    },
    TotalPremium: {
        id: 'policycommon.component.policy-common-details.Total Premium',
        defaultMessage: 'Total Premium'
    },
    TaxesAndFees: {
        id: 'policycommon.component.policy-common-details.Taxes and Fees',
        defaultMessage: 'Taxes and Fees'
    },
    TotalCost: {
        id: 'policycommon.component.policy-common-details.Total Cost',
        defaultMessage: 'Total Cost'
    },
    details: {
        id: 'policycommon.component.policy-common-details.Details',
        defaultMessage: 'Details'
    },
    premium: {
        id: 'policycommon.component.policy-common-details.Premium',
        defaultMessage: 'Premium'
    }
});
