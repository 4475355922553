import {
    defineMessages
} from 'react-intl';

export default defineMessages({
    paperless: {
        id: 'account.account pref.document delivery option.Paperless',
        defaultMessage: 'Paperless'
    },
    usMail: {
        id: 'account.account pref.document delivery option.US Mail',
        defaultMessage: 'U.S. Mail'
    }
});
