import { defineMessages } from 'react-intl';

export default defineMessages({
    statusActive: {
        id: 'policy.card.policy-status.Active',
        defaultMessage: 'Active'
    },
    statusCanceled: {
        id: 'policy.card.policy-status.Canceled',
        defaultMessage: 'Canceled'
    },
    customerService: {
        id: 'policy.card.customer-service.Message',
        defaultMessage: 'If you need any further support, please contact Customer Service at'
    },
    customerServicePhoneNumber: {
        id: 'policy.card.customer-service.PhoneNumber',
        defaultMessage: '1-800-543-2644.'
    },
    makeAPaymentButton: {
        id: 'policy-card.details.Make a payment',
        defaultMessage: 'Make a payment'
    },
    policyTypeValue: {
        id: 'policy.card.{type} Policy',
        defaultMessage: '{type} Policy'
    },
    policyIdValue: {
        id: 'policy.card.#{id}',
        defaultMessage: '#{id}'
    },
    lastPaymentReceivedValue: {
        id: 'policy.card.{lastPaymentReceivedAmt} on {lastPaymentReceivedAmtDate}',
        defaultMessage: '{lastPaymentReceivedAmt} on {lastPaymentReceivedAmtDate}'
    },
    nextPaymentDueValue: {
        id: 'policy.card.{nextPaymentAmt} due on {nextPaymentAmtDate}',
        defaultMessage: '{nextPaymentAmt} due on {nextPaymentAmtDate}'
    },
    includingFees: {
        id: 'portal.views.home-page-layout.Including Fees',
        defaultMessage: 'Total may include service or late fees.'
    },
    claimsHeader: {
        id: 'portal.views.home-page-layout.Claim Header',
        defaultMessage: 'Claims'
    },
    noActiveClaim: {
        id: 'policy.card.No Active Claim',
        defaultMessage: 'You have no active claims.'
    },
    latestClaimUpdates: {
        id: 'policy.card.Latest Claims Updates',
        defaultMessage: 'Latest claims updates'
    },
    youHave: {
        id: 'policy.card.You Have',
        defaultMessage: 'You have '
    },
    activeClaim: {
        id: 'policy.card.Active Claim',
        defaultMessage: ' active claim.'
    },
    activeClaims: {
        id: 'policy.card.Active Claims',
        defaultMessage: ' active claims.'
    },
    noClaimHistory: {
        id: 'policy.card.No Claim History',
        defaultMessage: 'You have no claims history.'
    },
    claimAPIErrorHeader: {
        id: 'policy.card.Claim API Error',
        defaultMessage: 'Something went wrong...'
    },
    claimAPIErrorDescription: {
        id: 'policy.card.Claim API Error Description',
        defaultMessage: 'We couldn\'t complete your request. Please try again later ' + 
        'or email Customer Service at onlineservices@amig.com'
    },
    trackClaims: {
        id: 'policy.card.Track Claims',
        defaultMessage: 'Use chatbot to track your claims.'
    },
    currentPaymentDue: {
        id: 'translation.number.Current Payment Due',
        defaultMessage: 'Current payment due'
    }
});