/* eslint-disable max-len */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import appConfig from 'app-config';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { withAuthenticationContext, useAuthentication } from 'gw-digital-auth-react';
import { EnrollmentSummaryContext } from 'gw-capability-enrollment-react';
import { CustomIconUtil } from 'gw-portals-util-js';
import { CustomAccountPrefContext } from 'gw-capability-profileinfo-react';
import { CustomCRMService } from 'gw-capability-profileinfo';
import { LoadingContext } from 'gw-capability-policy-react';
import metadata from './CustomAccountPrefPage.metadata.json5';
import messages from './CustomAccountPrefPage.messages';
import styles from './CustomAccountPrefPage.module.scss';
import CustomNotificationsComponent from '../../components/CustomNotifications/CustomNotifications';
import CustomAccountProfileComponent from '../../components/CustomAccountProfileComponent/CustomAccountProfileComponent';
import DocumentDeliveryComponent from '../../components/CustomDocumentDelivery/CustomDocumentDelivery';
import CustomLoginSecurityComponent from '../../components/CustomLoginSecurityComponent/CustomLoginSecurityComponent';
import accountPrefTypes from './CustomAccountPrefTypes.json';

function CustomAccountPrefPage() {
    const { env } = appConfig;
    const { authConfig, authHeader, oid, email, strongAuthenticationPhoneNumber, aud, nonce, refreshToken } = useAuthentication();
    const accountProfile = useContext(EnrollmentSummaryContext);
    const translator = useContext(TranslatorContext);
    const [accountProfileDetailCardStyle, setAccountProfileDetailCardStyle] = useState('account-preferences-columns-hidden');
    const [accountProfileDetailContainerStyle, setAccountProfileDetailContainerStyle] = useState('account-preferences-card account-preferences-card-minified');
    const [loginNSecurityCardStyle, setLoginNSecurityCardStyle] = useState('account-preferences-columns-hidden');
    const [loginNSecurityCardContainerStyle, setLoginNSecurityCardContainerStyle] = useState('account-preferences-card account-preferences-card-minified');
    const [notificationCardStyle, setNotificationCardStyle] = useState('account-preferences-columns-hidden');
    const [notificationCardContainerStyle, setNotificationCardContainerStyle] = useState('account-preferences-card account-preferences-card-minified');
    const [documentDeliveryCardStyle, setDocumentDeliveryCardStyle] = useState('account-preferences-columns-hidden');
    const [documentDeliveryCardContainerStyle, setDocumentDeliveryCardContainerStyle] = useState('account-preferences-card account-preferences-card-minified');
    const [showNotification, setShowNotification] = useState(true);
    const loader = useContext(LoadingContext);
    const [isLoading, setIsLoading] = useState(false);
    const [isEditAccount, setIsEditAccount] = useState(false);
    const [flowComplete, setFlowComplete] = useState(false);
    const [flowCompleteHeader, setFlowCompleteHeader] = useState('');
    const [b2cMFAOptionsURL, setB2CMFAOptionsURL] = useState('');
    const [docDeliveryFormData, setDocDeliveryFormData] = useState({});
    const [isNotificationPrefSet, updateIsNotificationPrefSet] = useState(false);
    const cardStates = [setAccountProfileDetailCardStyle, setLoginNSecurityCardStyle, setNotificationCardStyle, setDocumentDeliveryCardStyle];
    const containerStates = [setAccountProfileDetailContainerStyle, setLoginNSecurityCardContainerStyle, setNotificationCardContainerStyle, setDocumentDeliveryCardContainerStyle];
    const [accountPref, setAccountPref] = useState({ documentDeliveryPref: undefined, notificationPref: undefined, notificationTimezonePref: undefined });
    const [notificationPreference, updateNotificationPreference] = useState({ contactTimeZone: undefined, accountNotification: undefined });
    const [accountContact, setAccountContact] = useState({});
    const [cardBeingEdited, setCardBeingEdited] = useState(false);
    const [saveChangesWarning, setSaveChangesWarning] = useState(false);
    const accountPrefOptions = { paperless: 'paperless', usMail: 'usMail', textMessages: 'textMessages' };
    const validChangeTypes = { documentDelivery: 'documentDelivery', notification: 'notification' };
    const cardStatus = useContext(CustomAccountPrefContext);
    const [newEmail, setNewEmail] = useState(undefined);
    let currentEmail = email;
    let currentPhone = strongAuthenticationPhoneNumber;
    let newPhoneNumber = strongAuthenticationPhoneNumber;

    function openCard(cardName) {
        if (cardName === 'profile') { setAccountProfileDetailCardStyle('account-preferences-columns'); setAccountProfileDetailContainerStyle('account-preferences-card'); }
        if (cardName === 'loginNSecurity') { setLoginNSecurityCardStyle('account-preferences-columns'); setLoginNSecurityCardContainerStyle('account-preferences-card'); }
        if (cardName === 'notification') { setNotificationCardStyle('account-preferences-columns'); setNotificationCardContainerStyle('account-preferences-card'); }
        if (cardName === 'document') { setDocumentDeliveryCardStyle('account-preferences-columns'); setDocumentDeliveryCardContainerStyle('account-preferences-card'); }
        if (cardName === 'paperless') { setDocumentDeliveryCardStyle('account-preferences-columns'); setDocumentDeliveryCardContainerStyle('account-preferences-card'); }
    }

    function closeOtherCards(keepCardActive, keepContainerActive) {
        for (let i = 0; i < 4; i++) {
            if (cardStates[i] !== keepCardActive) { cardStates[i]('account-preferences-columns-hidden'); }
            if (containerStates[i] !== keepContainerActive) { containerStates[i]('account-preferences-card account-preferences-card-minified'); }
        }
    }

    const toggleCard = useCallback((name, componentState, componentFunc, componentContainerFunc, componentClass, hiddenComponentClass, containerClass, hiddenContainerClass) => () => {
        if (!cardBeingEdited) {
            if (componentState === hiddenComponentClass) {
                componentContainerFunc(containerClass);
                componentFunc(componentClass);
                window.accountPref = name;
                closeOtherCards(componentFunc, componentContainerFunc);
            } else {
                window.accountPref = undefined;
                componentContainerFunc(hiddenContainerClass);
                componentFunc(hiddenComponentClass);
            }
        } else { setSaveChangesWarning(true); }
    }, [cardBeingEdited]);

    const expandCard = useCallback((name, componentState, componentFunc, componentContainerFunc, componentClass, hiddenComponentClass, containerClass, hiddenContainerClass) => () => {
        if (componentState === hiddenComponentClass) {
            if (!cardBeingEdited) {
                componentContainerFunc(containerClass);
                componentFunc(componentClass);
                window.accountPref = name;
                closeOtherCards(componentFunc, componentContainerFunc);
            } else { setSaveChangesWarning(true); }
        }
    });

    const dismissNotification = useCallback((notificationType) => () => {
        if (notificationType === 'paperlessNotification') { setShowNotification(false); }
        if (notificationType === 'flowCompleteNotification') { setFlowComplete(false); }
        if (notificationType === 'unsavedChangesNotification') { cardStatus.setShowUnsavedChangesBanner(false); }
    });

    const mapDocDelivery = useCallback((deliveryPref) => {
        let preference = 'none';
        if (deliveryPref !== undefined) { preference = deliveryPref ? accountPrefOptions.paperless : accountPrefOptions.usMail; }
        return preference;
    });

    const updateDocDelivery = useCallback((deliveryPref) => {
        const docDelivery = { policyDocDelivery: 'default' };
        docDelivery.policyDocDelivery = mapDocDelivery(deliveryPref.documentDeliveryPref);
        setDocDeliveryFormData(docDelivery);
    }, [docDeliveryFormData]);

    const changeDeliveryMethod = useCallback(() => () => {
        if (!cardBeingEdited) {
            openCard('document');
            closeOtherCards(setDocumentDeliveryCardStyle, setDocumentDeliveryCardContainerStyle);
            document.getElementById('documentDeliveryCard').scrollIntoView();
        } else {
            setSaveChangesWarning(true);
        }
    });

    const areBothPaperless = useCallback(() => {
        if (!showNotification || (docDeliveryFormData.policyDocDelivery === accountPrefOptions.paperless)) { return false; }
        return true;
    }, [docDeliveryFormData, showNotification]);

    const isNotificationRequired = useCallback(() => {
        // these are in order of greatest precedence.
        if (flowComplete && !cardStatus.showUnsavedChangesBanner) { return { value: true, type: 'flowCompleteNotification', icon: CustomIconUtil.getLogoSrc('success_icon.png'), header: translator(flowCompleteHeader), body: ' ' }; }
        if (cardStatus.showUnsavedChangesBanner) { return { value: true, type: 'unsavedChangesNotification', icon: CustomIconUtil.getLogoSrc('alert_icon.png'), header: translator(messages.unsavedChangedHeader), body: translator(messages.unsavedChangesText) }; }
        if (areBothPaperless() && !flowComplete && !isEditAccount && !cardStatus.showUnsavedChangesBanner) { return { value: true, type: 'paperlessNotification', icon: CustomIconUtil.getLogoSrc('leaf.png'), header: translator(messages.goPaperlessHeader), body: translator(messages.goPaperlessBody) }; }
        return { value: false, type: ' ', icon: ' ', text: ' ' };
    }, [flowComplete, cardStatus.showUnsavedChangesBanner, showNotification, docDeliveryFormData, isEditAccount]);

    function getNotificationForPaperless(currentNotificationPref) {
        switch (currentNotificationPref) {
            case accountPrefTypes.notificationOptionSms.code:
            case accountPrefTypes.notificationOptionBoth.code: return accountPrefTypes.notificationOptionBoth.code;
            case accountPrefTypes.notificationOptionNone.code:
            case accountPrefTypes.notificationOptionEmail.code: return accountPrefTypes.notificationOptionEmail.code;
            default: return currentNotificationPref;
        }
    }

    function updatePrefs(wasNotificationSet) {
        let accPref = [];
        if (wasNotificationSet && accountPref.notificationPref === accountPrefTypes.notificationOptionNone.code) { accountPref.notificationPref = undefined; }
        if (accountPref.documentDeliveryPref !== undefined) {
            accPref = [...accPref, {
                preferenceName: accountPrefTypes.paperlessPolicyDocs.code,
                preferenceValue: accountPref.documentDeliveryPref ? accountPrefTypes.paperlessTrue.code : accountPrefTypes.paperlessFalse.code
            }];
        }
        if (accountPref.notificationPref !== undefined) { accPref = [...accPref, { preferenceName: accountPrefTypes.policyAlerts.code, preferenceValue: accountPref.notificationPref }]; }
        if (accountPref.notificationTimezonePref !== undefined) { accPref = [...accPref, { preferenceName: accountPrefTypes.contactTimezone.code, preferenceValue: accountPref.notificationTimezonePref }]; }
        return accPref;
    }

    const updateAccount = useCallback(async (updatedEmail, updatedPhone) => {
        loader.setLoading(true);
        const isAccountUpdated = await CustomCRMService.updateAccount(authHeader, appConfig.env.CRM_UPDATE_ACCOUNT_PATH, { ciamId: oid, emailAddress: currentEmail, updatedEmailAddress: updatedEmail, currentPhone: currentPhone, updatedMobilePhone: updatedPhone });
        if (isAccountUpdated.contactUpdated === true) {
            await refreshToken();
            setAccountContact({ ...accountContact, emailAddress1: updatedEmail, cellNumber: updatedPhone });
            currentEmail = updatedEmail; currentPhone = updatedPhone;
            if (accountProfile.data.profile !== undefined) { accountProfile.data.profile.contact.emailAddress = updatedEmail; accountProfile.data.profile.contact.mobilePhone = updatedPhone; accountProfile.updateCall(accountProfile.data); }
        }
        loader.setLoading(false);
    }, [accountProfile.data, refreshToken]);

    const updatePagePhoneNumber = useCallback(async (updatedEmail, updatedPhone) => {
        loader.setLoading(true);
        await refreshToken();
        setAccountContact({ ...accountContact, emailAddress1: updatedEmail, cellNumber: updatedPhone });
        currentEmail = updatedEmail; currentPhone = updatedPhone;
        if (accountProfile.data.profile !== undefined) { accountProfile.data.profile.contact.emailAddress = updatedEmail; accountProfile.data.profile.contact.mobilePhone = updatedPhone; accountProfile.updateCall(accountProfile.data); }
        loader.setLoading(false);
    }, [accountProfile.data, refreshToken]);

    const updateAccountPreferences = useCallback((changedField, change) => {
        let wasNotificationSet = isNotificationPrefSet;
        if (changedField === validChangeTypes.documentDelivery) {
            accountPref.documentDeliveryPref = (change.policyDocDelivery === accountPrefOptions.paperless);
            if (change.policyDocDelivery === accountPrefOptions.paperless) { accountPref.notificationPref = getNotificationForPaperless(accountPref.notificationPref); }
        }
        if (changedField === validChangeTypes.notification) {
            accountPref.notificationPref = change.accountNotification;
            accountPref.notificationTimezonePref = change.contactTimeZone;
            wasNotificationSet = false;
            updateIsNotificationPrefSet(false);
        }
        const accPref = updatePrefs(wasNotificationSet);

        setSaveChangesWarning(false);
        setCardBeingEdited(false);
        dismissNotification('unsavedChangesNotification').call();

        Promise.all([
            CustomCRMService.updateAccountPreferences(authHeader, appConfig.env.CRM_CREATE_PREFERENCES_PATH, { ciamId: oid, emailAddress: newEmail || currentEmail, preferences: accPref })
        ]).then((resp) => {
            if (resp[0].preferenceUpdated === true) {
                if (accountPref.notificationPref === undefined) {
                    accPref.notificationPref = accountPrefTypes.notificationOptionNone.code;
                    updateIsNotificationPrefSet(true);
                }
                accountProfile.data.profile.preferences = accPref;
                accountProfile.updateCall({ ...accountProfile.data });
                updateNotificationPreference({ contactTimeZone: accountPref.notificationTimezonePref, accountNotification: accountPref.notificationPref });
                setAccountPref(accountPref);
                updateDocDelivery(accountPref);
                setIsLoading(false);
            }
        }).finally(() => loader.setLoading(false));
    }, [accountPref, authHeader, email, oid, isNotificationPrefSet, notificationPreference]);

    let previousHeight;

    const editAccountToggle = useCallback( (selectedTenant) => () => {
        previousHeight = sessionStorage.getItem("previousHeight");
        setB2CMFAOptionsURL(`${env.B2C_RECOVER_URL}${selectedTenant}/oauth2/v2.0/authorize?client_id=${aud}&redirect_uri=${env.DEPLOYMENT_URL}/userprofile&response_type=id_token&scope=openid&response_mode=form_post&state=vJSor51Sdjb9s6gP&nonce=${nonce}`); setIsEditAccount((prevStatus) => !prevStatus);
        if(previousHeight > 0){
            setTimeout(function() {
                let iframe = document.getElementsByClassName('jut__IFrame__iframeContainer')[0];
                iframe.setAttribute("style", `padding-bottom: 50%; height: ${previousHeight}px;`);
                sessionStorage.removeItem("previousHeight");
            }, 1);
        }
     });

    let cardHeight;
    function changeHeight(iframeHeight) {
        if (cardHeight !== iframeHeight) {
            document.getElementsByClassName('jut__IFrame__iframeContainer')[0].style.height = `${iframeHeight}px`;
            cardHeight = iframeHeight;
        }
    }

    function closeIframe() {
        previousHeight = document.getElementsByClassName('jut__IFrame__iframeContainer')[0].style.height;
        previousHeight = previousHeight.substring(0, previousHeight.length - 2);
        previousHeight = parseInt(previousHeight);
        sessionStorage.setItem("previousHeight", previousHeight);
        setIsEditAccount(false); 
        cardHeight = 0;
    }

    useEffect(() => {
        function completeFlow(e) {
            switch (e.data[0]) {
                case 'phoneFlowComplete': closeIframe(); updatePagePhoneNumber(currentEmail, newPhoneNumber); sessionStorage.removeItem("previousHeight"); /*updateAccount(currentEmail, newPhoneNumber);*/ setFlowCompleteHeader(translator(messages.updatePhoneHeader)); setFlowComplete(true); window.scroll(0, 0); break;
                case 'newPhoneNumber': newPhoneNumber = e.data[1]; break;
                case 'editFlowComplete': closeIframe(); sessionStorage.removeItem("previousHeight"); setNewEmail(e.data[1]); updateAccount(e.data[1], currentPhone); setFlowCompleteHeader(translator(messages.updateUserHeader)); setFlowComplete(true); window.scroll(0, 0); break;
                case 'flowComplete': closeIframe(); sessionStorage.removeItem("previousHeight"); setFlowCompleteHeader(translator(messages.updatePasswordHeader)); setFlowComplete(true); window.scroll(0, 0); break;
                case 'flowStart': setFlowComplete(false); window.scroll(0, 0); break;
                case 'mfaFail': window.location.href = authConfig.endpoints.logout; break;
                case 'setHeight': sessionStorage.removeItem("previousHeight"); changeHeight(e.data[1]); break;
                default: break;
            }
        }
        window.addEventListener('message', completeFlow);
        return () => window.removeEventListener('message', completeFlow);
    }, []);


    const getAccountPrefData = () => {
        if (accountProfile.data && accountProfile.data.success) {
            const { preferences, contact } = accountProfile.data.profile;
            let policyAlertsPref;
            let paperlessPolicyDocsPref;
            let contactTimezonePref;
            const policyAlerts = preferences.find((e) => e.preferenceName === accountPrefTypes.policyAlerts.code);
            if (policyAlerts) { policyAlertsPref = policyAlerts.preferenceValue; }
            const paperlessPolicyDocs = preferences.find((e) => e.preferenceName === accountPrefTypes.paperlessPolicyDocs.code);
            if (paperlessPolicyDocs) { paperlessPolicyDocsPref = paperlessPolicyDocs.preferenceValue === accountPrefTypes.paperlessTrue.code; }
            const contactTimezone = preferences.find((e) => e.preferenceName === accountPrefTypes.contactTimezone.code);
            if (contactTimezone) { contactTimezonePref = contactTimezone.preferenceValue; }
            const accPref = { ...accountPref, notificationPref: policyAlertsPref, documentDeliveryPref: paperlessPolicyDocsPref, notificationTimezonePref: contactTimezonePref };

            if (accPref.notificationPref === undefined) {
                accPref.notificationPref = accountPrefTypes.notificationOptionNone.code;
                updateIsNotificationPrefSet(true);
            }
            setAccountPref(accPref);
            updateNotificationPreference({ contactTimeZone: accPref.notificationTimezonePref, accountNotification: accPref.notificationPref });
            updateDocDelivery(accPref);
            setAccountContact({ cellNumber: contact.mobilePhone, emailAddress1: contact.emailAddress });
            window.scroll(0, 0);
            loader.setLoading(false);
        }
    };

    useEffect(() => {
        if (window.accountPref !== undefined) { openCard(window.accountPref); }
        if (accountProfile.data.profile !== undefined && accountProfile.data.profile.preferences) { getAccountPrefData(); }
        if (window.accountPref === 'paperless') {
            document.getElementById('documentDeliveryCard').scrollIntoView();
            window.accountPref = 'document';
        }
    }, [accountProfile.data.profile]);

    useEffect(() => {
        cardStatus.updateCardBeingEdited(cardBeingEdited);
    }, [cardBeingEdited]);

    useEffect(() => {
        if (cardStatus.showUnsavedChangesBanner) { window.scroll(0, 0); }
    }, [cardStatus.showUnsavedChangesBanner]);

    const overrideProps = {
        pageContainer: {
            visible: !isEditAccount
        },
        toggleAccountProfileCardIcon: {
            src: (accountProfileDetailCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleCard('profile', accountProfileDetailCardStyle, setAccountProfileDetailCardStyle, setAccountProfileDetailContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        accountProfileDiv: {
            className: accountProfileDetailCardStyle
        },
        accountProfileCard: {
            className: accountProfileDetailContainerStyle,
            src: (accountProfileDetailCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: expandCard('profile', accountProfileDetailCardStyle, setAccountProfileDetailCardStyle, setAccountProfileDetailContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        toggleLoginNSecurityCardIcon: {
            src: (loginNSecurityCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleCard('loginNSecurity', loginNSecurityCardStyle, setLoginNSecurityCardStyle, setLoginNSecurityCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        loginNSecurityDiv: {
            className: loginNSecurityCardStyle
        },
        loginNSecurityCard: {
            className: loginNSecurityCardContainerStyle,
            src: (loginNSecurityCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: expandCard('loginNSecurity', loginNSecurityCardStyle, setLoginNSecurityCardStyle, setLoginNSecurityCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        toggleNotificationsCardIcon: {
            src: (notificationCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleCard('notification', notificationCardStyle, setNotificationCardStyle, setNotificationCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        notificationsCard: {
            className: notificationCardContainerStyle,
            src: (notificationCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: expandCard('notification', notificationCardStyle, setNotificationCardStyle, setNotificationCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        notificationsDiv: {
            className: notificationCardStyle
        },
        toggleDocumentDeliveryCardIcon: {
            src: (documentDeliveryCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: toggleCard('document', documentDeliveryCardStyle, setDocumentDeliveryCardStyle, setDocumentDeliveryCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        documentDeliveryDiv: {
            className: documentDeliveryCardStyle
        },
        documentDeliveryCard: {
            className: documentDeliveryCardContainerStyle,
            src: (documentDeliveryCardStyle === 'account-preferences-columns-hidden') ? CustomIconUtil.getLogoSrc('expand-card.png') : CustomIconUtil.getLogoSrc('collapse-card.png'),
            onClick: expandCard('document', documentDeliveryCardStyle, setDocumentDeliveryCardStyle, setDocumentDeliveryCardContainerStyle, 'account-preferences-columns', 'account-preferences-columns-hidden', 'account-preferences-card', 'account-preferences-card account-preferences-card-minified')
        },
        infoLogo: {
            src: isNotificationRequired().value === true ? isNotificationRequired().icon : ' ',
            className: 'iconChecked'
        },
        dismissLogo: {
            src: CustomIconUtil.getLogoSrc('dismiss-icon.png')
        },
        dismissButton: {
            onClick: dismissNotification(isNotificationRequired().type)
        },
        dismissButtonEdit:{
            onClick: closeIframe
        },
        dismissButtonMobile: {
            onClick: dismissNotification(isNotificationRequired().type)
        },
        dismissButtonMobileEdit: {
           onClick: closeIframe
        },
        dismissLogoMobile: {
            src: CustomIconUtil.getLogoSrc('dismiss-icon.png')
        },
        changeDeliveryMethodButton: {
            visible: isNotificationRequired().type === 'paperlessNotification',
            onClick: changeDeliveryMethod(),
            content: isNotificationRequired().type === 'paperlessNotification' ? 'Change delivery method' : ' '
        },
        customNotificationContainer: {
            visible: isNotificationRequired().value === true,
            className: isNotificationRequired().type
        },
        customHeading: {
            content: isNotificationRequired().value === true ? isNotificationRequired().header : ' '
        },
        customText: {
            content: isNotificationRequired().value === true ? isNotificationRequired().body : ' '
        },
        documentDeliverySection: {
            deliveryMethods: setDocDeliveryFormData,
            deliveryData: docDeliveryFormData,
            updatePref: updateAccountPreferences,
            setToEditMode: setCardBeingEdited,
            showSaveChangesWarning: saveChangesWarning,
            setSaveChangesWarning: setSaveChangesWarning,
            dismissNotification: dismissNotification,
            setLoadingState: setIsLoading,
            isLoading: isLoading
        },
        loginSecuritySection: {
            userDetails: accountContact,
            toggleAccount: editAccountToggle
        },
        accountProfileSection: {
            userDetails: accountContact,
            toggleAccount: editAccountToggle
        },
        notificationSection: {
            notificationDetails: notificationPreference,
            documentDetails: accountPref.documentDeliveryPref,
            updatePref: updateAccountPreferences,
            setToEditMode: setCardBeingEdited,
            showSaveChangesWarning: saveChangesWarning,
            setSaveChangesWarning: setSaveChangesWarning,
            dismissNotification: dismissNotification,
            setLoadingState: setIsLoading,
            isLoading: isLoading
        },
        b2cFrame: {
            visible: isEditAccount,
            src: b2cMFAOptionsURL
        },
    };

    const resolvers = {
        resolveClassNameMap: styles,
        resolveComponentMap: {
            customnotificationscomponent: CustomNotificationsComponent,
            documentdeliverycomponent: DocumentDeliveryComponent,
            accountProfileComponent: CustomAccountProfileComponent,
            loginsecuritycomponent: CustomLoginSecurityComponent
        },
    };
    return renderContentFromMetadata(metadata.pageContent, overrideProps, resolvers);
}

CustomAccountPrefPage.propTypes = { authHeader: PropTypes.shape({}).isRequired };

export default withAuthenticationContext(withRouter(CustomAccountPrefPage));
