import {
    useContext,
    useCallback,
    useState
} from 'react';

import _ from 'lodash';

import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { CustomFormatterUtil } from 'gw-portals-util-js';
import metadata from './CustomPaymentScheduleComponent.metadata.json5';
import styles from './CustomPaymentScheduleComponent.module.scss';
import messages from './CustomPaymentScheduleComponent.messages';
function CustomPaymentSchedule(props) {
    const { selectedPaymentSchedule } = props;
    const translator = useContext(TranslatorContext);    
    const [style, setStyle] = useState("hideDetails");

    const toggleDetails = useCallback(() => () => {
        if (style === "hideDetails") {
            setStyle("displayDetails");
        }
        else {
            setStyle("hideDetails");
        }

    }, [style]);

    const overrideProps = {
        "dueDate": {
            value: CustomFormatterUtil.formatDate(selectedPaymentSchedule.dueDate),
            label: translator(messages.dueDate)
        },
        "amountBilled": {
            value: CustomFormatterUtil.formatAmount(selectedPaymentSchedule.totalValue.amount),
            label: translator(messages.amountBilled)
        },
        "paid": {
            value: CustomFormatterUtil.formatAmount(selectedPaymentSchedule.totalPaid.amount),
            label: translator(messages.paid)
        },
        "currentDue": {
            value: CustomFormatterUtil.formatAmount(selectedPaymentSchedule.amountDue.amount),
            label: translator(messages.currentDue)
        },
        "pastDue": {
            value: CustomFormatterUtil.formatAmount(selectedPaymentSchedule.pastDueAmount_Ext.amount),
            label: translator(messages.pastDue)
        },
        "toggleDetails": {
            className: style
        },
        "chevronIcon": {
            onClick: toggleDetails(),
            className: (style === "displayDetails") ? "chevron-flip payment_chevron" : "payment_chevron"
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomPaymentSchedule.propTypes = {
};

export default CustomPaymentSchedule;
