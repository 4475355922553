import React, { useContext, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { TranslatorContext } from '@jutro/locale';
import { Chevron } from '@jutro/components';
import { Grid } from '@jutro/layout';
import policyChangeQuote from './PolicyChangeQuoteComponent.module.scss';
import messages from './PolicyChangeQuoteComponent.messages';

function PolicyChangeQuoteComponent(props) {
    const translator = useContext(TranslatorContext);
    const [accordionHeaders] = useState([]);
    const COLUMN_CONFIGURATION = ['auto', '12rem', '12rem'];
    const sanitizeText = useCallback((dirtyString) => {
        return dirtyString.replace(/[^A-Za-z0-9]/gi, '');
    }, []);

    const renderAccordionHeader = useCallback((isOpen, accordionTitle) => {
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="left" className={policyChangeQuote.chevronStyle} />
                <Grid
                    columns={COLUMN_CONFIGURATION}
                    gap="large"
                    className={policyChangeQuote.gridMargin}
                >
                    <span className={policyChangeQuote.rootLabel}>{accordionTitle.label}</span>
                    <span className={policyChangeQuote.gridColumn}>{accordionTitle.value1}</span>
                    <span className={policyChangeQuote.gridColumn}>{accordionTitle.value2}</span>
                </Grid>
            </React.Fragment>
        );
    }, [COLUMN_CONFIGURATION]);

    const renderMainAccordionHeader = useCallback((isOpen) => {
        const accordionTitle = translator(messages.quoteChangeTitle);
        return (
            <React.Fragment>
                <Chevron isOpen={isOpen} align="right" className={policyChangeQuote.chevronStyle} />
                <h2 className={policyChangeQuote.renderAccordionHeader}>
                    <span className={policyChangeQuote.title}>{accordionTitle}</span>
                </h2>
                <hr className={policyChangeQuote.titleSeparator} />
            </React.Fragment>
        );
    }, [translator]);

    const generateAllAccordionHeaders = useCallback(() => {
        const overrides = accordionHeaders.map((accordionHeader) => {
            return {
                [`quoteCard${sanitizeText(accordionHeader.label)}`]: {
                    renderHeader: (isOpen) => {
                        return renderAccordionHeader(isOpen, accordionHeader);
                    }
                }
            };
        });

        return Object.assign({}, ...overrides);
    }, [accordionHeaders, renderAccordionHeader, sanitizeText]);

    const generateQuoteGrid = useCallback((currentNode) => {
        return {
            id: `quoteRow${sanitizeText(currentNode.label)}`,
            type: 'container',
            component: 'Grid',
            componentProps: {
                columns: COLUMN_CONFIGURATION,
                gap: 'large',
                className: 'gridMargin'
            },
            content: [
                {
                    id: `quoteRowLabel${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    content: currentNode.label
                },
                {
                    id: `quoteRowValue1${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    componentProps: {
                        className: 'gridColumn'
                    },
                    content: currentNode.value1
                },
                {
                    id: `quoteRowValue2${sanitizeText(currentNode.label)}`,
                    type: 'element',
                    component: 'span',
                    componentProps: {
                        className: 'gridColumn'
                    },
                    content: currentNode.value2
                }
            ]
        };
    }, [COLUMN_CONFIGURATION, sanitizeText]);

    const generateQuoteTable = useCallback(
        (currentNodes) => {
            return currentNodes.map((currentNode) => {
                if (currentNode.isLeaf) {
                    return generateQuoteGrid(currentNode);
                }

                accordionHeaders.push(currentNode);
                return {
                    id: `quote${sanitizeText(currentNode.label)}`,
                    type: 'container',
                    component: 'accordion',
                    componentProps: {
                        closeOthers: false,
                        showFrame: false,
                        boldFont: false,
                        chevronAlignment: 'left',
                        defaultOpenedId: `quoteCard${sanitizeText(currentNode.label)}`
                    },
                    content: [
                        {
                            id: `quoteCard${sanitizeText(currentNode.label)}`,
                            type: 'element',
                            component: 'accordioncard',
                            componentProps: {
                                cardBodyClassName: 'cardBodyMargins'
                            },
                            content: generateQuoteTable(currentNode.children)
                        }
                    ]
                };
            });
        },
        [accordionHeaders, generateQuoteGrid, sanitizeText]
    );

    const renderQuoteTable = useCallback(() => {
        const { quote, id } = props;

        const quoteChangeTitle = {
            id: 'quoteTitle',
            type: 'container',
            component: 'Grid',
            componentProps: {
                columns: COLUMN_CONFIGURATION,
                gap: 'none'
            },
            content: [
                {
                    id: 'quoteTitleItem',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeItem
                },
                {
                    id: 'quoteTitleExisting',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeExisting
                },
                {
                    id: 'quoteTitleNew',
                    type: 'element',
                    component: 'div',
                    componentProps: {
                        className: 'gridTitle'
                    },
                    content: messages.quoteChangeNew
                }
            ]
        };

        const metadata = generateQuoteTable(quote);
        metadata.unshift(quoteChangeTitle);

        return {
            content: [
                {
                    id: `quote${id}`,
                    type: 'container',
                    component: 'accordion',
                    componentProps: {
                        closeOthers: false,
                        showFrame: false,
                        chevronAlignment: 'right',
                        defaultOpenedId: `quoteCard${id}`
                    },
                    content: [
                        {
                            id: `quoteCard${id}`,
                            type: 'container',
                            component: 'accordioncard',
                            componentProps: {
                                cardBodyClassName: 'cardBodyMainMargins'
                            },
                            content: metadata
                        }
                    ]
                }
            ]
        };
    }, [COLUMN_CONFIGURATION, generateQuoteTable, props]);

    const resolvers = {
        resolveClassNameMap: policyChangeQuote
    };
    const { id } = props;
    const generatedMetadata = renderQuoteTable();
    const overrides = {
        [`quoteCard${id}`]: {
            renderHeader: renderMainAccordionHeader
        },
        ...generateAllAccordionHeaders()
    };
    return renderContentFromMetadata(generatedMetadata, overrides, resolvers);
}

PolicyChangeQuoteComponent.propTypes = {
    id: PropTypes.string.isRequired,
    quote: PropTypes.arrayOf(PropTypes.shape({}))
};
PolicyChangeQuoteComponent.defaultProps = {
    quote: undefined
};
export default PolicyChangeQuoteComponent;
