import React, { useCallback, useContext } from 'react';
import _ from 'lodash';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { TranslatorContext } from '@jutro/locale';
import { WizardPage, wizardProps } from 'gw-portals-wizard-react';
import { useDependencies } from 'gw-portals-dependency-react';
import { withAuthenticationContext } from 'gw-digital-auth-react';
import { useValidation } from 'gw-portals-validation-react';
import { messages as paymentMessages } from 'gw-capability-policychange-common-react';
import metadata from './ValuablesPage.metadata.json5';
import './ValuablesPage.messages';


function ValuablesPage(props) {
    const translator = useContext(TranslatorContext);
    const {
        wizardData, updateWizardData, authHeader
    } = props;
    const { submissionVM } = wizardData;
    const { EndorsementService } = useDependencies('EndorsementService');
    const { initialValidation, isComponentValid } = useValidation('ValuablesPage');

    const writeValue = useCallback(
        (value, path) => {
            _.set(submissionVM, path, value);
            updateWizardData(wizardData);
        },
        [submissionVM, updateWizardData, wizardData]
    );

    const onUpdateCustomQuote = useCallback(
        (_basePath, lobPath) => {
            const changedPath = lobPath.replace(/.children/, '');
            return EndorsementService.saveEndorsement([submissionVM.value], authHeader).then(
                (response) => {
                    const updatedClauses = _.get(response, `${changedPath}.coverages`);
                    _.set(submissionVM, `${lobPath}.coverages`, updatedClauses);
                    updateWizardData(wizardData);
                }
            );
        },
        [EndorsementService, authHeader, submissionVM, updateWizardData, wizardData]
    );

    const onScheduleChange = useCallback(
        (schedule, path) => {
            const lobOfferingPath = 'lobData.homeowners.offerings.children[0]';
            writeValue(schedule, path);
            return onUpdateCustomQuote({}, lobOfferingPath);
        },
        [onUpdateCustomQuote, writeValue]
    );

    const onNext = useCallback(async () => {
        submissionVM.value = await EndorsementService.saveEndorsement(
            [submissionVM.value],
            authHeader
        );
        return wizardData;
    }, [EndorsementService, authHeader, submissionVM, wizardData]);

    const overrideProps = {
        '@field': {
            labelPosition: 'left'
        }
    };

    const resolvers = {
        resolveCallbackMap: {
            onScheduleChange: onScheduleChange
        }
    };

    return (
        <WizardPage
            onNext={onNext}
            disableNext={!isComponentValid}
            skipWhen={initialValidation}
            cancelLabel={translator(appConfig.persona === 'policyholder' ? paymentMessages.cancelAllChanges : paymentMessages.cancel)}
            showPrevious
        >
            <ViewModelForm
                uiProps={metadata.pageContent}
                model={submissionVM}
                overrideProps={overrideProps}
                callbackMap={resolvers.resolveCallbackMap}
            />
        </WizardPage>
    );
}

ValuablesPage.propTypes = wizardProps;
export default withAuthenticationContext(ValuablesPage);
