import React, { useCallback, useContext, useState } from 'react';
import _ from 'lodash';
import { MetadataForm } from '@jutro/uiconfig';
import { BreakpointTrackerContext } from '@jutro/layout';
import { TranslatorContext } from '@jutro/locale';
import { DropdownMenuSeparator } from '@jutro/router';
import { Avatar } from '@jutro/components';
import { CustomIconUtil, CustomPolicyTypeUtil, CustomFormatterUtil } from 'gw-portals-util-js';
import metadata from './CustomStickyCardComponent.metadata.json5';
import styles from './CustomStickyCardComponent.module.scss';
import messages from './CustomStickyCardComponent.messages.js';

function CustomStickyCardComponent(props) {
    const emptyString = '';

    const translator = useContext(TranslatorContext);
    const breakpoint = useContext(BreakpointTrackerContext);
    const isMobile = (breakpoint === 'phoneWide' || breakpoint === 'phone' || breakpoint === 'tablet');
    const [style, setStyle] = props.openCard ? useState('agentDisplay') : useState('agentHidden');

    const toggleAgentCard = useCallback(() => () => {
        if (style === 'agentHidden') {
            setStyle('agentDisplay');
        } else {
            setStyle('agentHidden');
        }
    });

    const getPolicyTypeIcon = (policyType) => {
        policyType = (policyType === 'Homeowners FLEX') ? 'Homeowners' : policyType.replace(/ +/g, emptyString);
        const icon = CustomIconUtil.getCustomPolicyTypeIcon(policyType);
        return icon;
    };

    const getPhoneIcon = useCallback(() => {
        return isMobile ? CustomIconUtil.getLogoSrc('phone-icon-dark.png') : CustomIconUtil.getLogoSrc('phone-icon.png');
    });

    const getAgentCards = useCallback(() => {
        var agentCard = [];
        props.agentDetails.policies.map((policyData) => {
            agentCard.push(
                <>
                    <a href={'/account-policy-details/'+policyData.policy_number.substring(1)} className={styles.stickyCardDetailPolicyNumber}>{policyData.policy_number}</a>
                    <div className={styles.customAgentCard}>
                        <Avatar imageSource={CustomIconUtil.getLogoSrc('avatar-icon.png')} className={styles.stickyAva}></Avatar>
                        <span className={styles.stickyCardDetail}>{policyData.agent_contact.producerCodeOfServiceProducer_Ext}</span>
                    </div>
                    <div className={styles.customStickyCardDetails}>
                        <Avatar id="stickyAvatarPhone" imageSource={getPhoneIcon()} className={styles.stickyAvatarLogo}></Avatar>
                        <a href={'tel:'+policyData.agent_contact.producerCodeOfServiceProducerPhone_Ext} className={styles.stickyCardDetailPhone}>{CustomFormatterUtil.formatPhoneNumber(policyData.agent_contact.producerCodeOfServiceProducerPhone_Ext)}</a>
                    </div>
                    <div className={styles.customStickyCardDetails}>
                        <Avatar id="stickyAvatarEmail" imageSource={CustomIconUtil.getLogoSrc('email-icon-dark.png')} className={styles.stickyAvatarLogo}></Avatar>
                        <a href={'mailto:' + policyData.agent_contact.producerCodeOfServiceProducerEmail_Ext} className={styles.stickyCardDetailEmail}>{policyData.agent_contact.producerCodeOfServiceProducerEmail_Ext}</a>
                    </div>
                    <DropdownMenuSeparator className={styles.customSeparator}></DropdownMenuSeparator></>
            );
        });

        return agentCard;
    });

    const overrideProps = {

        chevronIcon: {
            onClick: toggleAgentCard(),
            className: (style === 'agentDisplay') ? 'chevron-flip chevron' : 'chevron'
        },
        policyAgent: {
            className: style,
            content: getAgentCards()
        },
        stickyCardTitle: {
            content: (props.agentDetails.policy_type === 'Homeowners FLEX') ? CustomPolicyTypeUtil.getHOFlexTitle(true) : translator(messages.policyTypeTitle, {
                type: props.agentDetails.policy_type
            }),
            onClick: toggleAgentCard(),
        },
        stickyAvatar: {
            imageSource: getPolicyTypeIcon(props.agentDetails.policy_type)
        }

    };

    const resolvers = {
        resolveClassNameMap: styles,
    };

    return (
        <MetadataForm
            uiProps={metadata.componentContent}
            classNameMap={styles}
            overrideProps={overrideProps}
            componentMap={resolvers.resolveComponentMap}
            callbackMap={resolvers.resolveCallbackMap}
        />
    );
}

CustomStickyCardComponent.defaultProps = {
};

CustomStickyCardComponent.propTypes = {
};

export default CustomStickyCardComponent;
