import { defineMessages } from 'react-intl';

export default defineMessages({
    policychangeQuote: {
        id: 'endorsement.common.views.Quote',
        defaultMessage: 'Quote',
    },
    policychangeQuoteCurrentPremium: {
        id: 'endorsement.common.views.Current Premium',
        defaultMessage: 'Current Premium:',
    },
    policychangeAdjustedPremium: {
        id: 'endorsement.common.views.history.Adjusted Premium',
        defaultMessage: 'Adjusted Premium:',
    },
    policychangeQuotePremiumDifference: {
        id: 'endorsement.common.views.history.Premium Difference',
        defaultMessage: 'Premium Difference:',
    },
    policychangeQuotePolicyChangeEffectiveDate: {
        id: 'endorsement.common.views.history.Policy Change Effective Date',
        defaultMessage: 'Policy Change Effective Date'
    },
    policychangeQuotePolicyDetails: {
        id: 'endorsement.common.views.history.Policy Details',
        defaultMessage: 'Policy Details'
    },
    policychangeQuotePolicyNumber: {
        id: 'endorsement.common.views.history.Policy Number',
        defaultMessage: 'Policy Number'
    },
    policychangeQuotePolicyPeriod: {
        id: 'endorsement.common.views.history.Policy Period',
        defaultMessage: 'Policy Period'
    },
    policychangeQuoteAddress: {
        id: 'endorsement.common.views.history.Address',
        defaultMessage: 'Address'
    },
    policychangeQuoteDrivers: {
        id: 'endorsement.common.views.history.Drivers',
        defaultMessage: 'Drivers'
    },
    policychangeQuoteVehicles: {
        id: 'endorsement.common.views.history.Vehicles',
        defaultMessage: 'Vehicles'
    },
    quote: {
        id: 'endorsement.pa.directives.templates.pa-quote.Quote',
        defaultMessage: 'Quote',
    },
    paPolicychangeQuoteTitle: {
        id: 'portal.components.transactionWizardTitle.transactionWizardTitle.Policy Changes - Personal Auto',
        defaultMessage: 'Policy Changes - Personal Auto',
    },
    locationsAndBuildings: {
        id: 'gw-portals-policytransaction-pc-bop-angular.config.FlowConfig.Locations and Buildings',
        defaultMessage: 'Locations and Buildings',
    },
    policyChanges: {
        id: 'endorsement.bop.directives.templates.bop-policy-changes.Policy Changes',
        defaultMessage: 'Policy Changes',
    },
    payments: {
        id: 'gw-portals-bop-components-angular.Payments',
        defaultMessage: 'Payments',
    },
    noDriverError: {
        id: 'endorsement.pa.views.PASelectAction.No driver error',
        defaultMessage: 'Please assign a vehicle to {driver}'
    },
    noVehicleError: {
        id: 'endorsement.pa.views.PASelectAction.No vehicle error',
        defaultMessage: 'No driver assigned to {vehicle}'
    },
    changesConfirmed: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmationCtrl.Your changes have been confirmed.',
        defaultMessage: 'Your changes have been confirmed.'
    },
    referenceNumber: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.Reference Number',
        defaultMessage: 'Reference Number: {refNumber}'
    },
    paymentSchedule: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.You can check the payment schedule...',
        defaultMessage: 'You can check the payment schedule and invoices here: {link}'
    },
    viewPolicy: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.View Policy',
        defaultMessage: 'View Policy'
    },
    lobPolicy: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.LOB policy',
        defaultMessage: '{lob} policy: {policyNumber}'
    },
    policyBillingPage: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.Policy Billing Page',
        defaultMessage: 'Policy Billing Page'
    },
    viewJobDetails: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmation.View Job Details',
        defaultMessage: 'View {jobType} Details'
    },
    premiumIncrease: {
        id: 'endorsement.common.views.Your Premium Will Increase by',
        defaultMessage: 'Your Premium Will Increase by {newPremium}.This change will be reflected in the upcoming invoices. The policy change effective date is {effectiveDate}.'
    },
    premiumDecrease: {
        id: 'endorsement.common.views.Your Premium Will Decrease by',
        defaultMessage: 'Your premium will decrease by {newPremium}.This change will be reflected in the upcoming invoices. The policy change effective date is {effectiveDate}.'
    },
    nochanges: {
        id: 'gw-portals-policytransaction-common-angular.components.quote.quote.There are no changes',
        defaultMessage: 'There are no changes to the premium.'
    },
    confirmedTransaction: {
        id: 'gw-portals-policytransaction-common-angular.TransactionConfirmation.TransactionConfirmationCtrl.Your transaction has been confirmed.',
        defaultMessage: 'Your transaction has been confirmed.'
    },
    unableToDraftPolicy: {
        id: 'endorsement.common.config.Unable to save draft policy change',
        defaultMessage: 'Unable to save draft policy change'
    },
    anErrorOccurred: {
        id: 'endorsement.common.config.An error occurred while attempting to start the policy change.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    unableToStartPolicy: {
        id: 'endorsement.common.config.Unable to start policy change',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    unableToLoadDraftPolicy: {
        id: 'endorsement.common.config.Unable to load draft policy change',
        defaultMessage: 'Unable to Load Draft Policy Change'
    },
    somethingWentWrong: {
        id: 'endorsement.common.config.An error occurred while attempting to load the policy change.',
        defaultMessage: 'Something went wrong during this process. Please try again.'
    },
    cancelAllChanges: {
        id: 'endorsement.common.view.Cancel All Changes',
        defaultMessage: 'Cancel All Changes'
    },
    cancel: {
        id: 'endorsement.common.views.payment.Cancel',
        defaultMessage: 'Cancel'
    }
});
