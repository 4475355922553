import { defineMessages } from 'react-intl';

export default defineMessages({
    addAPolicy: {
        id: 'enrollment.components.enrollment.Add a policy',
        defaultMessage: 'Add a policy'
    },
    reenrollAPolicy: {
        id: 'enrollment.components.enrollment.Reenroll a policy',
        defaultMessage: 'Re-enroll your policy'
    },
    policyTypeIndividual: {
        id: 'enrollment.components.enrollment.Policy type individual',
        defaultMessage: 'Individual'
    },
    policyTypeBusiness: {
        id: 'enrollment.components.enrollment.Policy type business',
        defaultMessage: 'Business, LLC, Trust or Estate'
    },
    enroll: {
        id: 'enrollment.components.enrollment.Enroll',
        defaultMessage: 'Enroll'
    },
    enrollmentFailed: {
        id: 'enrollment.components.enrollment.The policy you entered is ineligible to add to the account',
        defaultMessage: 'The policy you entered is ineligible to add to the account. For further help, send an email to onlineservices@amig.com'
    },
    enrollmentInvalid: {
        id: 'enrollment.components.enrollment.Sorry your enrollment request is invalid',
        defaultMessage: 'Sorry your enrollment request is invalid'
    },
    enrollmentSuccess: {
        id: 'enrollment.components.enrollment.Enrollment Successful',
        defaultMessage: 'Enrollment Successful.'
    },
    fieldEmpty: {
        id: 'enrollment.components.enrollment.Enter your policy number and the first line of your address to associate your policy with this account',
        defaultMessage: 'Enter your policy number and the first line of your address to associate your policy with this account'
    },
    enterPolicyDetails: {
        id: 'enrollment.components.enrollment.Enter policy details',
        defaultMessage: 'We need to verify your identity in order to keep your account secure. If you\'d like to add a new policy, please enter the policy details below. We\'ll automatically add policies that are associated.'
    },
    enterPolicyError: {
        id: 'enrollment.components.enrollment.Enter policy error',
        defaultMessage: 'Due to technical issues on our end, there was an error creating your account. Please re-enter your policy details below. This information is the same information you entered when setting up your account. If you require further assistance, please call Customer Service at 1-800-543-2644.'
    },
    inValidRequest: {
        id: 'enrollment.components.enrollment.Invalid enrollment request',
        defaultMessage: 'Invalid enrollment request.'
    },
    connectedPolicy: {
        id: 'enrollment.components.enrollment.Connected to Other Account',
        defaultMessage: 'The policy you entered is connected to another account.'
    },
});
