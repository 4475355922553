import { defineMessages } from 'react-intl';

export default defineMessages({
    contactName: {
        id: 'policy.common.contacts.component.policy-contacts-Name',
        defaultMessage: 'Name'
    },
    contactRole: {
        id: 'policy.common.contacts.component.policy-contacts-Roles',
        defaultMessage: 'Roles'
    },
    contactEmail: {
        id: 'policy.common.contacts.component.policy-contacts-Email',
        defaultMessage: 'Email'
    },
    contactPhone: {
        id: 'policy.common.contacts.component.policy-contacts-Phone number',
        defaultMessage: 'Phone number'
    },
    contactAddress: {
        id: 'policy.common.contacts.component.policy-contacts-Address',
        defaultMessage: 'Address'
    },
    contactDOB: {
        id: 'policy.common.contacts.component.policy-contacts-Date of birth',
        defaultMessage: 'Date of birth'
    },
    contactYourAgent: {
        id: "policy.contacts.card.contactYourAgentText",
        defaultMessage: "To update any contact information shown, please contact your agent at "
    }
});
