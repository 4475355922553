function initializeHierarchy() {
    const personType = {
        name: 'Person'
    };
    const companyType = {
        name: 'Company'
    };
    const placeType = {
        name: 'Place'
    };
    const companyVendorType = {
        name: 'CompanyVendor',
        parent: companyType
    };
    const personVendorType = {
        name: 'PersonVendor',
        parent: personType
    };
    return [
        companyType,
        companyVendorType, {
            name: 'AutoRepairShop',
            parent: companyVendorType
        }, {
            name: 'AutoTowingAgcy',
            parent: companyVendorType
        }, {
            name: 'LawFirm',
            parent: companyVendorType
        }, {
            name: 'MedicalCareOrg',
            parent: companyVendorType
        },
        personType, {
            name: 'Adjudicator',
            parent: personType
        },
        personVendorType, {
            name: 'Attorney',
            parent: personVendorType
        }, {
            name: 'Doctor',
            parent: personVendorType
        }, {
            name: 'UserContact',
            parent: personType
        },
        placeType, {
            name: 'LegalVenue',
            parent: placeType
        }
    ];
}
const contactTypes = initializeHierarchy();
function isInstanceOf(contact, contactType) {
    let foundType = contactTypes.find((value) => {
        return value.name === contact.contactdetails.subtype;
    });
    while (foundType && foundType.name !== contactType) {
        foundType = foundType.parent;
    }
    return !!foundType;
}
export default {
    isInstanceOf
};
