import { defineMessages } from 'react-intl';

export default defineMessages({
    faq: {
        id: 'portal.index.FAQ',
        defaultMessage: 'FAQ'
    },
    home: {
        id: 'app.html.index.My policies',
        defaultMessage: 'My policies'
    },
    additionalEnrollment: {
        id: 'portal.index.Additional Enrollment',
        defaultMessage: 'Additional Enrollment'
    },
    makeAPayment: {
        id: 'portal.index.Make a Payment',
        defaultMessage: 'Make a Payment'
    },
    billingSummary: {
        id: 'portal.index.Billing and payment',
        defaultMessage: 'Billing and payment'
    },
    claimList: {
        id: 'portal.index.Claims',
        defaultMessage: 'My claims'
    },
    claimDetails: {
        id: 'portal.index.Claim Details',
        defaultMessage: 'Track claim'
    },
    createNote: {
        id: 'portal.index.Create Note',
        defaultMessage: 'Create Note'
    },
    policyChange: {
        id: 'portal.index.Policy Change',
        defaultMessage: 'Policy Change'
    },
    policyDetails: {
        id: 'portal.index.Policy Summary',
        defaultMessage: 'Policy summary'
    },
    accountInformation: {
        id: 'portal.index.Account Information',
        defaultMessage: 'Account Information'
    },
    homePage: {
        id: 'portal.index.Home Page',
        defaultMessage: 'Home Page'
    },
    error: {
        id: 'portal.index.Error',
        defaultMessage: 'Error'
    },
    errorMessage: {
        id: 'portal.index.Sorry, an error occurred. You can try later or contact one of our agents.',
        defaultMessage: 'Sorry, an error occurred. You can try later or contact one of our agents.'
    },
    errorMessageHeader: {
        id: 'portal.index.Error Message Header',
        defaultMessage: 'Something went wrong'
    },
    errorMessageContent: {
        id: 'portal.index.Error Message Content',
        defaultMessage: 'We couldn\'t complete your request. Please try again later or '
        + 'email Customer Service at onlineservices@amig.com'
    },
    returnButton: {
        id: 'portal.index.Return to home',
        defaultMessage: 'Return to home'
    },
    noAuthoritiesErrorMessageContent: {
        id: 'portal.index.No Authorities Error Message Content',
        defaultMessage: 'We couldn\'t complete your request. Please call Customer service at '
    },
    noAuthoritiesErrorMessageInfo: {
        id: 'portal.index.No Authorities Error Message Info',
        defaultMessage: ' with your policy number and the email associated with your account.'
    },
    phoneNumber: {
        id: 'portal.index.Phone Number',
        defaultMessage: '1(855) 469-2644'
    },
    noAuthoritiesLogoutButton: {
        id: 'portal.index.Log Out',
        defaultMessage: 'Log out'
    },
    backButton: {
        id: 'portal.index.Back',
        defaultMessage: 'Back'
    },
    accountPreferences: {
        id: 'portal.index.Account Preferences',
        defaultMessage: 'Account preferences'
    },
    mailingAddressForm: {        
        id: 'app.html.index.Mailing address form',
        defaultMessage: 'Mailing address form'          
    }
});
