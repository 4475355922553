import { defineMessages } from 'react-intl';

export default defineMessages({
    vehicles: {
        id: 'policycommon.component.policy-vehicle-details.vehicle coverages',
        defaultMessage: 'Vehicle Coverages'
    },
    vehicledetailsPolicy: {
        id: 'policycommon.component.policy-vehicle-details.Policy',
        defaultMessage: 'Policy'
    },
    hideVehicleCoverages: {
        id: 'policycommon.component.policy-vehicle-details.hide vehicle coverages',
        defaultMessage: 'Hide Vehicle Coverages'
    },
    showVehicleCoverages: {
        id: 'policycommon.component.policy-vehicle-details.show vehicle coverages',
        defaultMessage: 'Show Vehicle Coverages'
    },
    make: {
        id: 'policycommon.component.policy-vehicle-details.make',
        defaultMessage: 'Make'
    },
    model: {
        id: 'policycommon.component.policy-vehicle-details.model',
        defaultMessage: 'Model'
    },
    year: {
        id: 'policycommon.component.policy-vehicle-details.year',
        defaultMessage: 'Year'
    },
    vin: {
        id: 'policycommon.component.policy-vehicle-details.VIN',
        defaultMessage: 'VIN '
    },
    vinHinSerial: {
        id: 'policycommon.component.policy-vehicle-details.VIN HIN Serial',
        defaultMessage: 'VIN / HIN / Serial #'
    },
    mileagePlan: {
        id: 'policycommon.component.policy-vehicle-details.Mileage Plan',
        defaultMessage: 'Mileage Plan'
    },
    storageAddress: {       
        id: 'policycommon.component.policy-vehicle-details.Storage Address ',
        defaultMessage: 'Storage Address'
    },
    mileage: {
        id: 'policycommon.component.policy-vehicle-details.{mileage} miles',
        defaultMessage: '{mileage} miles'
    },

});
