import moment from "moment";

const hasData = (data) => {
    if (!(typeof (data) !== "undefined" && data !== null) || data === "") {
        return false;
    }
    return true;
};

const formatPhoneNumber = (phoneNumber) => {
    if (hasData(phoneNumber)) {
        const regex = new RegExp(/^((\+1)|1)? ?\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})( ?(ext\.? ?|x)(\d*))?$/);
        const results = regex.exec(phoneNumber.trim());
        if (results !== null && results.length > 8) {
            return `(${results[3]}) ${results[4]}-${results[5]}${typeof results[8] !== 'undefined' ? ` x${results[8]}` : ''}`;
        }
        return phoneNumber;
    }
    return '-';
};

const formatAmount = (amount) => {
    return hasData(amount) ? amount.toLocaleString("en-US", {
        style: "currency",
        currency: "USD"
    }) : "-";
};

const formatDate = (date) => {
    return hasData(date) ? (moment.utc(date).format("MMM D, YYYY")) : "-";
};

const formatAccompanyingDate = (date) => {
    return hasData(date) ? (moment.utc(date).format("M/D/YY")) : "-";
};

const formatDateForTable = (date) => {
    return hasData(date) ? (moment.utc(date).format("M/D/YYYY")) : "-";
};

const formatDateLong = (date) => {
    return hasData(date) ? (moment.utc(date).format('MM/DD/YYYY')) : '-';
};

const maskDOB = (dateOfBirth) => {
    return hasData(dateOfBirth) ? "XX/XX/" + (dateOfBirth.year.toString()) : "-";
};

const maskLicense = (licenseNumber) => {
    return hasData(licenseNumber) ? "XXXXXXX" + licenseNumber.toString().substring(licenseNumber.length - 4) : "-";
};

const formatDateRange = (fromDate, toDate) => {
    if (hasData(fromDate) && hasData(toDate)) {
        return (formatAccompanyingDate(fromDate) + " - " + formatAccompanyingDate(toDate));
    }
    return "-";
};

const formatMeasurements = (measurement) => {
    return hasData(measurement) ? parseInt(measurement, 10).toLocaleString("en-US", {
        maximumFractionDigits: 0
    }) : "-";
};

const formatIdNumber = (idNumber) => {
    return hasData(idNumber) ? idNumber.toString().replace(/,/g, '') : "-";
};

const formatPaymentDescription = (description) => {
    return hasData(description) ? description.charAt(0) + description.substring(1).toLowerCase() : "-";
};

const formatText = (text) => {
    return hasData(text) ? text.charAt(0).toUpperCase() + text.substring(1).toLowerCase() : "-";
};

export default {
    formatPhoneNumber: formatPhoneNumber,
    formatAmount: formatAmount,
    formatDate: formatDate,
    formatAccompanyingDate: formatAccompanyingDate,
    formatDateForTable: formatDateForTable,
    formatDateLong: formatDateLong,
    maskDOB: maskDOB,
    maskLicense: maskLicense,
    formatDateRange: formatDateRange,
    formatMeasurements: formatMeasurements,
    formatIdNumber: formatIdNumber,
    formatPaymentDescription: formatPaymentDescription,
    formatText: formatText
};
