import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { readViewModelValue } from 'gw-jutro-adapters-react';
import { ViewModelForm } from 'gw-portals-viewmodel-react';
import CoverageTermsIterableComponent from '../../CoverageTermsIterableComponent/CoverageTermsIterableComponent';
import metadata from './CoverableIterableComponent.metadata.json5';
import styles from './CoverableIterableComponent.module.scss';

function CoverableIterableComponent(props) {
    const { value: coverable } = props;
    const overrides = {
        coverableIterable: {
            data: coverable
        }
    };

    const resolvers = {
        resolveComponentMap: {
            coveragetermsiterablecomponent: CoverageTermsIterableComponent
        },
        resolveClassNameMap: styles
    };

    const readValue = useCallback(
        (id, path) => {
            return readViewModelValue(metadata.componentContent, coverable, id, path, overrides);
        },
        [coverable, overrides]
    );

    return (
        <ViewModelForm
            uiProps={metadata.componentContent}
            model={coverable}
            overrideProps={overrides}
            componentMap={resolvers.resolveComponentMap}
            classNameMap={resolvers.resolveClassNameMap}
            resolveValue={readValue}
        />
    );
}

CoverableIterableComponent.propTypes = {
    value: PropTypes.arrayOf(PropTypes.shape({}))
};

CoverableIterableComponent.defaultProps = {
    value: []
};

export default CoverableIterableComponent;
