import { defineMessages } from 'react-intl';

export default defineMessages({
    coveredDrivers: {
        id: 'policycommon.component.policy-driver-info.Covered Drivers',
        defaultMessage: 'Covered Drivers'
    },   
    name: {
        id: 'policycommon.component.policy-driver-info.Name',
        defaultMessage: 'Name'
    },
    license: {
        id: 'policycommon.component.policy-driver-info.License',
        defaultMessage: 'License'
    },
    dob: {
        id: 'policycommon.component.policy-driver-info.Date of Birth',
        defaultMessage: 'Date of Birth'
    },

    excluded: {
        id: "policycommon.component.policy-driver-info.Excluded",
        defaultMessage: "Excluded"
    }

});
