import _ from 'lodash';
// eslint-disable-next-line import/no-unresolved
import appConfig from 'app-config';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { ENROLLMENT_TYPES } from '../../EnrollmentUtil';
import EnrollmentComponent from '../../components/EnrollmentComponent/EnrollmentComponent';

import metadata from './AdditionalEnrollment.metadata.json5';
import messages from './AdditionalEnrollment.messages';

const isMultiAccountUser = _.get(appConfig, 'self-enrollment.multiAccountEnrollment', false);

function AdditionalEnrollment() {
    const overrides = {
        enrollmentComponent: {
            enrollmentType: isMultiAccountUser ? ENROLLMENT_TYPES.account : ENROLLMENT_TYPES.policy,
            informationText: messages.provideVerificationInformation,
            informationAdditionalText:
                isMultiAccountUser === true
                    ? messages.relatedPoliciesAddedAutomatically
                    : messages.completeFieldsToAccessPolicyInfo
        }
    };

    const resolvers = {
        resolveComponentMap: {
            enrollmentcomponent: EnrollmentComponent
        }
    };

    const renderedContentFromMetadata = renderContentFromMetadata(
        metadata.pageContent,
        overrides,
        resolvers
    );

    return renderedContentFromMetadata;
}

export default AdditionalEnrollment;
