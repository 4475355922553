import {
    useCallback,
    useState
} from 'react';

import _ from 'lodash';
import { renderContentFromMetadata } from '@jutro/uiconfig';
import { CustomFormatterUtil } from 'gw-portals-util-js';
import metadata from './CustomPaymentHistoryComponent.metadata.json5';
import styles from './CustomPaymentHistoryComponent.module.scss';
import messages from './CustomPaymentHistoryComponent.messages';

function CustomPaymentHistoryComponent(props) {
    const { selectedPaymentHistory } = props;
    const [style, setStyle] = useState('hideDetails');

    const toggleDetails = useCallback(() => () => {
        if (style === 'hideDetails') {
            setStyle('displayDetails');
        } else {
            setStyle('hideDetails');
        }
    }, [style]);

    const showModal = useCallback(() => () => {
        const payAmt = CustomFormatterUtil.formatAmount(selectedPaymentHistory.amount.amount);
        const payDate = CustomFormatterUtil.formatDate(selectedPaymentHistory.paymentDate);
        props.showPaymentReceipt(payAmt, payDate, selectedPaymentHistory.paymentMethod_Ext, selectedPaymentHistory.receiptNumber_Ext);
    }, []);

    const overrideProps = {
        "paymentDate": {
            value: CustomFormatterUtil.formatDate(selectedPaymentHistory.paymentDate)
        },
        "paid": {
            value: CustomFormatterUtil.formatAmount(selectedPaymentHistory.amount.amount),
            className: selectedPaymentHistory.paymentStatus_Ext==='Rejected'? "rejected":"paymentHistoryGridValues"
        },
        "paymentStatus": {
            value: selectedPaymentHistory.paymentStatus_Ext,
            className: selectedPaymentHistory.paymentStatus_Ext==='Rejected'? "rejected":"paymentHistoryGridValues"
        },
        "description": {
            value: CustomFormatterUtil.formatPaymentDescription(selectedPaymentHistory.description_Ext), 
        },
        "toggleDetails": {
            className: style
        },
        "paymentHistoryChevronIcon": {
            onClick: toggleDetails(),
            className: (style === "displayDetails") ? "chevron-flip payment_chevron" : "payment_chevron"
        },
        "paymentReceiptLink": {
            onClick: showModal()
        }
    };

    const resolvers = {
        resolveClassNameMap: styles
    };

    return renderContentFromMetadata(metadata.componentContent, overrideProps, resolvers);
}

CustomPaymentHistoryComponent.propTypes = {
};

export default CustomPaymentHistoryComponent;
