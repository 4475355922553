import LoginComponent from './LoginComponent/LoginComponent';
import SignUpComponent from './SignUpComponent/SignUpComponent';
import ForgotPasswordComponent from './ForgotPasswordComponent/ForgotPasswordComponent';
import ResetPasswordComponent from './ResetPasswordComponent/ResetPasswordComponent';

const LoginCapabilityComponentMap = {
    LoginComponent: { component: 'LoginComponent' },
    SignUpComponent: { component: 'SignUpComponent' },
    ForgotPasswordComponent: { component: 'ForgotPasswordComponent' },
    ResetPasswordComponent: { component: 'ResetPasswordComponent' }
};

const LoginCapabilityComponents = {
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
};

export {
    LoginCapabilityComponentMap,
    LoginCapabilityComponents,
    LoginComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent
};
